import React, { useContext } from 'react';
import { Box, Button ,LinearProgress} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import { RiDeleteBinLine } from "react-icons/ri";
import { CaseContext } from '../../../../context/CaseContext';
import UserContext from '../../../../context/UserContext';
import { commaMoneyFormat } from '../../../../Utils';

const FeesFinesView = (props) => {
    const { setConfirmChange, setSelectedFee, openDelete, setAssessDisplay, setSuspendDisplay } = props;

    const { loadingFF, feeFineRows } = useContext(CaseContext);

    const { useFeeSchedule } = useContext(UserContext)

    const columns = [
        {
            field: 'description',
            headerName: 'Description',
            width: 400,
        },
        {
            field: 'assessAmount',
            headerName: 'Assessed',
            width: 150,
            type: 'number',
            renderCell: (params) => {
                return (
                    <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</>
                )
            },
            align: "right",
            headerAlign: "right"
        },
        {
            field: 'suspendAmount',
            headerName: 'Suspended',
            width: 150,
            type: 'number',
            renderCell: (params) => {
                return (
                    <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</>
                )
            },
            align: "right",
            headerAlign: "right"
        },
        {
            field: 'edit',
            headerName: 'Edit',
            headerAlign: 'center',
            align: 'center',
            width: 100,
            disableColumnFilter: 'true',
            disableColumnMenu: 'true',
            disableSort: 'true',
            sortComparator: () => {
                return 0;
            },
            renderCell: (params) => {
                return (
                    <>
                        {
                            (params.row.applyTo === "FEE" || (params.row.applyTo === "FINE" && useFeeSchedule === 0)) &&
                            <Button
                                    fullWidth
                                    onClick={async () => {
                                        await setSelectedFee(params.row);
                                        await setAssessDisplay(commaMoneyFormat(params.row.assessAmount))
                                        await setSuspendDisplay(commaMoneyFormat(params.row.suspendAmount))
                                        setConfirmChange(true);
                                    }}
                                    variant="contained"
                                    sx={{ backgroundColor: "steelblue", color: 'white', fontSize: "20px", p: "15px" }}
                                    disabled={params.row.description === 'AMENDED TO DISMISSED'}
                            >
                                <EditIcon />
                            </Button>
                        }
                    </>
                )
            }
        },
        {
            field: 'delete',
            headerName: 'Delete',
            headerAlign: 'center',
            align: 'center',
            width: 100,
            disableColumnFilter: 'true',
            disableColumnMenu: 'true',
            disableSort: 'true',
            sortComparator: () => {
                return 0;
            },
            renderCell: (params) => {
                return (
                    <>
                        {
                            (params.row.applyTo === "FEE" || (params.row.applyTo === "FINE" && useFeeSchedule === 0)) && (
                                <Button
                                    fullWidth
                                    onClick={() => {
                                        openDelete(params.row);
                                    }}
                                    variant="contained"
                                    sx={{ backgroundColor: "steelblue", color: 'white', fontSize: "20px", p: "15px" }}
                                    disabled={params.row.applyTo === 'FINE'}
                                >
                                    <RiDeleteBinLine />
                                </Button>
                            )
                        }
                    </>
                )
            }
        },
    ];


    const sortedRows = [...feeFineRows].sort((a, b) => {
        if (a.applyTo === 'FINE') {
            return -1;
        }
        if (a.applyTo === 'FEE' && a.isCourtCost === 'Y' && a.description > b.description) {
            return 1;
        }
        if (a.applyTo === 'FEE' && a.isCourtCost == null) {
            return 1;
        }
        return 0;
    });

    return (
        <Box sx={{ height: '28vh' }}>
            <DataGrid
                rows={sortedRows}
                columns={columns}
                disableMultipleRowSelection
                density="compact"
                slots={{ loadingOverlay: LinearProgress }}
                loading={loadingFF}
                localeText={{ noRowsLabel: 'Select a violation above to display or add fees and fines to selected violation' }}
                initialState={{
                    pagination: { paginationModel: { pageSize: 75 } }
                }}
                sx={{
                    '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                        marginTop: '1em',
                        marginBottom: '1em'
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                        outline: "none !important",
                    },
                    maxHeight: '28vh',
                }}
            />
        </Box>
    );
};

export default FeesFinesView;
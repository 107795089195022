import React, { useContext, useState } from 'react';

import { Box, Checkbox, FormControlLabel, Grid, MenuItem, Paper, TextField } from '@mui/material';

import { stateOptions } from '../../../../Utils';
import { CaseContext } from '../../../../context/CaseContext';


const RemitterForm = (props) => {
    const { ts, dispatch, state, selectedPayment, calledFrom, modalType, citationState } = props;

    const { caseHistoryRows } = useContext(CaseContext);
    const selectedPaymentHistoryDetail = caseHistoryRows.filter((item) => item?.pkFfPaymentId === selectedPayment?.pkFfPaymentId);

    const [autoFill, setAutoFill] = useState(false);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const handleBlur = (field, value) => {
        let error = null;
        switch (field) {
            case 'payToAddress1':
                if (!value) {
                    error = `${field.substring(5, field.length - 1)} is required`;
                }
                break;
            case 'payToZip':
                if (!value) {
                    error = `${field.substring(5)} Code is required`;
                }
                break;
            default:
                if (!value && field !== "payToAddress2") {
                    error = `${field.substring(5)} is required`;
                }
                break;
        }
        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const autoFillRemitter = (e) => {
        e.preventDefault();
        let bool = !autoFill;
        setAutoFill(bool);
        if (bool) {

            if (calledFrom === 'Case History') {
                handleFieldChange('payToName', selectedPaymentHistoryDetail[0]?.paytoName);
                handleFieldChange('payToAddress1', selectedPaymentHistoryDetail[0]?.paytoAddress1);
                handleFieldChange('payToAddress2', selectedPaymentHistoryDetail[0]?.paytoAddress2);
                handleFieldChange('payToCity', selectedPaymentHistoryDetail[0]?.paytoCity);
                handleFieldChange('payToState', selectedPaymentHistoryDetail[0]?.paytoState);
                handleFieldChange('payToZip', selectedPaymentHistoryDetail[0]?.paytoZip);
            } else {
                handleFieldChange('payToName', `${citationState?.firstName} ${citationState?.lastName}`);
                handleFieldChange('payToAddress1', citationState.address1);
                handleFieldChange('payToAddress2', citationState.address2);
                handleFieldChange('payToCity', citationState.city);
                handleFieldChange('payToState', citationState.state);
                handleFieldChange('payToZip', citationState.zipCode);
            }

        } else {
            handleFieldChange('payToName', '');
            handleFieldChange('payToAddress1', '');
            handleFieldChange('payToAddress2', '');
            handleFieldChange('payToCity', '');
            handleFieldChange('payToState', '');
            handleFieldChange('payToZip', '');
        }
    }

    const label = modalType === ('newPayment' || 'quickPayment') ? 'Autofill Defendant Info' : 'Autofill Remitter Info'

    return (
        <Paper sx={{ mb: '2vh', width: modalType === 'quickPayment' ? 'auto' : 'auto' }} elevation={10}>
            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display: "flex" }} elevation={10}>
                <h6 style={{ marginBottom: '0px' }}>Remitter Info</h6>
            </Paper>
            <Box ml={'1vw'} >
                <FormControlLabel control={<Checkbox onClick={(e) => autoFillRemitter(e)} checked={autoFill} />} label={label} />
            </Box>
            <Grid container direction="row" sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', p: '.5vh', pb: '1vh', ml: '.5vw' }}>
                <Grid item sm={12} >
                    <TextField
                        required
                        sx={{ ...ts, width: modalType === 'quickPayment' ? '97.5%' : '40%', mb: modalType === 'quickPayment' ? '1.75vh' : 0 }}
                        name="payToName"
                        id="payToName"
                        label="Full Name"
                        value={state.payToName || ''}
                        onChange={(e) => { handleFieldChange('payToName', e.target.value) }}
                        onBlur={(e) => { handleBlur('payToName', e.target.value) }}
                        helperText={state?.errors?.payToName}
                        error={!!state?.errors?.payToName}
                        variant="outlined"
                    />
                </Grid>
                <Grid item sm={modalType === 'quickPayment' ? 5.7 : 4}>
                    <TextField
                        required
                        fullWidth
                        sx={{ ...ts, mb: modalType === 'quickPayment' ? '1.75vh' : 0 }}
                        name="payToAddress1"
                        id="payToAddress1"
                        label="Address1"
                        value={state.payToAddress1 || ''}
                        onChange={(e) => { handleFieldChange('payToAddress1', e.target.value) }}
                        onBlur={(e) => { handleBlur('payToAddress1', e.target.value) }}
                        helperText={state?.errors?.payToAddress1}
                        error={!!state?.errors?.payToAddress1}
                        variant="outlined"
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        fullWidth
                        sx={{ ...ts, width: modalType === 'quickPayment' ? '95%' : '70%', mb: modalType === 'quickPayment' ? '1.75vh' : 0 }}
                        name="payToAddress2"
                        id="payToAddress2"
                        label="Address2"
                        value={state.payToAddress2 || ''}
                        onChange={(e) => { handleFieldChange('payToAddress2', e.target.value) }}
                        onBlur={(e) => { handleBlur('payToAddress2', e.target.value) }}
                        helperText={state?.errors?.payToAddress2}
                        error={!!state?.errors?.payToAddress2}
                        variant="outlined"
                    />
                </Grid>
                <Grid item sm={modalType === 'quickPayment' ? 4.75 : 3}>
                    <TextField
                        required
                        fullWidth
                        sx={{ ...ts, mb: modalType === 'quickPayment' ? '1.5vh' : 0 }}
                        name="payToCity"
                        id="payToCity"
                        label="City"
                        value={state.payToCity || ''}
                        onChange={(e) => { handleFieldChange('payToCity', e.target.value) }}
                        onBlur={(e) => { handleBlur('payToCity', e.target.value) }}
                        helperText={state?.errors?.payToCity}
                        error={!!state?.errors?.payToCity}
                        variant="outlined"
                    />
                </Grid>
                <Grid item sm={modalType === 'quickPayment' ? 1.55 : 1.5}>
                    <TextField
                        required
                        fullWidth
                        select
                        sx={{ ...ts, mb: modalType === 'quickPayment' ? '1.5vh' : 0 }}
                        name="payToState"
                        id="payToState"
                        label="State"
                        value={state.payToState || ''}
                        onChange={(e) => { handleFieldChange('payToState', e.target.value) }}
                        onBlur={(e) => { handleBlur('payToState', e.target.value) }}
                        helperText={state?.errors?.payToState}
                        error={!!state?.errors?.payToState}
                        variant="outlined"
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: {
                                        maxHeight: '20em',
                                    },
                                },
                            },
                        }}
                    >
                        <MenuItem value=''>N/A</MenuItem>
                        {
                            stateOptions?.map(({ value, label }) => {
                                return <MenuItem key={label} value={value}>{label}</MenuItem>
                            })
                        }
                    </TextField>
                </Grid>
                <Grid item sm={modalType === 'quickPayment' ? 4.8 : 3}>
                    <TextField
                        required
                        fullWidth
                        sx={{ ...ts, mb: modalType === 'quickPayment' ? '1.75vh' : 0 }}
                        name="payToZip"
                        id="payToZip"
                        label="ZIP Code"
                        value={state.payToZip || ''}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            if (/^\d*$/.test(inputValue)) {
                                handleFieldChange('payToZip', inputValue);
                            }
                        }}
                        onBlur={(e) => { handleBlur('payToZip', e.target.value) }}
                        helperText={state?.errors?.payToZip}
                        error={!!state?.errors?.payToZip}
                        variant="outlined"
                        inputProps={{ maxLength: 5 }}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default RemitterForm;
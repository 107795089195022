import { Box, Chip } from '@mui/material';

import { formatPhoneNumber } from "../../../../../Utils";

export const getColumns = (name) => {
    if (name === 'Agencies') {
        return [
            {
                id: 'code',
                field: 'code',
                headerName: 'Code',
                width: 200,
            },
            {
                id: 'description',
                field: 'description',
                headerName: 'Description',
                width: 300,
            },
            {
                id: 'usCode',
                field: 'usCode',
                headerName: 'US Code',
                width: 300,
            },
            {
                id: 'accountNumber',
                field: 'accountNumber',
                headerName: 'Account Number',
                width: 200,
            },
        ];
    } else if (name === 'Banks') {
        return [
            {
                id: 'pkBankId',
                field: 'pkBankId',
                headerName: 'Bank ID',
                width: 300,
            },
            {
                id: 'name',
                field: 'name',
                headerName: 'Name',
                width: 400,
            },
            {
                id: 'isActive',
                field: 'isActive',
                headerName: 'Is Active',
                width: 400,
                renderCell: (params) => {
                    const isActiveOptions = [
                        { value: 'Y', label: 'Yes' },
                        { value: 'N', label: 'No' }
                    ]
                    return (
                        params.value !== undefined ? isActiveOptions.find(c => c.value === params?.value)?.label : ""
                    )
                }
            },
            {
                id: 'createdBy',
                field: 'createdBy',
                headerName: 'Created By',
                width: 400,
            },
        ];
    }
    else if (name === 'Gl Accounts') {
        return [
            {
                id: 'pkAccountId',
                field: 'pkAccountId',
                headerName: 'Id',
                width: 100
            },
            {
                id: 'description',
                field: 'description',
                headerName: 'Description',
                width: 200,
            },
            {
                id: 'glAccountType',
                field: 'glAccountType',
                headerName: 'Gl Account Type',
                width: 200,
            },
            {
                id: 'accountNumber',
                field: 'accountNumber',
                headerName: 'Account Number',
                width: 200,
            },
            {
                id: 'fkDisburseId',
                field: 'fkDisburseRuleId',
                headerName: 'Fk Disburse Id',
                width: 200,
            },
            {
                id: 'type',
                field: 'type',
                headerName: 'Type',
                width: 200,
            },
            {
                id: 'fundNumber',
                field: 'fundNumber',
                headerName: 'Fund Number',
                width: 350,
            },
            {
                id: 'isActive',
                field: 'isActive',
                headerName: 'Is Active',
                width: 200,
                renderCell: (params) => {
                    const isActiveOptions = [
                        { value: 'Y', label: 'Yes' },
                        { value: 'N', label: 'No' }
                    ]
                    return (
                        params.value !== undefined ? isActiveOptions.find(c => c.value === params?.value)?.label : ""
                    )
                }
            },
            {
                id: 'createdBy',
                field: 'createdBy',
                headerName: 'Created By',
                width: 200,
            },
            {
                id: 'fkBankId',
                field: 'fkBankId',
                headerName: 'fk Bank Id',
                width: 200,
            }
       ]
    } else if (name === 'Statutes/Charge Codes') {
        return [
            {
                id: 'rsNumber',
                field: 'rsNumber',
                headerName: 'RS number',
                width: 110,
                headerAlign: 'right',
                align: 'right',
            },
            {
                id: 'description',
                field: 'description',
                headerName: 'Description',
                width: 450,
                headerAlign: 'left',
            },
            {
                id: 'chargeType',
                field: 'chargeType',
                headerName: 'Charge Type',
                width: 350,
                headerAlign: 'left',
            },
            {
                id: 'caseLevelFee',
                field: 'caseLevelFee',
                headerName: 'Case Level Fee',
                width: 165,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => {
                    return (
                        params.value === true ? 'Yes' : 'No'
                    )
                }
            },
            {
                id: 'cmisCategory',
                field: 'cmisCategory',
                headerName: 'CMIS Form A',
                width: 250,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => {
                    const cmisOptions =
                        [
                            { value: 'FELONY', label: 'Felonies' },
                            { value: 'DWI', label: 'D.W.I' },
                            { value: 'STATE MISD', label: 'Misdemeanors (State)' },
                            { value: 'STATE TRAF', label: 'Traffic (State)' },
                            { value: 'LOCAL MISD', label: 'Misdemenanors (Local Ordinance)' },
                            { value: 'LOCAL TRAF', label: 'Traffic (Local Ordinance)' },
                            { value: 'PARK', label: 'Parking (State and Local Ordinance)' },
                            { value: 'WLF', label: 'Wildlife & Fisheries' }
                        ];
                    return (
                        params.value !== undefined ? cmisOptions.find(c => c.value === params?.value)?.label : ""
                    )
                }
            },
            {
                id: 'courtAppearRqd',
                field: 'courtAppearRqd',
                headerName: 'Court Appear Rqd',
                width: 135,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => {
                    return (
                        params.value === 'Y' ? 'Yes' : 'No'
                    )
                }
            },
            {
                id: 'defaultFine',
                field: 'defaultFine',
                headerName: 'Default Fine',
                width: 165,
                headerAlign: 'right',
                align: 'right',
                renderCell: (params) => {
                    if (!params.value) {
                        params.value = 0
                    }
                    return (
                        <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</>
                    )
                }
            },
        ]
    }
    else if (name === 'Courts') {
        return [
            {
                id: 'courtNum',
                field: 'courtNum',
                headerName: 'Court Number',
                width: 110,
            },
            {
                id: 'courtName',
                field: 'courtName',
                headerName: 'Court Name',
                width: 150,
            },
            {
                id: 'displayCourtName',
                field: 'displayCourtName',
                headerName: 'Display as',
                width: 150,
            },
            {
                id: 'address1',
                field: 'address1',
                headerName: 'Address 1',
                width: 150,
            },
            {
                id: 'address2',
                field: 'address2',
                headerName: 'Address 2',
                width: 110,
            },
            {
                id: 'city',
                field: 'city',
                headerName: 'City',
                width: 110,
            },
            {
                id: 'state',
                field: 'state',
                headerName: 'State',
                width: 80,
            },
            {
                id: 'zipcode',
                field: 'zipcode',
                headerName: 'Zip',
                width: 80,
            },
            {
                id: 'parishCountyType',
                field: 'parishCountyType',
                headerName: 'County Type',
                width: 100,
            },
            {
                id: 'parishCountyName',
                field: 'parishCountyName',
                headerName: 'Parish Name',
                width: 150,
            },
            {
                id: 'districtName',
                field: 'districtName',
                headerName: 'District Name',
                width: 300,
            },
        ]
    }
    else if (name === 'Divisions') {
        return [
            {
                id: 'divisionCode',
                field: 'divisionCode',
                headerName: 'Division Code',
                width: 200,
            },
            {
                id: 'name',
                field: 'name',
                headerName: 'Name',
                width: 200,
            },
        ]
    }
    else if (name === 'Disbursement Rules') {
        return [
            {
                id: 'courtNum',
                field: 'courtNum',
                headerName: 'Court Num',
                width: 100,
            },
            {
                id: 'calcOrder',
                field: 'calcOrder',
                headerName: 'Calc Order',
                width: 120,
            },
            {
                id: 'feeGrouper',
                field: 'feeGrouper',
                headerName: 'Fee Grouper',
                width: 120,
            },
            {
                id: 'disburseOrder',
                field: 'disburseOrder',
                headerName: 'Disb. Order',
                width: 150,
            },
            {
                id: 'allocateBy',
                field: 'allocateBy',
                headerName: 'Allocate By',
                width: 150,
            },
            {
                id: 'allocation',
                field: 'allocation',
                headerName: 'Allocation',
                width: 150,
            },
            {
                id: 'description',
                field: 'description',
                headerName: 'Description',
                width: 300,
            },
        ]
    }
    else if (name === 'Organization Info') {
        return [
            {
                id: 'entityName',
                field: 'entityName',
                headerName: 'Entity Name',
                width: 200,
            },
            {
                id: 'entityType',
                field: 'entityType',
                headerName: 'Entity Type',
                width: 175,
            },
            {
                id: 'streetNumber',
                field: 'streetNumber',
                headerName: 'Street #',
                width: 100,
            },
            {
                id: 'streetName',
                field: 'streetName',
                headerName: 'Street Name',
                width: 150,
            },
            {
                id: 'city',
                field: 'city',
                headerName: 'City',
                width: 120,
            },
            {
                id: 'state',
                field: 'state',
                headerName: 'State',
                width: 75,
            },
            {
                id: 'zip',
                field: 'zip',
                headerName: 'Zip Code',
                width: 100,
            },
            {
                id: 'phone',
                field: 'phone',
                headerName: 'Phone #',
                width: 125,
            },
            {
                id: 'email',
                field: 'email',
                headerName: 'Email',
                width: 175,
            },
            {
                id: 'sheriffName',
                field: 'sheriffName',
                headerName: 'Sheriff Name',
                width: 150,
            },
            //{
            //    id: 'paymentUrl',
            //    field: 'paymentUrl',
            //    headerName: 'Payment URL',
            //    width: 150,
            //},
            {
                id: 'schoolFormula',
                field: 'schoolFormula',
                headerName: 'School Formula',
                width: 150,
            },
            {
                id: 'schoolAmount',
                field: 'schoolAmount',
                headerName: 'School Amount',
                width: 150,
                renderCell: (params) => {
                    if (!params.value) {
                        params.value = 0
                    }
                    return (
                        <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</>
                    )
                }
            },
        ]
    }
    else if (name === 'Fee Rules') {
        return [
            {
                id: "description",
                field: "description",
                headerName: "Description",
                width: 350,
            },
            {
                id: "caseType",
                field: "caseType",
                headerName: "Case Type",
                width: 300,
            },
            {
                id: "feeGrouper",
                field: "feeGrouper",
                headerName: "Fee Grouper",
                headerAlign: 'center',
                align: 'center',
                width: 100,
            },
            {
                id: "applyTo",
                field: "applyTo",
                headerName: "Apply To",
                headerAlign: 'center',
                align: 'center',
                width: 100,
            },
            {
                id: "feeType",
                field: "feeType",
                headerName: "Fee Type",
                headerAlign: 'center',
                align: 'center',
                width: 100,
            },
            {
                id: "act250",
                field: "act250",
                headerName: "Act 250",
                headerAlign: 'center',
                align: 'center',
                width: 100,
                renderCell: (params) => {
                    return (
                        params.value === "Y" ? 'Yes' : 'No'
                    );
                }
            },
            {
                id: "act405",
                field: "act405",
                headerName: "Act 405",
                headerAlign: 'center',
                align: 'center',
                width: 100,
                renderCell: (params) => {
                    return (
                        params.value === "Y" ? 'Yes' : 'No'
                    );
                }
            },
            {
                id: "act440",
                field: "act440",
                headerName: "Act 440",
                headerAlign: 'center',
                align: 'center',
                width: 100,
                renderCell: (params) => {
                    return (
                        params.value === "Y" ? 'Yes' : 'No'
                    );
                }
            }, {
                id: "act832",
                field: "act832",
                headerName: "Act 832",
                headerAlign: 'center',
                align: 'center',
                width: 100,
                renderCell: (params) => {
                    return (
                        params.value === "Y" ? 'Yes' : 'No'
                    );
                }
            },
            {
                id: "amount",
                field: "amount",
                headerName: "Amount ($)",
                headerAlign: 'right',
                align: 'right',
                width: 100,
                renderCell: (params) => {
                    if (!params.value) {
                        params.value = 0
                    }
                    return (
                        <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</>
                    )
                }
            },
        ]
    }
    else if (name === 'Officers') {
        return [
            {
                id: 'officerName',
                field: 'officerName',
                headerName: 'Officer Name',
                width: 350,
            },
            {
                id: 'officerRank',
                field: 'officerRank',
                headerName: 'Officer Rank',
                width: 350,
            },
            {
                id: 'badgeNumber',
                field: 'badgeNumber',
                headerName: 'Badge Number',
                width: 300,
            },
            {
                id: 'agency',
                field: 'agency',
                headerName: 'Agency',
                width: 300,
            },
        ]
    }
    else if (name === 'Payees') {
        return [
            {
                id: 'paytoName',
                field: 'paytoName',
                headerName: 'Pay To Name',
                width: 300,
            },
            {
                id: 'vendorType',
                field: 'vendorType',
                headerName: 'Vendor Type',
                width: 150,
            },
            {
                id: 'vendorCode',
                field: 'vendorCode',
                headerName: 'Vendor Code',
                width: 150,
            },
            {
                id: 'paytoAddress1',
                field: 'paytoAddress1',
                headerName: 'Pay To Address 1',
                width: 200,
            },
            {
                id: 'paytoAddress2',
                field: 'paytoAddress2',
                headerName: 'Pay To Address 2',
                width: 200,
            },
            {
                id: 'paytoCity',
                field: 'paytoCity',
                headerName: 'Pay To City',
                width: 150,
            },
            {
                id: 'paytoState',
                field: 'paytoState',
                headerName: 'Pay To State',
                width: 150,
            },
            {
                id: 'paytoZip',
                field: 'paytoZip',
                headerName: 'Pay To Zip',
                width: 150,
            },
        ]
    }
    else if (name === 'Speeding Amounts') {
        return [
            {
                id: 'startRange',
                field: 'startRange',
                headerName: 'Start Range',
                width: 200,
            },
            {
                id: 'endRange',
                field: 'endRange',
                headerName: 'End Range',
                width: 200,
            },
            {
                id: 'increaseBy',
                field: 'increaseBy',
                headerName: 'Increase By',
                width: 200,
                renderCell: (params) => {
                    if (!params.value) {
                        params.value = 0
                    }
                    return (
                        <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</>
                    )
                }
            },
        ]
    }
    else if (name === 'System Codes') {
        return [
            {
                id: 'id',
                field: 'id',
                headerName: 'Sys Code ID',
                width: 200,
            },
            {
                id: 'code',
                field: 'code',
                headerName: 'Code',
                width: 200,
            },
            {
                id: 'description',
                field: 'description',
                headerName: 'Description',
                width: 600,
            },
            {
                id: 'type',
                field: 'type',
                headerName: 'Type',
                width: 200,
            },
            {
                id: 'defaultValue',
                field: 'defaultValue',
                headerName: 'Default Value',
                width: 200,
            },
        ]
    }
    else if (name === 'Users') {
        return [
            {
                field: 'name',
                headerName: 'User Name',
                width: 300,
            },
            {
                field: 'email',
                headerName: 'User Email',
                width: 300,
            },
            {
                field: 'phoneNumber',
                headerName: 'User Phone',
                width: 200,
                renderCell: ({ row: { phoneNumber } }) => {
                    return (
                        formatPhoneNumber(phoneNumber)
                    )
                }
            },
            {
                field: 'emailMfa',
                headerName: 'Email MFA Enabled',
                width: 150,
                renderCell: ({ row: { isEmailEnabled } }) => {
                    return isEmailEnabled ? 'Yes' : 'No';
                }
            },
            {
                field: 'textMfa',
                headerName: 'Text MFA Enabled',
                width: 150,
                renderCell: ({ row: { isPhoneEnabled } }) => {
                    return isPhoneEnabled ? 'Yes' : 'No';
                }
            },

            {
                field: 'roles',
                headerName: 'User Roles',
                width: 150,
                renderCell: ({ row: { roles } }) => {
                    return (
                        <>
                            <Box
                                sx={{
                                    py: 2,
                                    display: 'flex',
                                    gap: 2,
                                    justifyItems: 'start',
                                    alignItems: 'center',
                                }}
                            >
                                {roles?.map((role) => (
                                    <Chip
                                        key={role}
                                        label={role.replace('COLLECT_', '')}
                                        variant="outlined"
                                        size="small"
                                        color="default"
                                    />
                                ))}
                            </Box>
                        </>
                    );
                },
            },
            {
                field: 'isAccountLocked',
                headerName: 'Account Locked',
                width: 150,
                renderCell: ({ row: { isAccountLocked } }) => {
                    return isAccountLocked ? 'Yes' : 'No';
                }
            },
        ]
    }
    else {
        return []
    }
}
import React, { useContext } from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    Paper,
    TextField,
} from '@mui/material';

import { MdExpandMore } from "react-icons/md";
import { SystemContext } from '../../context/SystemContext';

export const NewVehicle = ({
    index,
    otherVehicles,
    updateOtherVehicle,
    saveOtherVehicle,
    ts,
    otherVehicleDefaultObj,
    editOtherVehicle,
    state
}) => {

    const {
        vehicleMakes,
        vehicleTypes,
        vehicleStyles,
        vehicleColors,
        states,
    } = useContext(SystemContext);

    let i = index;
    i = i + 1;

    return (
        <Accordion defaultExpanded={otherVehicles[index] !== otherVehicleDefaultObj && index === otherVehicles.length - 1}>
            <AccordionSummary expandIcon={<MdExpandMore />}
                sx={{ backgroundColor: 'steelblue' }}>
                Vehicle {i}
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ height: "100%" }}>
                    <Box sx={{ width: "99%", margin: "1vh auto" }}>
                        <Paper sx={{ width: '100%', mb: '2vh', pb: '.25vh' }} elevation={10}>
                            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display:'flex' }}>
                                <h6 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px'  }}>Vehicle</h6>
                            </Paper>
                            <Box sx={{ width: '97%', margin: '1vh auto' }}>
                                <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", gap: '1rem' }}>
                                    <Grid item sm={1}>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            id='vehicleYear'
                                            label='Year'
                                            name='vehicleYear'
                                            value={otherVehicles[index]?.vehicleYear}
                                            onChange={(e) => updateOtherVehicle('vehicleYear', index, e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            inputProps={{ readOnly: !state.isActive || state.isDeleted }}
                                        />
                                    </Grid>
                                    <Grid item sm={2}>
                                        <TextField
                                            fullWidth
                                            select
                                            sx={ts}
                                            id='vehicleMake'
                                            label='Make'
                                            name='vehicleMake'
                                            value={otherVehicles[index]?.vehicleMake}
                                            onChange={(e) => updateOtherVehicle('vehicleMake', index, e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: '20em',
                                                        },
                                                    },
                                                },
                                            }}
                                            inputProps={{ readOnly: !state.isActive || state.isDeleted }}
                                        >
                                            <MenuItem value={''}>N/A</MenuItem>
                                            {
                                                vehicleMakes?.map(({ codeValue, codeTitle },) => {
                                                    return <MenuItem key={codeTitle} value={codeValue}>{codeTitle}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            id='vehicleModel'
                                            label='Model'
                                            name='vehicleModel'
                                            value={otherVehicles[index]?.vehicleModel}
                                            onChange={(e) => updateOtherVehicle('vehicleModel', index, e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            inputProps={{ readOnly: !state.isActive || state.isDeleted }}
                                        />
                                    </Grid>
                                    <Grid item sm={2}>
                                        <TextField
                                            fullWidth
                                            select
                                            sx={ts}
                                            id='vehicleType'
                                            label='Type'
                                            name='vehicleType'
                                            value={otherVehicles[index]?.vehicleType}
                                            onChange={(e) => updateOtherVehicle('vehicleType', index, e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: '20em',
                                                        },
                                                    },
                                                },
                                            }}
                                            inputProps={{ readOnly: !state.isActive || state.isDeleted }}
                                        >
                                            <MenuItem value={''}>N/a</MenuItem>
                                            {
                                                vehicleTypes?.map(({ codeValue, codeTitle },) => {
                                                    return <MenuItem key={codeTitle} value={codeValue}>{codeTitle}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <TextField
                                            fullWidth
                                            select
                                            sx={ts}
                                            id='vehicleStyle'
                                            label='Style'
                                            name='vehicleStyle'
                                            value={otherVehicles[index]?.vehicleStyle}
                                            onChange={(e) => updateOtherVehicle('vehicleStyle', index, e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: '20em',
                                                        },
                                                    },
                                                },
                                            }}
                                            inputProps={{ readOnly: !state.isActive || state.isDeleted }}
                                        >
                                            <MenuItem value={''}>N/A</MenuItem>
                                            {
                                                vehicleStyles?.map(({ codeValue, codeTitle },) => {
                                                    return <MenuItem key={codeTitle} value={codeValue}>{codeTitle}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <TextField
                                            fullWidth
                                            select
                                            sx={ts}
                                            id='vehicleColor'
                                            label='Color'
                                            name='vehicleColor'
                                            value={otherVehicles[index]?.vehicleColor}
                                            onChange={(e) => updateOtherVehicle('vehicleColor', index, e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: '20em',
                                                        },
                                                    },
                                                },
                                            }}
                                            inputProps={{ readOnly: !state.isActive || state.isDeleted }}
                                        >
                                            <MenuItem value={''}>N/A</MenuItem>
                                            {
                                                vehicleColors?.map(({ codeValue, codeTitle },) => {
                                                    return <MenuItem key={codeTitle} value={codeValue}>{codeTitle}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Paper sx={{ width: '55%', mb: '2vh' }} elevation={10}>
                                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh', m: 'auto', p: '.5vh' }}>
                                    <h6>
                                        <strong>Vehicle License Plate</strong>
                                    </h6>
                                </Paper>
                                <Box sx={{ width: '95%', margin: '1vh auto' }}>
                                    <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", gap: '1rem' }}>
                                        <Grid item sm={5}>
                                            <TextField
                                                fullWidth
                                                sx={ts}
                                                id='vehicleLicenseNumber'
                                                label='Number'
                                                name='vehicleLicenseNumber'
                                                value={otherVehicles[index]?.vehicleLicenseNumber}
                                                onChange={(e) => updateOtherVehicle('vehicleLicenseNumber', index, e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                inputProps={{ readOnly: !state.isActive || state.isDeleted }}
                                            />
                                        </Grid>
                                        <Grid item sm={3}>
                                            <TextField
                                                fullWidth
                                                select
                                                sx={ts}
                                                id='vehicleLicenseState'
                                                label='State'
                                                name='vehicleLicenseState'
                                                value={otherVehicles[index]?.vehicleLicenseState}
                                                onChange={(e) => updateOtherVehicle('vehicleLicenseState', index, e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                SelectProps={{
                                                    MenuProps: {
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: '20em',
                                                            },
                                                        },
                                                    },
                                                }}
                                                inputProps={{ readOnly: !state.isActive || state.isDeleted }}
                                            >
                                                <MenuItem value={''}>N/A</MenuItem>
                                                {
                                                    states?.map(({ codeValue, codeTitle },) => {
                                                        return <MenuItem key={codeTitle} value={codeValue}>{codeTitle}</MenuItem>
                                                    })
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item sm={.75}>
                                            <TextField
                                                fullWidth
                                                placeholder="##"
                                                sx={ts}
                                                id='vehicleLicenseYear'
                                                label='Year'
                                                name='vehicleLicenseYear'
                                                value={otherVehicles[index]?.vehicleLicenseYear}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (/^\d*$/.test(inputValue)) { // Check if input contains only digits
                                                        updateOtherVehicle('vehicleLicenseYear', index, inputValue);
                                                    }
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                inputProps={{ maxLength: 2, readOnly: !state.isActive || state.isDeleted }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <Paper sx={{ width: '25%', mb: '2vh' }} elevation={10}>
                                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh', m: 'auto', p: '.5vh' }}>
                                    <h6>
                                        <strong>Investigation</strong>
                                    </h6>
                                </Paper>
                                <Box sx={{ width: '95%', margin: '1vh auto' }}>
                                    <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", gap: '1rem' }}>
                                        <Grid item sm={5}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={otherVehicles[index]?.hazmatVehicle === 'Y'}
                                                        onChange={(e) => {
                                                            updateOtherVehicle('hazmatVehicle', index, e.target.checked ? 'Y' : null)
                                                        }}
                                                    />}
                                                disabled={state.isDeleted | !state.isActive}
                                                label="Hazmat"
                                            />
                                        </Grid>
                                        <Grid item sm={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={otherVehicles[index]?.commercialVehicle === 'Y'}
                                                        onChange={(e) => {
                                                            updateOtherVehicle('commercialVehicle', index, e.target.checked ? 'Y' : null)
                                                        }}
                                                    />}
                                                disabled={state.isDeleted | !state.isActive}
                                                label="Commercial"
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Paper sx={{ width: '30%', mb: '2vh' }} elevation={10}>
                                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh', m: 'auto', p: '.5vh' }}>
                                    <h6>
                                        <strong>Accident With</strong>
                                    </h6>
                                </Paper>
                                <Box sx={{ width: '95%', margin: '1vh auto' }}>
                                    <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", gap: '1rem' }}>
                                        <Grid item sm={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={otherVehicles[index]?.propertyDamage === 'Y'}
                                                        onChange={(e) => {
                                                            updateOtherVehicle('propertyDamage', index, e.target.checked ? 'Y' : null)
                                                        }}
                                                    />}
                                                disabled={state.isDeleted | !state.isActive}
                                                label="Property Dmg."
                                            />
                                        </Grid>
                                        <Grid item sm={4}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={otherVehicles[index]?.personalInjury === 'Y'}
                                                        onChange={(e) => {
                                                            updateOtherVehicle('personalInjury', index, e.target.checked ? 'Y' : null)
                                                        }}
                                                    />}
                                                disabled={state.isDeleted | !state.isActive}
                                                label="Personal Injury"
                                            />
                                        </Grid>
                                        <Grid item sm={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={otherVehicles[index]?.fatal === 'Y'}
                                                        onChange={(e) => {
                                                            updateOtherVehicle('fatal', index, e.target.checked ? 'Y' : null)
                                                        }}
                                                    />}
                                                disabled={state.isDeleted | !state.isActive}
                                                label="Fatal"
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <Box sx={{ display: 'flex', gap: '1rem' }} >
                                <Button
                                    sx={{ mt: '5vh', backgroundColor: "steelblue", height: '5vh', color: 'inherit' }}
                                    variant="contained"
                                    onClick={async () => index === otherVehicles.length -1 ? await saveOtherVehicle(index) : await editOtherVehicle(otherVehicles[index], index)}
                                    disabled={(otherVehicles[index] && !otherVehicles[index].changes) | !state.isActive | state.isDeleted}
                                >
                                    {index === otherVehicles.length - 1 ? 'Save Vehicle' : 'Edit Vehicle'}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
};
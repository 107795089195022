import React, { useContext } from 'react';

import { ThemeModeContext } from '../../../App.js';
import { Box, Button, FormControlLabel, LinearProgress, Paper } from '@mui/material';
import {
    DataGrid,
    GridToolbar,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid';

const BondViolationsTable = (props) => {
    // props
    const {
        rows,
        getRow,
        removeStatute
    } = props

    const { mode } = useContext(ThemeModeContext);

    const tBackground = mode === 'dark' ? '#313131' : 'white';

    const columns = [
        {
            field: 'chargeType',
            headerName: 'Charge Type',
            width: 450
        },
        {
            field: 'rsNumber',
            headerName: 'Statute',
            width: 150
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 550
        },
        {
            field: 'defaultFine',
            headerName: 'Default Amount',
            width: 140,
            headerAlign: 'right',
            align: 'right',
            renderCell: (params) => {
                return (
                    <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</span>
                )
            }
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={
                        {
                            tooltip: {
                                title: 'Change density'
                            }
                        }
                    }
                />
                <Button
                    onClick={removeStatute}> Remove </Button>
                <Box
                    sx={
                        {
                            flexGrow: 1
                        }
                    }
                />
                <GridToolbarQuickFilter />
            </GridToolbarContainer >
        );
    }

    // useEffect
    return (
        <DataGrid
            rows={rows}
            columns={columns}
            disableMultipleRowSelection
            onRowClick={(params) => getRow(params)}
            onCellDoubleClick={removeStatute}
            //loading={loading}
            localeText={{ noRowsLabel: 'Please select violations from the list above' }}
            density="compact"
            pageSizeOptions={[25, 50, 100]}
            initialState={{
                pagination: { paginationModel: { pageSize: 100 } },
            }}
            slots={{
                toolbar: CustomToolbar,
                loadingOverlay: LinearProgress
            }}
            disableColumnFilter
            disableColumnSelector
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true }
                },
            }}
            sx={{
                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                    "marginTop": "2em",
                    "marginBottom": "2em"
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                    outline: "none !important",
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                    display: 'none',  // Hides the scrollbar in WebKit browsers (like Chrome, Safari)
                },
                maxHeight: '35vh'
            }}
        />
    )
}

export default BondViolationsTable;
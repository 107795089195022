import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { apiRoute } from '../App.js';

const BatchContext = createContext({
    date: '',
    setDate: () => { },
    users: '',
    setUsers: () => { },
    batchType: '',
    setBatchType: () => { },
    batchStatus: '',
    setBatchStatus: () => { },
    batchNumber: '',
    setBatchNumber: () => { },
    allUser: Boolean,
    setAllUsers: () => { },
    batchRows: [],
    setBatchRows: () => { },
    openBatches: [],
    setOpenBatches: () => { },
    userOptions: [],
    setUserOptions: () => { },
    entityId: '',
    setEntityId: () => { },
    userId: '',
    setUserId: () => { },
    paymentBatchNumber: '',
    setPaymentBatchNumber: () => { },
    statusCheck: '',
    setStatusCheck: () => { },
    startDate: '',
    setStartDate: () => { },
    endDate: '',
    setEndDate: () => { },
    searchedBatches: '',
    setSearchedBatches: () => { },
    resetBatchContext: () => { },
    resetBatchContextPromise: () => { },
})

const BatchProvider = ({ children }) => {
    const [date, setDate] = useState('');
    const [users, setUsers] = useState('allUsers');
    const [batchType, setBatchType] = useState('ALL');
    const [batchStatus, setBatchStatus] = useState('open');
    const [batchNumber, setBatchNumber] = useState('');
    const [batchRows, setBatchRows] = useState([]);
    const [openBatches, setOpenBatches] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [userId, setUserId] = useState('');
    const [entityId, setEntityId] = useState('');
    const [paymentBatchNumber, setPaymentBatchNumber] = useState('');
    const [statusCheck, setStatusCheck] = useState('open');

    const [loadingBatches, setLoadingBatches] = useState(false);
    const [noResults, setNoResults] = useState(false);

    // Used to search by date range on batch management view
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchedBatches, setSearchedBatches] = useState([]);

    const resetBatchContext = () => {
        setDate('');
        setUsers('allUsers');
        setBatchType('ALL');
        setBatchStatus('open');
        setBatchNumber('');
        setBatchRows([]);
        setOpenBatches([]);
        setUserOptions([]);
        setUserId('');
        setEntityId('');
        setPaymentBatchNumber('');
        setStatusCheck('open');
        setLoadingBatches(false);
        setNoResults(false);
        setStartDate('');
        setEndDate('');
        setSearchedBatches([]);
    };

    const resetBatchContextPromise = async () => {
        return new Promise((resolve) => {
            resetBatchContext();
            resolve();
        })
    };


    //* --------------- SUBMIT SEARCH FORM FUNCTION ---------------

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    // GETS ALL OPEN BATCHES
    const getBatches = async () => {
        setBatchRows([]);
        setLoadingBatches(true);
        try {
            const { data } = await axios.get(`${apiRoute}/api/BatchTbl/allOpen`);
            const newBatchData = data?.map(async (batch) => {
                const { batchNumber, openDatetime, closeDatetime, settleDate, depositDate, shiftDate, settledBy } = batch
                const row = {
                    ...batch,
                    id: batchNumber,
                    displayTime: openDatetime ? formatDate(openDatetime) : null,
                    openDatetime: openDatetime,
                    userName: '',
                    check: 0,
                    cash: 0,
                    creditCard: 0,
                    total: 0,
                    batchStatus: !closeDatetime ? 'Open' : !settleDate ? 'Closed' : !depositDate ? 'Settled' : 'Deposited',
                    settleDate: settleDate ? formatDate(settleDate) : null,
                    settledBy: settledBy,
                    shiftDate: shiftDate,
                    isPaymentExists: false
                };

                try {
                    const { fkUserId, batchNumber } = batch;
                    const { data } = await axios.get(`${apiRoute}/api/users/${fkUserId}`);
                    row.userName = data.name;
                    const paymentRes = await axios.get(`${apiRoute}/api/ffpaymenttbls/batch/${batchNumber}`);
                    let checkAmount = 0;
                    let cashAmount = 0;
                    let creditCardAmount = 0;
                    let totalAmount = 0;
                    let moneyOrder = 0;
                    let cashiersCheck = 0;
                    paymentRes.data.forEach(({ paymentAmount, paymentType }) => {
                        if (['TIME SERVED', 'BOND', 'REFUND'].includes(paymentType)) {
                            return;
                        } else if (paymentType === 'CASH') {
                            cashAmount += paymentAmount
                        } else if (paymentType === 'CREDIT CARD') {
                            creditCardAmount += paymentAmount
                        } else {
                            checkAmount += paymentAmount
                        }
                        if (paymentType === 'MONEY ORDER') {
                            moneyOrder += paymentAmount
                        } else if (paymentType === 'CHECK') {
                            cashiersCheck += paymentAmount
                        }
                    });
                    row.check = checkAmount;
                    row.cash = cashAmount;
                    row.creditCard = creditCardAmount;
                    totalAmount = checkAmount + cashAmount + creditCardAmount;
                    row.total = totalAmount;
                    row.moneyOrder = moneyOrder;
                    row.cashiersCheck = cashiersCheck;
                    row.isPaymentExists = totalAmount > 0 ? true : false;
                    return row;
                } catch (err) {
                    console.log('batch context error (getBatches)');
                }
            });

            const newBatchRows = await Promise.all(newBatchData);

            // Sort the newBatchRows array by openDatetime in descending order
            newBatchRows.sort((a, b) => {
                return new Date(b.openDatetime) - new Date(a.openDatetime);
            });
            setBatchRows(newBatchRows);
            setSearchedBatches(newBatchRows);
        } catch (err) {
            console.log('err getting batches - getBatches');
        } finally {
            setLoadingBatches(false);
        }
    };

    // Needed these to be in a global scope to enforce the useEffect to fire
    const hasToken = localStorage.getItem('token') !== null;
    const token = localStorage.getItem('token');
    useEffect(() => {
        if (hasToken) {
            const decodedToken = jwt_decode(token);
            const userDataClaim = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'];
            const entityIdValue = userDataClaim ? userDataClaim.toString() : null;
            const userIdValue = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
            setUserId(userIdValue);
            setEntityId(entityIdValue);
            if (entityIdValue) {
                const getUsers = async () => {
                    if (entityIdValue) {
                        try {
                            const { data } = await axios.get(`${apiRoute}/api/users/byEntity/${entityIdValue}`);
                            setUserOptions(data);
                        } catch (err) {
                            console.log('err getting users', err);
                        }
                    } else {
                        console.error('NO ENTITYID');
                    }
                };
                getUsers();

            }
        }
    }, [token]);

    // SEARCH BATCH FUNCTION
    const onSubmitHandler = async (e) => {
        e.preventDefault();

        // build search params string
        const searchParams = new URLSearchParams();
        if (date) searchParams.set('date', date);
        if (startDate) searchParams.set('startDate', startDate);
        if (endDate) searchParams.set('endDate', endDate);
        if (users) searchParams.set('users', users);
        if (batchStatus) searchParams.set('batchStatus', batchStatus);
        if (batchNumber) searchParams.set('batchNumber', batchNumber);
        if (batchType) searchParams.set('batchType', batchType === 'ALL' ? 'SIMPLE' : batchType);

        setLoadingBatches(true);
        setNoResults(false);
        try {
            const { data } = await axios.get(`${apiRoute}/api/batchtbl/${entityId}/search?${searchParams.toString()}`);
            const newBatchData = data?.map(async (batch) => {
                const { batchNumber, openDatetime, closeDatetime, settleDate, depositDate, shiftDate, settledBy } = batch
                const row = {
                    ...batch,
                    id: batchNumber,
                    displayTime: openDatetime ? formatDate(openDatetime) : undefined,
                    openDatetime: openDatetime ? openDatetime : undefined,
                    userName: '',
                    check: 0,
                    cash: 0,
                    creditCard: 0,
                    total: 0,
                    batchStatus: !closeDatetime ? 'Open' : !settleDate ? 'Closed' : !depositDate ? 'Settled' : 'Deposited',
                    settleDate: settleDate ? formatDate(settleDate) : null,
                    settledBy: settledBy,
                    shiftDate: shiftDate
                };
                try {
                    setStatusCheck(row.batchStatus.toLowerCase());
                    const { fkUserId, batchNumber } = batch;
                    const userRes = await axios.get(`${apiRoute}/api/users/${fkUserId}`);
                    row.userName = userRes.data.name;
                    const { data } = await axios.get(`${apiRoute}/api/ffpaymenttbls/batch/${batchNumber}`);
                    let checkAmount = 0;
                    let cashAmount = 0;
                    let creditCardAmount = 0;
                    let totalAmount = 0;
                    data?.forEach(({ paymentAmount, paymentType }) => {
                        if (['TIME SERVED', 'BOND', 'REFUND'].includes(paymentType)) {
                            return;
                        } else if (paymentType === 'CASH') {
                                cashAmount += paymentAmount
                            } else if (paymentType === 'CREDIT CARD') {
                                creditCardAmount += paymentAmount
                            } else {
                                checkAmount += paymentAmount
                            }
                    });
                    row.check = checkAmount;
                    row.cash = cashAmount;
                    row.creditCard = creditCardAmount;
                    totalAmount = checkAmount + cashAmount + creditCardAmount;
                    row.total = totalAmount;
                    return row;
                } catch (err) {
                    console.log('batch context error (getBatches)');
                }
            });

            const newBatchRows = await Promise.all(newBatchData);

            // Sort the newBatchRows array by openDatetime in descending order
            newBatchRows.sort((a, b) => {
                return new Date(b.openDatetime) - new Date(a.openDatetime);
            });
            setSearchedBatches(newBatchRows);
        } catch (err) {
            setNoResults(true);
            setSearchedBatches([]);
            console.log('err getting batches - onSubmitHandler(BatchMgmtContext)');
        } finally {
            setLoadingBatches(false);
        }

    };

    const contextValue = {
        date: date,
        setDate,
        users: users,
        setUsers,
        batchNumber: batchNumber,
        setBatchNumber,
        batchType: batchType,
        setBatchType,
        batchStatus: batchStatus,
        setBatchStatus,
        batchRows: batchRows,
        setBatchRows,
        loadingBatches: loadingBatches,
        noResults: noResults,
        getBatches,
        onSubmitHandler,
        openBatches: openBatches,
        setOpenBatches,
        userOptions: userOptions,
        setUserOptions,
        userId,
        entityId,
        paymentBatchNumber: paymentBatchNumber,
        setPaymentBatchNumber,
        formatDate,
        setEntityId,
        setUserId,
        statusCheck: statusCheck,
        setStatusCheck,
        startDate: startDate,
        setStartDate,
        endDate: endDate,
        setEndDate,
        searchedBatches: searchedBatches,
        setSearchedBatches,
        resetBatchContextPromise
    }

    return (
        <BatchContext.Provider value={contextValue}>
            {children}
        </BatchContext.Provider>
    )
}

export default BatchProvider;
export { BatchContext };
import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';

import { apiRoute } from '../App.js';

const SearchContext = createContext({
    firstName: "",
    setFirstName: () => { },
    lastName: "",
    setLastName: () => { },
    caseNumber: "",
    setCaseNumber: () => { },
    citationNumber: "",
    setCitationNumber: () => { },
    violationDate: "",
    setViolationDate: () => { },
    courtDate: "",
    setCourtDate: () => { },
    driverLicenseNumber: "",
    setDriverLicenseNumber: () => { },
    searchButtons: "all",
    setSearchButtons: () => { },
    loadingSearch: false,
    setLoadingSearch: () => { },
    noResults: false,
    setNoResults: () => { },
    formSubmitted: false,
    setFormSubmitted: () => { },
    searchResults: [],
    setSearchResults: () => { },
    caseId: 0,
    setCaseId: () => { },
    quickAssessTotal: 0,
    setQuickAssessTotal: () => { },
    quickNetTotal: 0,
    setQuickNetTotal: () => { },
    dateOfBirth: [],
    setDateOfBirth: () => { },
    agency: '',
    setAgency: () => { },
    caseStatus: String,
    setCaseStatus: () => { },
    searchParams: '',
    setSearchParams: () => { },
    formatDate: () => { },
    refreshSearch: () => { },
    caseType: '',
    setCaseType: () => { },
    receiptNumber: '',
    setReceiptNumber: () => { },
    bondNumber: '',
    setBondNumber: () => { },
    postedLast: '',
    setPostedLast: () => { },
    postedFirst: '',
    setPostedFirst: () => { },
    searchStatutes: "",
    setSearchStatutes: () => { },
    fullCaseNumber: "",
    setFullCaseNumber: () => { },
    arrestNumber: "",
    setArrestNumber: () => { },
    resetSearchContext: () => { },
    resetSearchContextPromise: () => { },
})

const SearchProvider = ({ children }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [caseNumber, setCaseNumber] = useState('');
    const [citationNumber, setCitationNumber] = useState('');
    const [violationDate, setViolationDate] = useState('');
    const [courtDate, setCourtDate] = useState('');
    const [driverLicenseNumber, setDriverLicenseNumber] = useState('');
    const [searchButtons, setSearchButtons] = useState('all');
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [caseId, setCaseId] = useState(0);
    const [quickAssessTotal, setQuickAssessTotal] = useState(0);
    const [quickNetTotal, setQuickNetTotal] = useState(0);
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [agency, setAgency] = useState('');
    const [caseStatus, setCaseStatus] = useState('');
    const [searchParams, setSearchParams] = useState('');
    const [caseType, setCaseType] = useState('');
    const [receiptNum, setReceiptNum] = useState('');
    const [bondNumber, setBondNumber] = useState('');
    const [postedLast, setPostedLast] = useState('');
    const [postedFirst, setPostedFirst] = useState('');

    const [chargeDesc, setChargeDesc] = useState("")
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(1500);
    const [fullCaseNumber, setFullCaseNumber] = useState('');
    const [arrestNumber, setArrestNumber] = useState('');

    const resetSearchContext = () => {
        setFirstName('');
        setLastName('');
        setCaseNumber('');
        setCitationNumber('');
        setViolationDate('');
        setCourtDate('');
        setDriverLicenseNumber('');
        setSearchButtons('all');
        setLoadingSearch(false);
        setNoResults(false);
        setFormSubmitted(false);
        setSearchResults([]);
        setCaseId(0);
        setQuickAssessTotal(0);
        setQuickNetTotal(0);
        setDateOfBirth('');
        setAgency('');
        setCaseStatus('');
        setSearchParams('');
        setCaseType('');
        setReceiptNum('');
        setBondNumber('');
        setPostedLast('');
        setPostedFirst('');
        setChargeDesc('');
        setPageNumber(0);
        setPageSize(1500);
        setFullCaseNumber('');
        setArrestNumber('');
    }

    const resetSearchContextPromise = async () => {
        return new Promise((resolve) => {
            resetSearchContext();
            resolve();
        })
    }
    //* --------------- SUBMIT SEARCH FORM FUNCTION ---------------

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    }

    const onSubmitHandler = (e) => {
        e.preventDefault();

        // build search params string
        const searchParams = new URLSearchParams();
        if (fullCaseNumber) searchParams.set('fullcasenumber', fullCaseNumber)
        if (firstName) searchParams.set('firstname', firstName)
        if (lastName) searchParams.set('lastname', lastName)
        if (caseNumber) searchParams.set('casenumber', caseNumber)
        if (citationNumber) searchParams.set('citationnumber', citationNumber)
        if (violationDate) searchParams.set('violationdate', violationDate)
        if (courtDate) searchParams.set('courtdate', courtDate)
        if (driverLicenseNumber) searchParams.set('driverlicensenumber', driverLicenseNumber)
        if (dateOfBirth) searchParams.set('dateOfBirth', dateOfBirth)
        if (agency) searchParams.set('agency', agency)
        if (caseStatus) searchParams.set('caseStatus', caseStatus)
        if (caseType) searchParams.set('caseType', caseType)
        if (caseType === 'BOND') {
            if (receiptNum) searchParams.set('receiptNum', receiptNum)
            if (bondNumber) searchParams.set('bondNumber', bondNumber)
            if (postedLast) searchParams.set('postedLast', postedLast)
            if (postedFirst) searchParams.set('postedFirst', postedFirst)
        }
        if (chargeDesc) searchParams.set('chargeDesc', chargeDesc)
        if (arrestNumber) searchParams.set('arrestNumber', arrestNumber)

        setLoadingSearch(true);
        setNoResults(false);
        setFormSubmitted(true);
        axios.get(`${apiRoute}/api/SearchScreenViews/search`, {
            params: {
                Agency: agency,
                BondNumber: bondNumber,
                CaseStatus: caseStatus,
                CaseType: caseType,
                ChargeDescription: chargeDesc,
                CitationNumber: citationNumber,
                CourtDateTime: courtDate,
                DateOfBirth: dateOfBirth,
                DocketNumber: caseNumber,
                DriversLicenseNumber: driverLicenseNumber,
                FirstName: firstName,
                FullCaseNumber: fullCaseNumber,
                LastName: lastName,
                PostedFirst: postedFirst,
                PostedLast: postedLast,
                ReceiptNum: receiptNum,
                ViolationDate: violationDate,
                ArrestNumber: arrestNumber
            },
        })
            .then(({ data }) => {
                setSearchResults(data?.map((item) => ({
                    ...item,
                    id: item.pkCaseId,
                    dateOfBirth: item.dateOfBirth ? formatDate(item.dateOfBirth) : undefined,
                    violationDate: item.violationDate ? formatDate(item.violationDate) : undefined,
                    courtDatetime: item.courtDatetime ? formatDate(item.courtDatetime) : undefined,
                })));
                setLoadingSearch(false);
                setSearchParams(searchParams.toString())
            })
            .catch(err => {
                setLoadingSearch(false);
                setNoResults(true);
                console.log(err);
            });
    };

    const refreshSearch = (searchParams) => {
        const queryString = searchParams;
        setLoadingSearch(true);
        setNoResults(false);
        setFormSubmitted(true);

        axios.get(`${apiRoute}/api/SearchScreenViews/search?${queryString}`)
            .then(({ data }) => {
                setSearchResults(data?.map((item) => ({
                    ...item,
                    id: item.pkCaseId,
                    dateOfBirth: item.dateOfBirth ? formatDate(item.dateOfBirth) : undefined,
                    violationDate: item.violationDate ? formatDate(item.violationDate) : undefined,
                    courtDatetime: item.courtDatetime ? formatDate(item.courtDatetime) : undefined,
                })));
                setLoadingSearch(false);
            })
            .catch(err => {
                setLoadingSearch(false);
                setNoResults(true);
                console.log(err);
            });
    }
    //* --------------- GET ASSESS TOTAL AND NET TOTAL BY CASE ID (FOR CASE QUICK VIEW) ---------------

    const calculateSums = (data) => {
        const initialSums = {
            assessTotal: 0,
            suspendTotal: 0,
        };

        const sums = data.reduce((acc, item) => {
            acc.assessTotal += item.assessAmount;
            acc.suspendTotal += item.suspendAmount;
            return acc;
        }, initialSums);

        sums.netTotal = sums.assessTotal - sums.suspendTotal;

        return sums;
    };

    useEffect(() => {
        if (caseId) {
            axios.get(`${apiRoute}/api/FeeFineTbls/FkCaseId/${caseId}`)
                .then(({ data }) => {
                    const {
                        assessTotal,
                        netTotal,
                    } = calculateSums(data);

                    setQuickAssessTotal(assessTotal);
                    setQuickNetTotal(netTotal);
                })
                .catch(err => {
                    console.log("❌ FEE FINE BY CASE ID ERR");
                    setQuickAssessTotal(0);
                    setQuickNetTotal(0);
                })
        }
    }, [caseId]);

    const clearFields = (e) => {
        setFirstName('');
        setLastName('');
        setCaseNumber('');
        setCitationNumber('');
        setViolationDate('');
        setCourtDate('');
        setDriverLicenseNumber('');
        setSearchButtons('all');
        setDateOfBirth('');
        setAgency('');
        setCaseStatus('');
        setCaseType('');
        setSearchParams('');
        setFullCaseNumber('');
        setArrestNumber('');
    }

    const contextValue = {
        firstName: firstName,
        setFirstName,
        lastName: lastName,
        setLastName,
        caseNumber: caseNumber,
        setCaseNumber,
        citationNumber: citationNumber,
        setCitationNumber,
        violationDate: violationDate,
        setViolationDate,
        courtDate: courtDate,
        setCourtDate,
        driverLicenseNumber: driverLicenseNumber,
        setDriverLicenseNumber,
        searchButtons: searchButtons,
        setSearchButtons,
        loadingSearch: loadingSearch,
        setLoadingSearch,
        noResults: noResults,
        setNoResults,
        formSubmitted: formSubmitted,
        setFormSubmitted,
        searchResults: searchResults,
        setSearchResults,
        onSubmitHandler,
        caseId: caseId,
        setCaseId,
        quickAssessTotal: quickAssessTotal,
        setQuickAssessTotal,
        quickNetTotal: quickNetTotal,
        setQuickNetTotal,
        dateOfBirth: dateOfBirth,
        setDateOfBirth,
        agency: agency,
        setAgency,
        clearFields,
        caseStatus: caseStatus,
        setCaseStatus,
        searchParams: searchParams,
        setSearchParams,
        formatDate,
        refreshSearch,
        caseType: caseType,
        setCaseType,
        receiptNum: receiptNum,
        setReceiptNum,
        bondNumber: bondNumber,
        setBondNumber,
        postedLast: postedLast,
        setPostedLast,
        postedFirst: postedFirst,
        setPostedFirst,
        chargeDesc: chargeDesc,
        setChargeDesc,
        fullCaseNumber,
        setFullCaseNumber,
        arrestNumber,
        setArrestNumber,
        resetSearchContextPromise
    }

    return (
        <SearchContext.Provider value={contextValue}>
            {children}
        </SearchContext.Provider>
    )
}

export default SearchProvider;
export { SearchContext };
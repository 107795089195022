import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Box, Menu, AppBar, Toolbar, MenuItem, Typography, CircularProgress, Modal, Switch, FormControlLabel, Backdrop } from '@mui/material';
import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from "./Dropdown";
import { toast } from 'react-toastify';

import ArrowRight from "@mui/icons-material/ArrowRight";

import DisburseReportsModal from './ReportsModals/DisburseReportsModal';
import CollectionReportsModal from './ReportsModals/CollectionReportsModal';
import PrintDisbursementChecksModal from './ReportsModals/PrintDisbursementChecksModal';
import ReportsDateRangeModal from './ReportsModals/ReportsDateRangeModal';
import PrintFineScheduleModal from './ReportsModals/PrintFineScheduleModal';

import { ThemeModeContext, apiRoute } from '../../App';

import { BatchContext } from '../../context/BatchMgmtContext';
import { CaseContext } from '../../context/CaseContext';
import { DistributionCheckContext } from '../../context/DistributionCheckContext';
import { GeneralLedgerContext } from '../../context/GeneralLedgerContext'; 
import { SearchContext } from '../../context/SearchContext';
import { SystemContext } from '../../context/SystemContext';
import { UserContext } from '../../context/UserContext';
import ContactModal from './ContactModal';

const NavMenu = (props) => {
    const { setUtilInfo } = props;

    const { mode, toggleMode } = useContext(ThemeModeContext);

    const { resetBatchContextPromise } = useContext(BatchContext);

    const { resetDistributionCheckContextPromise } = useContext(DistributionCheckContext);

    const { resetGLContextPromise } = useContext(GeneralLedgerContext);

    const { setFormSubmitted, clearFields, refreshSearch, resetSearchContextPromise } = useContext(SearchContext);

    const { resetCaseContextPromise, setCaseView, setBalanceRows, setChargesRows, setViolationRows, setBalance } = useContext(CaseContext);

    const { resetSystemContextPromise } = useContext(SystemContext);

    const { entityName, entityId, userName, adminCheck, superAdminCheck, useFeeSchedule, changes, setChangesModal, setModalType, setFrom, resetUserContextPromise } = useContext(UserContext);

    const [agencyCode, setAgencyCode] = useState([]);

    const [utilitiesAnchorEl, setUtilitiesAnchorEl] = useState(null);
    const [closeOutAnchorEl, setCloseOutAnchorEl] = useState(null);
    const [closeOutMonthAnchorEl, setCloseOutMonthAnchorEl] = useState(null);
    const [accountAnchorEl, setAccountAnchorEl] = useState(null);
    const [helpAnchorEl, setHelpAnchorEl] = useState(null);

    const closeOutOpen = Boolean(closeOutAnchorEl);
    const closeOutMonthOpen = Boolean(closeOutMonthAnchorEl);
    const [selectedDate, setSelectedDate] = useState('');
    const [disbursefetch, setDisburseFetch] = useState(false);
    const [disbursefetchLoading, setDisburseFetchLoading] = useState(false);
    const [disburseAmountFetch, setDisburseAmountFetch] = useState(false);
    const [disburseAmountFetchLoading, setDisburseAmountFetchLoading] = useState(false);
    const [reportLoading, setReportLoading] = useState(false);
    const [disburseData, setDisburseData] = useState({});
    const [calcData, setCalcData] = useState({});
    const helpOpen = Boolean(helpAnchorEl);
    const utilitiesOpen = Boolean(utilitiesAnchorEl);
    const accountOpen = Boolean(accountAnchorEl);

    const [disburseReportsModal, setDisburseReportsModal] = useState(false);
    const [collectionReportsModal, setCollectionReportsModal] = useState(false);
    const [printDisbursementChecksModal, setPrintDisbursementChecksModal] = useState(false);
    const [reportsDateRangeModal, setReportsDateRangeModal] = useState(false);
    const [printFineScheduleModal, setPrintFineScheduleModal] = useState(false);
    const [reportsModalPath, setReportsModalPath] = useState("");
    const [selectedReport, setSelectedReport] = useState("");
    const [glPostingJournalFilter, setGlPostingJournalFilter] = useState("DateRange")

    // Contact Info Modal 
    const [contactModal, setContactModal] = useState(false);
    const [contactModalCalledFrom, setContactModalCalledFrom] = useState("");

    const openContact = () => {
        setContactModal(true);
    }
    const closeContact = () => setContactModal(false);

    const navigate = useNavigate();

    const handleCloseOutClick = (event) => {
        setCloseOutAnchorEl(event.currentTarget);
    };

    const handleCloseOut = () => {
        setCloseOutAnchorEl(null);
    }

    const handleCloseOutMonth = () => {
        setCloseOutMonthAnchorEl(null);
    }

    const handleCloseOutMonthClick = (event) => {
        setCloseOutMonthAnchorEl(event.currentTarget);
    };

    const handleHelpClick = (event) => {
        setHelpAnchorEl(event.currentTarget);
    };

    const handleHelpClose = () => {
        setHelpAnchorEl(null);
    };

    const handleUtilitiesClick = (event) => {
        setUtilitiesAnchorEl(event.currentTarget);
    };

    const handleUtilitiesClose = () => {
        setUtilitiesAnchorEl(null);
    };

    const handleAccountClick = (event) => {
        setAccountAnchorEl(event.currentTarget);
    };

    const handleAccount = () => {
        setAccountAnchorEl(null);
    };

    const handleCalc = async () => {
        setDisburseFetchLoading(true);

        const currentDate = new Date();
        // Check if the selected date is in the future
        if (new Date(selectedDate) > currentDate) {
            // Show an error message
            toast.error('A future date cannot be selected.', {
                position: 'top-left',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            setDisburseFetchLoading(false);
            return; // Stop further execution
        }

        try {
            const calc = await axios.get(`${apiRoute}/api/Disbursements/${selectedDate}/${entityId}`);
            setDisburseFetch(true)
            setDisburseFetchLoading(false);
            setCalcData(calc.data)
        } catch (err) {
            console.log(err);
            setDisburseFetchLoading(false);
            if (err.response.data === 'All open batches in closing month must be settled before closing and disbursing funds.') {
                toast.error('All open batches in closing month must be settled before closing and disbursing funds.', {
                    position: 'top-left',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            } else {
                toast.error(`Calc failed. ${err?.response?.data}`, {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            }
        }
    }

    const handleDisburseAmount = async () => {
        setDisburseAmountFetchLoading(true);
        try {
            const disburse = await axios.post(`${apiRoute}/api/Disbursements/DisburseIt/${selectedDate}/${entityId}`);
            setDisburseAmountFetch(true)
            setDisburseAmountFetchLoading(false);
            setDisburseData(disburse.data)
        } catch (err) {
            console.error("error disbursing", err);
            setDisburseAmountFetchLoading(false);
        }
    }

    const handleLogout = async () => {
        try {
            await axios.post(`${apiRoute}/api/auth/logout`);
            localStorage.removeItem('timeZone');
            localStorage.removeItem('token');
            localStorage.removeItem('shiftDate');
            localStorage.removeItem('selectedTab');
            setFormSubmitted(false);
            clearFields();
            await resetBatchContextPromise();
            await resetCaseContextPromise();
            await resetDistributionCheckContextPromise();
            await resetGLContextPromise();
            await resetSearchContextPromise();
            await resetSystemContextPromise();
            await resetUserContextPromise();
            navigate('/login');
        } catch (err) {
            console.log('Logout Failed', err);
        }
    }

    const handleDisburseReportsModalOpen = () => setDisburseReportsModal(true)
    const handleDisburseReportsModalClose = () => setDisburseReportsModal(false);
    const handleCollectionReportsModalOpen = () => setCollectionReportsModal(true);
    const handleCollectionReportsModalClose = () => setCollectionReportsModal(false);
    const handlePrintDisbursementChecksModalOpen = () => setPrintDisbursementChecksModal(true);
    const handlePrintDisbursementChecksModalClose = () => setPrintDisbursementChecksModal(false);
    const handleReportsDateRangeModalOpen = () => setReportsDateRangeModal(true);
    const handleReportsDateRangeModalClose = () => setReportsDateRangeModal(false);

    const handleGenerateReportClick = (reportPath) => {
        setReportLoading(true);
        axios.get(`${apiRoute}/${reportPath}`, { responseType: 'arraybuffer' })
            .then((response) => {
                const arrayBufferView = new Uint8Array(response.data);
                const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                const dataUrl = URL.createObjectURL(blob);
                window.open(dataUrl, '_blank');
                setReportLoading(false);
            })
            .catch(error => {
                console.error('Error fetching report from front end in NavMenu.js', error);
                setReportLoading(false);
            })
    }

    const handleLinkClick = (path) => {
        const notDataEntryPaths = ['/', '/new', '/batches', '/distribution-checks', '/close-out', '/login'];
        if (changes && path.includes('util')) {
            // open Settings menu and prompt modal to save or discard
            setModalType("saveChanges");
            setChangesModal(true)
        }
        else if (changes && notDataEntryPaths.includes(path)) {
            // Check for unsaved changes and clicked path is in notDataEntryPaths
            setFrom(['nav', path])
            setModalType("saveChanges");
            setChangesModal(true);
        } else {
            navigate(path);
            setCaseView({});
            setBalanceRows([]);
            setChargesRows([]);
            setViolationRows([]);
            setBalance(0.00);
        }
    };

    return (
        <>
            <AppBar position="sticky" sx={{ background: 'steelblue' }}>
                <Toolbar className="hoverClassTitle">
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} onClick={() => changes ? handleLinkClick('/') : refreshSearch() & navigate('/')} style={{ color: 'inherit', textDecoration: 'inherit', cursor: 'pointer' }}>
                        Secure Collect - {entityName}
                    </Typography>
                    <Box className="homePageClass" sx={{ display: { xs: 'inherit', }, alignItems: 'center' }}>
                        <MenuItem className="fontSizeMenu" style={{ color: 'inherit', textDecoration: 'inherit' }} onClick={() => handleLinkClick('/')}>
                            Home
                        </MenuItem>
                        <MenuItem className="fontSizeMenu" style={{ color: 'inherit', textDecoration: 'inherit' }} onClick={() => handleLinkClick('/new')}>
                            New Case
                        </MenuItem>
                        <MenuItem className="fontSizeMenu" style={{ color: 'inherit', textDecoration: 'inherit' }} onClick={() => handleLinkClick('/batches')}>
                            Batches
                        </MenuItem>
                        <MenuItem className="fontSizeMenu" style={{ color: 'inherit', textDecoration: 'inherit' }} onClick={() => handleLinkClick('/close-out')}>
                            Close Out
                        </MenuItem>
                        <MenuItem className="fontSizeMenu" style={{ color: 'inherit', textDecoration: 'inherit' }} onClick={() => handleLinkClick('/distribution-checks')}>
                            Distribution Checks
                        </MenuItem>
                        <Dropdown
                            trigger={
                                <MenuItem
                                    className="fontSizeMenu"
                                    color="inherit"
                                    textDecoration="inherit"
                                    sx={{
                                        fontSize: '1rem',
                                    }}
                                >
                                    Reports
                                </MenuItem>
                            }
                            menu={[
                                <DropdownNestedMenuItem
                                    key="collections"
                                    label="Collection Reports"
                                    rightIcon={<ArrowRight />}
                                    rightAnchored={true}
                                    menu={[
                                        <DropdownMenuItem
                                            key="paidCaseReport"
                                            onClick={() => {
                                                setReportsModalPath('api/CollectionReportViews/PaidCase?');
                                                handleReportsDateRangeModalOpen();
                                                setSelectedReport('PaidCaseReport');
                                            }}
                                        >
                                            Paid Case
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="batchTransactionDetail"
                                            onClick={() => {
                                                setReportsModalPath('api/CollectionReportViews/BatchCollectionByDateRange?');
                                                handleReportsDateRangeModalOpen();
                                                setSelectedReport('BatchCollectionByDateRange');
                                            }}
                                        >
                                            Batch Collection
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="batchTransactionDetail"
                                            onClick={() => {
                                                setReportsModalPath('api/CollectionReportViews/BatchTransactionDetail');
                                                handleReportsDateRangeModalOpen();
                                                setSelectedReport('BatchTransactionDetail');
                                            }}
                                        >
                                            Batch Transaction Detail
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="cashBondTransactions"
                                            onClick={() => {
                                                setReportsModalPath('api/CollectionReportViews/CashBondTransactions');
                                                handleReportsDateRangeModalOpen();
                                                setSelectedReport('CashBondTransactions');
                                            }}
                                        >
                                            Cash Bond Transactions
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="cashBonds"
                                            onClick={() => {
                                                handleGenerateReportClick('api/CollectionReportViews/CashBondsHeldReport');
                                            }}
                                        >
                                            Cash Bonds Balance
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="cashierCollections"
                                            onClick={() => {
                                                setReportsModalPath('api/CollectionReportViews/CashierCollections');
                                                handleCollectionReportsModalOpen();
                                            }}
                                        >
                                            Cashier Collections
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="casePayment"
                                            onClick={() => {
                                                setReportsModalPath('api/CollectionReportViews/CasePayment?');
                                                handleReportsDateRangeModalOpen();
                                                setSelectedReport('CasePayment');
                                            }}
                                        >
                                            Case Payment
                                        </DropdownMenuItem>,
                                        //<DropdownMenuItem
                                        //    key="chartOfAccounts"
                                        //    onClick={() => {
                                        //        handleGenerateReportClick('api/CollectionReportViews/ChartOfAccounts');
                                        //    }}
                                        //>
                                        //    Chart of Accounts
                                        //</DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="checkAndMoneyOrder"
                                            onClick={() => {
                                                setReportsModalPath('api/CollectionReportViews/CheckAndMoneyOrderCollections');
                                                handleCollectionReportsModalOpen();
                                            }}
                                        >
                                            Check/Money Order Collections
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="checkAndMoneyOrder"
                                            onClick={() => {
                                                setReportsModalPath('api/CollectionReportViews/CreditCardPayment');
                                                handleCollectionReportsModalOpen();
                                            }}
                                        >
                                            Credit Card Payments
                                        </DropdownMenuItem>,
                                        //<DropdownMenuItem
                                        //    key="depositTransmittal"
                                        //    onClick={() => {
                                        //        setReportsModalPath('api/CollectionReportViews/DepositTransmittal');
                                        //        handleCollectionReportsModalOpen();
                                        //    }}
                                        //>
                                        //    Deposit Transmittal
                                        //</DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="FineSchedule"
                                            onClick={() => setPrintFineScheduleModal(true)}
                                        >
                                            Fine Schedule Print Out
                                        </DropdownMenuItem>,
                                        //<DropdownMenuItem
                                        //    key="generalLedger"
                                        //    onClick={() => {
                                        //        axios
                                        //            .get(`${apiRoute}/api/AgencyTbls`)
                                        //            .then((res) => {
                                        //                setAgencyCode(res.data);
                                        //            })
                                        //            .then(() => {
                                        //                setReportsModalPath(`api/CollectionReportViews/GeneralLedger`);
                                        //                handleReportsDateRangeModalOpen();
                                        //                setSelectedReport('GeneralLedger');
                                        //            })
                                        //            .catch((err) => {
                                        //                console.log(err);
                                        //            });
                                        //    }}
                                        //>
                                        //    General Ledger
                                        //</DropdownMenuItem>,
                                        //<DropdownMenuItem
                                        //    key="miscCollections"
                                        //    onClick={() => {
                                        //        setReportsModalPath('api/CollectionReportViews/MiscCollReport');
                                        //        handleCollectionReportsModalOpen();
                                        //    }}
                                        //>
                                        //    Miscellaneous Collections
                                        //</DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="paymentRegister"
                                            onClick={() => {
                                                setReportsModalPath(
                                                    `api/CollectionReportViews/PaymentRegister?UserName=${userName}&`
                                                );
                                                handleReportsDateRangeModalOpen();
                                                setSelectedReport('PaymentRegister');
                                            }}
                                        >
                                            Payment Register
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="typeBasedPayment"
                                            onClick={() => {
                                                setReportsModalPath(`api/CollectionReportViews/PaymentReport`);
                                                handleReportsDateRangeModalOpen();
                                                setSelectedReport('TypeBasedPayment');
                                            }}
                                        >
                                            Type Based Payment
                                        </DropdownMenuItem>,
                                        //<dropdownmenuitem disabled={true}>
                                        //    comments report
                                        //</dropdownmenuitem>,
                                        //<dropdownmenuitem disabled={true}>
                                        //    monthly (by date) deposits
                                        //</dropdownmenuitem>,
                                        //<dropdownmenuitem disabled={true}>
                                        //    collections detail
                                        //</dropdownmenuitem>,
                                        //<dropdownmenuitem disabled={true}>
                                        //    settlement report
                                        //</dropdownmenuitem>,
                                        //<dropdownmenuitem disabled={true}>
                                        //    print nsf-end-of-month report
                                        //</dropdownmenuitem>
                                    ]}
                                />,
                                <DropdownNestedMenuItem
                                    key="distributions"
                                    label="Distribution Reports"
                                    rightIcon={<ArrowRight />}
                                    rightAnchored={true}
                                    menu={[
                                        <DropdownMenuItem
                                            key="checkRegister"
                                            onClick={() => {
                                                setReportsModalPath('api/CollectionReportViews/CheckRegisterBetweenDates');
                                                handleCollectionReportsModalOpen();
                                            }}
                                        >
                                            Check Register
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="cmisFormA"
                                            onClick={() => {
                                                setReportsModalPath('api/DisburseReportViews/CMISFormA');
                                                handleDisburseReportsModalOpen();
                                            }}
                                        >
                                            CMIS Form A
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="nlclc"
                                            onClick={() => {
                                                setReportsModalPath('api/DisburseReportViews/GetNLCLCFromDate/NLCLC')
                                                handleDisburseReportsModalOpen();
                                                //handleGenerateReportClick('api/DisburseReportViews/NLCLC');
                                            }}
                                        >
                                            Court Costs Collected For NLCLC
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="disbursementDetail"
                                            onClick={() => {
                                                setReportsModalPath('api/DisburseReportViews/DisbursementDetail');
                                                handleDisburseReportsModalOpen();
                                            }}
                                        >
                                            Disbursement Detail
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="disbursementRecon"
                                            onClick={() => {
                                                setReportsModalPath('api/DisburseReportViews/DisbursementRecon');
                                                handleDisburseReportsModalOpen();
                                            }}
                                        >
                                            Disbursement Reconciliation
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="distribution"
                                            onClick={() => {
                                                setReportsModalPath('api/DisburseReportViews/Distribution');
                                                handleDisburseReportsModalOpen();
                                                setSelectedReport('DistributionReport');
                                            }}
                                        >
                                            Distribution Report
                                        </DropdownMenuItem>,
                                        //<DropdownMenuItem
                                        //    key="guiltyPlea"
                                        //    onClick={() => {
                                        //        setReportsModalPath('api/DisburseReportViews/GuiltyPleaList/Disbursement');
                                        //        handleCollectionReportsModalOpen();
                                        //    }}
                                        //>
                                        //    Guilty Plea List Disbursement
                                        //</DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="lcle"
                                            onClick={() => {
                                                setReportsModalPath('api/DisburseReportViews/LCLE9903');
                                                handleDisburseReportsModalOpen();
                                            }}
                                        >
                                            LCLE 9903/9907/9919
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="louisianaJudicialCollegeFormA"
                                            onClick={() => {
                                                setReportsModalPath('api/DisburseReportViews/ACT405');
                                                handleDisburseReportsModalOpen();
                                            }}
                                        >
                                            Louisiana Judicial College Form A
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="refundReport"
                                            onClick={() => {
                                                setReportsModalPath('api/DisburseReportViews/RefundReport');
                                                handleReportsDateRangeModalOpen();
                                                setSelectedReport('RefundReport');
                                            }}
                                        >
                                            Refund Report
                                        </DropdownMenuItem>,
                                        //<DropdownMenuItem disabled={true}>
                                        //Disbursed Payment Reconciliation
                                        //</DropdownMenuItem>,
                                        //<DropdownMenuItem disabled={true}>
                                        //    Disbursed Collections
                                        //</DropdownMenuItem>,
                                        //<DropdownMenuItem disabled={true}>
                                        //    Disbursed Totals
                                        //</DropdownMenuItem>,
                                        //<DropdownMenuItem disabled={true}>
                                        //    Over/Under
                                        //</DropdownMenuItem>
                                    ]}
                                />,
                                <DropdownNestedMenuItem
                                    key="dataExport"
                                    label="Data Export"
                                    rightIcon={<ArrowRight />}
                                    rightAnchored={true}
                                    menu={[
                                        <DropdownMenuItem
                                            key="depositExtract"
                                            onClick={() => {
                                                setReportsModalPath('api/depositTbls/deposit-extract');
                                                handleReportsDateRangeModalOpen();
                                                setSelectedReport('DepositExtract');
                                            }}
                                        >
                                            Deposit Extract
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="distributionCheckExtract"
                                            onClick={() => {
                                                setReportsModalPath('api/ffchecktbls/distribution/check-extract');
                                                handleReportsDateRangeModalOpen();
                                                setSelectedReport('DistributionCheckExtract');
                                            }}
                                        >
                                            Distribution Check Extract
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="glTransactionExtract"
                                            onClick={() => {
                                                setReportsModalPath('api/gltransactionTbls/extract');
                                                handleReportsDateRangeModalOpen();
                                                setSelectedReport('GlTransactionExtract');
                                            }}
                                        >
                                            GL Transaction Extract
                                        </DropdownMenuItem>,
                                    ]}
                                />,
                                <DropdownNestedMenuItem
                                    key="otherReports"
                                    label="Other"
                                    rightIcon={<ArrowRight />}
                                    rightAnchored={true}
                                    menu={[
                                        <DropdownMenuItem
                                            key="generalLedger"
                                            onClick={() => {
                                                setReportsModalPath(`api/CollectionReportViews/GeneralLedger`);
                                                handleReportsDateRangeModalOpen();
                                                setSelectedReport('GeneralLedger');
                                            }}
                                        >
                                            GL Posting Journal
                                        </DropdownMenuItem>,
                                        <DropdownMenuItem
                                            key="trafficArraignment"
                                            onClick={() => {
                                                handleGenerateReportClick('api/DisburseReportViews/TrafficArraignment')
                                            }}
                                        >
                                            Traffic Arraignment
                                        </DropdownMenuItem>
                                    ]}
                                />
                            ]}
                        />
                        <Modal
                            open={disburseReportsModal}
                            onClose={handleDisburseReportsModalClose}
                            aria-labelledby="disbursed-recon-modal"
                            sx={{ width: '35%', m: '5vh auto' }}
                        >
                            <DisburseReportsModal
                                handleClose={handleDisburseReportsModalClose}
                                reportPath={reportsModalPath}
                            />
                        </Modal>
                        <Modal
                            open={collectionReportsModal}
                            onClose={handleCollectionReportsModalClose}
                            sx={{ width: '20%', m: '5vh auto' }}
                        >
                            <CollectionReportsModal
                                handleClose={handleCollectionReportsModalClose}
                                reportPath={reportsModalPath}
                            />
                        </Modal>
                        <Modal
                            open={reportsDateRangeModal}
                            onClose={handleReportsDateRangeModalClose}
                            sx={{ width: glPostingJournalFilter === "DisburseDate" ? '50%' : '25%', m: '5vh auto' }}
                        >
                            <ReportsDateRangeModal
                                handleClose={handleReportsDateRangeModalClose}
                                reportPath={reportsModalPath}
                                selectedReport={selectedReport}
                                agencyCode={agencyCode}
                                glPostingJournalFilter={glPostingJournalFilter}
                                setGlPostingJournalFilter={setGlPostingJournalFilter}
                            />
                        </Modal>
                        <Modal
                            open={printFineScheduleModal}
                            onClose={() => setPrintFineScheduleModal(false)}
                            sx={{ width: '25%', m: '5vh auto' }}
                        >
                            <PrintFineScheduleModal
                                handleClose={() => setPrintFineScheduleModal(false)}
                                handleGenerateReportClick={() => handleGenerateReportClick('api/CollectionReportViews/FineSchedule')}
                            />
                        </Modal>
                        <Modal
                            open={printDisbursementChecksModal}
                            onClose={handlePrintDisbursementChecksModalClose}
                            aria-labelledby="disbursment-checks-modal"
                            sx={{ width: '35%', m: '5vh auto' }}
                        >
                            <PrintDisbursementChecksModal handleClose={handlePrintDisbursementChecksModalClose} />
                        </Modal>
                        {adminCheck && (
                            <MenuItem
                                className="fontSizeMenu"
                                id="utilities-button"
                                aria-controls="utilities-menu"
                                aria-haspopup="true"
                                aria-expanded={utilitiesOpen ? 'true' : undefined}
                                onClick={(e) => changes ? handleLinkClick('/util') & handleUtilitiesClick(e) : handleUtilitiesClick(e)}
                                color="inherit"
                            >
                                Settings
                            </MenuItem>
                        )}
                        <Menu
                            id="utilities-menu"
                            anchorEl={utilitiesAnchorEl}
                            open={utilitiesOpen}
                            onClose={handleUtilitiesClose}
                            MenuListProps={{
                                'aria-labelledby': 'utilities-button',
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    setUtilInfo(
                                        {
                                            name: 'Agencies',
                                            controller: 'AgencyTbls',
                                            tableHeight: '75vh',
                                            id: 'pkAgencyId'
                                        }
                                    );
                                    handleUtilitiesClose();
                                }}
                            >
                                <Link to="/util/agencies" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                    Agency
                                </Link>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setUtilInfo(
                                        {
                                            name: 'Banks',
                                            controller: 'GlBankTbls',
                                            tableHeight: '75vh',
                                            id: 'pkBankId'
                                        }
                                    );
                                    handleUtilitiesClose();
                                }}
                            >
                                <Link to="/util/banks" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                    Bank
                                </Link>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setUtilInfo(
                                        {
                                            name: 'Gl Accounts',
                                            controller: 'GlAccountsTbls',
                                            tableHeight: '75vh',
                                            id: 'pkAccountId'
                                        }
                                    );
                                    handleUtilitiesClose();
                                }}
                            >
                                <Link to="/util/gl-accounts" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                    Chart of Accounts
                                </Link>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setUtilInfo(
                                        {
                                            name: 'Courts',
                                            controller: 'CourtTbls',
                                            tableHeight: '50vh',
                                            id: 'pkCourtId'
                                        },
                                    );
                                    handleUtilitiesClose();
                                }}
                            >
                                <Link to="/util/courts-and-divisions" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                    Courts
                                </Link>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setUtilInfo(
                                        {
                                            name: 'Divisions',
                                            controller: 'DivisionTbls',
                                            tableHeight: '50vh',
                                            id: 'pkDivisionId'
                                        },
                                    );
                                    handleUtilitiesClose();
                                }}
                            >
                                <Link to="/util/divisions" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                    Divisions
                                </Link>
                            </MenuItem>
                            {superAdminCheck && (
                                <MenuItem
                                    onClick={() => {
                                        setUtilInfo(
                                            {
                                                name: 'Disbursement Rules',
                                                controller: 'DisburseRuleTbls',
                                                tableHeight: '60vh',
                                                id: 'pkDisburseRuleId'
                                            }
                                        );
                                        handleUtilitiesClose();
                                    }}
                                >
                                    <Link to="/util/disbursement-rules" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                        Disbursement Rules
                                    </Link>
                                </MenuItem>
                            )}  
                            <MenuItem
                                onClick={() => {
                                    setUtilInfo(
                                        {
                                            name: 'Fee Rules',
                                            controller: 'FeeRuleTbls',
                                            tableHeight: '70vh',
                                            id: 'pkFeeRuleId'
                                        }
                                    )
                                    handleUtilitiesClose();
                                }}
                            >
                                <Link to="/util/fee-rules" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                    Fee Rules
                                </Link>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setUtilInfo(
                                        {
                                            name: 'Officers',
                                            controller: 'OfficerTbls',
                                            tableHeight: '80vh',
                                            id: 'pkOfficerId'
                                        }
                                    );
                                    handleUtilitiesClose();
                                }}
                            >
                                <Link to="/util/officers" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                    Officers
                                </Link>
                            </MenuItem>
                            {superAdminCheck && (
                                <MenuItem
                                    onClick={() => {
                                        setUtilInfo(
                                            {
                                                name: 'Organization Info',
                                                controller: 'Entities',
                                                tableHeight: '60vh',
                                                id: 'id'
                                            }
                                        );
                                        handleUtilitiesClose();
                                    }}
                                >
                                    <Link to="/util/organization" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                        Organization Info
                                    </Link>
                                </MenuItem>
                            )}
                            <MenuItem
                                onClick={() => {
                                    setUtilInfo(
                                        {
                                            name: 'Payees',
                                            controller: 'PayeeTbls',
                                            tableHeight: '60vh',
                                            id: 'pkPayeeId'
                                        }
                                    );
                                    handleUtilitiesClose();
                                }}
                            >
                                <Link to={`/util/payees`} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                    Payees
                                </Link>
                            </MenuItem>
                            {/*<MenuItem*/}
                            {/*    onClick={() => {*/}
                            {/*        setUtilInfo(*/}
                            {/*            {*/}
                            {/*                name: 'Speeding Amounts',*/}
                            {/*                controller: 'StatuteFineTblSpeed',*/}
                            {/*                tableHeight: '80vh',*/}
                            {/*                id: 'id'*/}
                            {/*            }*/}
                            {/*        );*/}
                            {/*        handleUtilitiesClose();*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <Link to="/util/speeding-amounts" style={{ color: 'inherit', textDecoration: 'inherit' }}>*/}
                            {/*        Speeding Amounts*/}
                            {/*    </Link>*/}
                            {/*</MenuItem>*/}
                            < MenuItem
                                onClick={() => {
                                    setUtilInfo(
                                        {
                                            name: 'Statutes/Charge Codes',
                                            controller: 'StatuteFineTbls',
                                            tableHeight: '75vh',
                                            id: 'pkStatuteFineId'
                                        }
                                    );
                                    handleUtilitiesClose();
                                }}
                            >
                                <Link to="/util/statute-fines" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                    Statutes/Charge Codes
                                </Link>
                            </MenuItem>
                            {superAdminCheck && (
                                <MenuItem
                                    onClick={() => {
                                        setUtilInfo(
                                            {
                                                name: 'System Codes',
                                                controller: 'SyscodeTbls',
                                                tableHeight: '75vh',
                                                id: 'pkSyscodeId'
                                            }
                                        );
                                        handleUtilitiesClose();
                                    }}
                                >
                                    <Link to="/util/sys-codes" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                        System Codes
                                    </Link>
                                </MenuItem>
                            )}
                            <MenuItem
                                onClick={() => {
                                    setUtilInfo(
                                        {
                                            name: 'Users',
                                            controller: 'Users',
                                            tableHeight: '50vh',
                                            id: 'pkUserId'
                                        },
                                    );
                                    handleUtilitiesClose();
                                }}
                            >
                                <Link to="/util/users" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                    Users
                                </Link>
                            </MenuItem>
                        </Menu>
                        <MenuItem
                            id="account-button"
                            className="fontSizeMenu"
                            aria-controls="account-menu"
                            aria-haspopup="true"
                            aria-expanded={accountOpen ? 'true' : undefined}
                            onClick={handleAccountClick}
                            color="inherit"
                        >
                            Account
                        </MenuItem>
                        <Menu
                            id="account-menu"
                            anchorEl={accountAnchorEl}
                            open={accountOpen}
                            onClose={handleAccount}
                            MenuListProps={{
                                'aria-labelledby': 'account-button',
                            }}
                        >
                            <MenuItem>{userName}</MenuItem>
                            <MenuItem onClick={async () => await handleLogout()}>Logout</MenuItem>
                            <MenuItem>
                                <Box>Dark Mode: &nbsp;</Box>
                                <FormControlLabel
                                    control={<Switch checked={mode === 'dark'} onChange={toggleMode} color="primary" />}
                                />
                            </MenuItem>
                        </Menu>
                        <MenuItem
                            id="help-button"
                            className="fontSizeMenu"
                            aria-controls="help-menu"
                            aria-haspopup="true"
                            aria-expanded={helpOpen ? 'true' : undefined}
                            onClick={handleHelpClick}
                            color="inherit"
                        >
                            Help
                        </MenuItem>
                        <Menu
                            id="help-menu"
                            anchorEl={helpAnchorEl}
                            open={helpOpen}
                            onClose={handleHelpClose}
                            MenuListProps={{
                                'aria-labelledby': 'help-button',
                            }}
                        >
                            {/*<MenuItem onClick={handleHelpClose}>*/}
                            {/*    <Link to="/release/notes" style={{ color: 'inherit', textDecoration: 'inherit' }}>*/}
                            {/*        Release Notes*/}
                            {/*    </Link>*/}
                            {/*</MenuItem>*/}
                            <MenuItem onClick={handleHelpClose}>
                                <Link
                                    onClick={(e) => {
                                        setContactModalCalledFrom('contactUs');
                                        openContact();
                                    }}
                                    style={{ color: 'inherit', textDecoration: 'inherit' }}
                                >
                                    Contact Us
                                </Link>
                            </MenuItem>
                            {/*<MenuItem onClick={handleHelpClose}>*/}
                            {/*    <Link to="/help/support" style={{ color: 'inherit', textDecoration: 'inherit' }}>*/}
                            {/*        Support*/}
                            {/*    </Link>*/}
                            {/*</MenuItem>*/}
                            {/*<MenuItem onClick={handleHelpClose}>*/}
                            {/*    <Link to="/help/documentation" style={{ color: 'inherit', textDecoration: 'inherit' }}>*/}
                            {/*        Documentation*/}
                            {/*    </Link>*/}
                            {/*</MenuItem>*/}
                            <MenuItem onClick={handleHelpClose}>
                                <Link
                                    onClick={(e) => {
                                        setContactModalCalledFrom('feedback');
                                        openContact();
                                    }}
                                    style={{ color: 'inherit', textDecoration: 'inherit' }}
                                >
                                    Feedback
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleHelpClose}>
                                <a
                                    href="https://sos.splashtop.com/?_gl=1*1byn50a*_gcl_au*MjM0Nzk2MjE3LjE2ODAyMDQ0ODg.*_ga*ODc4NzUzNDQ1LjE2ODAyMDQ0ODg.*_ga_GKBXSKZ3NW*MTY4NTY1MzA5Mi42LjEuMTY4NTY1MzE5OS4zOS4wLjA.&_ga=2.55024363.1912597960.1685653093-878753445.1680204488"
                                    style={{ color: 'inherit', textDecoration: 'inherit' }}
                                    download
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    View my screen
                                </a>
                            </MenuItem>
                        </Menu>
                        <Modal
                            open={contactModal}
                            sx={{
                                width: contactModalCalledFrom === 'feedback' ? '40%' : '20%',
                                minWidth: '400px',
                                height: '20vh',
                                margin: contactModalCalledFrom === 'feedback' ? '10vh auto' : '30vh auto',
                                borderRadius: '.5em',
                            }}
                        >
                            <ContactModal contactModalCalledFrom={contactModalCalledFrom} closeContact={closeContact} />
                        </Modal>

                    </Box>

                </Toolbar>
            </AppBar>
            {reportLoading && (
                <Backdrop open sx={{ zIndex: 1 }}>
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <p>
                            <CircularProgress />
                        </p>
                    </div>
                </Backdrop>
            )}
        </>
    );
};
export default NavMenu;
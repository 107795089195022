import React, { useContext } from 'react';
import axios from 'axios';

import { LinearProgress, Button, Box } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { DistributionCheckContext } from '../../../context/DistributionCheckContext.js';
import { apiRoute } from '../../../App.js';

const DistributionCheckTable = (props) => {
    const { handleOpen, setSelectedCheck, modal, setLoadingCheck } = props;

    const { rows, loading } = useContext(DistributionCheckContext);

    const getRow = (params) => setSelectedCheck(params.row);

    const handlePrint = (id, checkNum, checkDate) => {
        setLoadingCheck(true);
        axios.get(`${apiRoute}/api/PrintDisbursementChecks/Reprint?checkId=${id}&checkDate=${checkDate}&checkNum=${checkNum}`, { responseType: 'arraybuffer' })
            .then((response) => {
                const arrayBufferView = new Uint8Array(response.data);
                const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                const dataUrl = URL.createObjectURL(blob);
                window.open(dataUrl, '_blank');
            })
            .catch(error => {
                console.error('Error fetching report from front end:', error);
            })
            .finally(() => setLoadingCheck(false));
    }; 

    const columns = [
        {
            field: 'payeeName',
            headerName: 'Receiving Entities',
            width: 300
        },
        {
            field: 'remitterAddress',
            headerName: "Receiving Entities Address",
            width: 300
        },
        {
            id: 'checkNumber',
            field: 'checkNumber',
            headerName: 'Check #',
            headerAlign: 'center',
            align: 'center',
            width: 180
        },
        {
            field: 'checkAmount',
            headerName: 'Check Amount',
            headerAlign: 'right',
            align: 'right',
            width: 150,
            valueFormatter: ({ value }) => !value ? '$0.00' : `$${value.toFixed(2).toLocaleString()}`,
        },
        {
            field: 'date',
            headerName: 'Print Date',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            type: 'dateTime',
            valueGetter: (params) => new Date(params.row.date),
            renderCell: (params) => {
                const { row } = params
                const formattedDate = new Date(row.date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                });

                return <div>{formattedDate}</div>
                ;
            }
            // render cell property: pass in params and call renderDate method
        },
        {
            field: 'view',
            headerName: 'View',
            headerAlign: 'center',
            align: 'center',
            width: 125,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ backgroundColor: 'steelblue', color: 'white' }}
                        onClick={() => handlePrint(row.pkFfCheckId, row.checkNumber, row.date)}
                        disabled={modal}
                    >
                        View Check
                    </Button>
                )
            }
        },
        {
            field: 'print',
            headerName: 'Print',
            headerAlign: 'center',
            align: 'center',
            width: 125,
            renderCell: () => {
                return (
                    <Button fullWidth variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleOpen} disabled={modal}>
                        <PrintIcon />
                    </Button>
                )
            }
        }
    ];

    return (
        <Box sx={{ height: '65vh' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                loading={loading}
                disableMultipleRowSelection
                onRowClick={getRow}
                density="compact"
                initialState={{
                    sorting: { sortModel: [{ field: 'date', sort: 'desc' }] },
                }}
                slots={{
                    toolbar: GridToolbar,
                    loadingOverlay: LinearProgress
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                        printOptions: { disableToolbarButton: true },
                    },
                }}
                localeText={{
                    noRowsLabel:
                        'No Distribution Checks Found',
                }}
                rowHeight={60}
                sx={{
                    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                        marginTop: "1em",
                        marginBottom: "1em"
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                        outline: "none !important",
                    },
                    maxHeight: '65vh',
                }}
            />
        </Box>
    )
}

export default DistributionCheckTable;
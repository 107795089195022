import axios from "axios";
import React, { useContext, useReducer, useRef, useState } from "react";
 
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, LinearProgress, Paper, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';

import { ThemeModeContext, apiRoute } from "../../../../../App";
import UserContext from "../../../../../context/UserContext";
import { cleanPhoneNumber, formatPhoneNumber } from "../../../../../Utils";

const UserForm = (props) => {
    const { handleClose, data, loading, setLoading, modalType, ts, rows, setRows, getRows } = props;

    const { superAdminCheck, userRoleChoices, users } = useContext(UserContext);
    const { mode } = useContext(ThemeModeContext);
  

    const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
        position: 'relative',
        padding: '0 8px', // Adds padding around the label text to create space for the background
        backgroundColor: mode === 'dark' ? '#313131' : theme.palette.background.default, // Match the form's background
        zIndex: 1, // Ensures the label is on top
    }));

    const initialState = {
        name: modalType === 'add' ? '' : data?.name || '',
        email: modalType === 'add' ? '' : data?.email || '',
        password: modalType === 'add' ? '' : '',
        confirmPassword: modalType === 'add' ? '' : '',
        phoneNumber: modalType === 'add' ? '' : data?.phoneNumber || '',
        roles: modalType === 'add' ? [] : users?.filter((item) => item.id === data?.id)[0]?.roles || [],
        isTfaEmailEnabled: modalType === 'add' ? false : data?.isEmailEnabled,
        isTfaPhoneEnabled: modalType === 'add' ? false : data?.isPhoneEnabled,
        isAccountLocked: modalType === 'add' ? false : data?.isAccountLocked,
        errors: {}
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);
    const phoneNumberRef = useRef("");
    const [displayPhoneNumber, setDisplayPhoneNumber] = useState(
        formatPhoneNumber(state?.phoneNumber)
    );


    const handleFieldChange = (field, value) => {
        dispatch({ type: "UPDATE_FIELD", field, value });
    };

    const handleBlur = (field, value) => {
        let error = null;
        switch (field) {
            case 'name':
                if (value.trim() === '') {
                    error = 'User Name Required';
                } else if (value.length < 1 || value.length > 255) {
                    error = 'Name must be between 1 and 255 characters';
                }
                break;
            case 'email':
                if (value.trim() === '') {
                    error = 'Email Required';
                } else if (value.length > 255) {
                    error = 'Email is too long';
                } else if (!/\S+@\S+\.\S+/.test(value)) {
                    error = 'Invalid email format';
                }
                break;
            case 'password':
                if (modalType === 'add') {
                    if (value.trim() === '') {
                        error = 'Password Required';
                    } else if (value.length < 8 || value.length > 25) {
                        error = 'Password must be between 8 and 25 characters';
                    } else if (!/[a-z]/.test(value)) {
                        error = 'Password must contain at least one lowercase letter';
                    } else if (!/[A-Z]/.test(value)) {
                        error = 'Password must contain at least one uppercase letter';
                    } else if (!/\d/.test(value)) {
                        error = 'Password must contain at least one digit';
                    } else if (!/[!@#$%^&*]/.test(value)) {
                        error = 'Password must contain at least one special character (!@#$%^&*)';
                    } else if (/\s/.test(value)) {
                        error = 'Password cannot contain whitespace';
                    }
                }
                break;
            case 'confirmPassword':
                const password = state.password;
                if (value !== password) {
                    error = 'Confirm password must match Password';
                }
                break;
            case 'phoneNumber':
                const phoneNumberPattern = /^\(\d{3}\) \d{3}-\d{4}$/;
                if (value?.length > 0) {
                    if (!/^\d{10,15}$/.test(value)) {
                        error = 'Phone number must be 10 to 15 digits';
                    }
                    if (value.length >= 10 && (value[0] === ('0') || value[0] === ('1'))) {
                        error = "Area Code cannot begin with 1 or 0";
                    }
                    if (value.length >= 10 && value[1] === "9") {
                        error = "Area Code middle digit cannot be 9";
                    }
                }
                break;
            case 'roles':
                if (!value || value.length === 0) {
                    error = 'At least one role must be selected';
                }
                break;

            case 'isTfaPhoneEnabled':
                if (value && !state.phoneNumber) {
                    error = 'Please provide Phone number to enable Text MFA';
                }
                break;
            case 'isTfaEmailEnabled':
                if (value && !state.email) {
                    error = 'Please provide Email to enable Email MFA';
                }
                break;
            default:
                break;
        }
        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const handleRoleChange = (value) => {
        const updatedRoles = state?.roles?.includes(value)
            ? state?.roles?.filter((role) => role !== value)
            : [value];
        handleFieldChange('roles', updatedRoles);
    };

    const userRolesArray = superAdminCheck
        ? userRoleChoices
        : userRoleChoices.filter((role) => role.code !== "SUPER_ADMIN");

    const handlePhoneNumberChange = (e, field) => {
        const { value, selectionStart } = e.target;
        const formattedPhoneNumber = formatPhoneNumber(value);
        setDisplayPhoneNumber(formattedPhoneNumber);
        handleFieldChange(field, cleanPhoneNumber(value));
        state.isTfaPhoneEnabled = false
        setTimeout(() => {
            const diff = formattedPhoneNumber.length - value.length;
            const newPosition = selectionStart + diff;

            if (phoneNumberRef && phoneNumberRef.current) {
                phoneNumberRef.current.setSelectionRange(newPosition, newPosition);
            }
        }, 0);
        // }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (state?.errors?.name || state?.errors?.email || state?.errors?.password || state?.errors?.confirmPassword || state?.errors?.phoneNumber) {
            toast.error(`Please fix the errors before submitting the form.`, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            return;
        } else {
            setLoading(true);
            if (modalType === 'add') {
                const user = {
                    name: state?.name,
                    email: state?.email,
                    password: state?.password,
                    confirmPassword: state?.confirmPassword,
                    phoneNumber: cleanPhoneNumber(state?.phoneNumber),
                    isEmailMFA: state.isTfaEmailEnabled,
                    isTextMFA: state.isTfaPhoneEnabled,
                    isAccountLocked: state.isAccountLocked,
                    roles: state?.roles,
                }
                axios.post(`${apiRoute}/api/users/addUser`, user)
                    .then((res) => {
                        setRows([...rows, { ...res.data, id: res.data.pkUserId, roles: state?.roles }])
                        toast.success('User added successfully!', {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored',
                        });
                        handleClose();
                    })
                    .catch((err) => {
                        if (!!err?.response?.data) {
                            toast.error(`${err?.response?.data}`, {
                                position: 'top-right',
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: 'colored',
                            });
                        } else {
                            toast.error(`Error ${err?.response?.status} while adding new User!`, {
                                position: 'top-right',
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: 'colored',
                            });
                        }
                        console.log(err, 'Error adding user!');
                    })
                    .finally(() => { setLoading(false); getRows() })
            } else {
                const user = {
                    ...data,
                    name: state?.name,
                    email: state?.email,
                    password: state?.password,
                    confirmPassword: state?.confirmPassword,
                    phoneNumber: cleanPhoneNumber(state?.phoneNumber),
                    isEmailMFA: state.isTfaEmailEnabled,
                    isTextMFA: state.isTfaPhoneEnabled,
                    isAccountLocked: state.isAccountLocked,
                    roles: state?.roles,
                }
                axios.put(`${apiRoute}/api/users/${data.id}`, user)
                    .then((res) => {
                        const updatedRows = rows?.map(row => {
                            if (row.id === data.id) {
                                return { ...row, ...user };
                            }
                            return row;
                        });
                        setRows(updatedRows);
                        toast.success('User updated successfully!', {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored',
                        });
                        handleClose();
                    })
                    .catch((err) => {
                        if (!!err?.response?.data) {
                            toast.error(`${err?.response?.data}`, {
                                position: 'top-right',
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: 'colored',
                            });
                        } else {
                            toast.error(`Error ${err?.response?.status} while updating new User!`, {
                                position: 'top-right',
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: 'colored',
                            });
                        }

                    })
                    .finally(() => { setLoading(false); getRows() })
            }
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Paper elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                        <h1>
                            <strong>
                                {
                                    modalType === 'add' ? 'Add User' : 'Edit User'
                                }
                            </strong>
                        </h1>
                    </Paper>
                    <Box p={1}>
                        <Box width={1} flexWrap="wrap" display="flex">
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="name"
                                    label="User Name"
                                    name="name"
                                    value={state.name}
                                    onChange={(e) => {
                                        handleFieldChange('name', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('name', e.target.value) }}
                                    helperText={state?.errors?.name}
                                    error={!!state?.errors?.name}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="email"
                                    label="User Email"
                                    name="email"
                                    type="email"
                                    value={state.email}
                                    onChange={(e) => {
                                        handleFieldChange('email', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('email', e.target.value) }}
                                    helperText={state?.errors?.email}
                                    error={!!state?.errors?.email}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required={modalType === 'add'}
                                    sx={ts}
                                    id="password"
                                    label={modalType === 'add' ? 'Password' : 'New Password'}
                                    name="password"
                                    type="password"
                                    value={state.password}
                                    onChange={(e) => {
                                        handleFieldChange('password', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('password', e.target.value) }}
                                    helperText={state?.errors?.password}
                                    error={!!state?.errors?.password}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required={modalType === 'add'}
                                    sx={ts}
                                    id="confirmPassword"
                                    label={modalType === 'add' ? 'Confirm Password' : 'Confirm New Password'}
                                    name="confirmPassword"
                                    type="password"
                                    value={state.confirmPassword}
                                    onChange={(e) => {
                                        handleFieldChange('confirmPassword', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('confirmPassword', e.target.value) }}
                                    helperText={state?.errors?.confirmPassword}
                                    error={!!state?.errors?.confirmPassword}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="phoneNumber"
                                    label="Phone Number"
                                    name="phoneNumber"
                                    type="phoneNumber"
                                    value={displayPhoneNumber}
                                    onChange={(e) => {
                                        handlePhoneNumberChange(e, "phoneNumber");
                                    }}
                                    onBlur={(e) => {
                                        if (displayPhoneNumber.length > 0) {
                                            const newPhoneNumber = formatPhoneNumber(
                                                e.target.value);
                                            setDisplayPhoneNumber(newPhoneNumber);
                                            handleFieldChange("phoneNumber", cleanPhoneNumber(e.target.value));
                                            handleBlur("phoneNumber", cleanPhoneNumber(e.target.value));
                                        }
                                    }}
                                    helperText={state?.errors?.phoneNumber}
                                    error={!!state?.errors?.phoneNumber}
                                    variant="outlined"
                                    placeholder="(XXX) XXX-XXXX"
                                    inputProps={{
                                        maxLength: 14,
                                        ref: phoneNumberRef,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>

                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3} >
                                <Box
                                    component="fieldset"
                                    sx={{ p: 2, border: '1px solid lightgrey', position: 'relative', padding: '5px' }}
                                >
                                    <StyledFormLabel
                                        component="legend"
                                        sx={{ position: 'absolute', top: '-10px', left: '5px',  padding: '0 4px', width: 'fit-content', fontSize: '0.75rem' }}
                                    >
                                        MFA Modes
                                    </StyledFormLabel>
                                    <FormGroup row sx={{ justifyContent: 'space-between', alignItems: 'center', marginRight:'7rem' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={state.isTfaEmailEnabled}
                                                    onChange={(e) => handleFieldChange('isTfaEmailEnabled', e.target.checked)}
                                                    onBlur={(e) => {
                                                        handleBlur('isTfaEmailEnabled', e.target.checked);
                                                    }}
                                                    name="isTfaEmailEnabled"
                                                    disabled={!state.email}
                                                />
                                            }
                                            label="Email"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={state.isTfaPhoneEnabled}
                                                    onChange={(e) => handleFieldChange('isTfaPhoneEnabled', e.target.checked)}
                                                    onBlur={(e) => {
                                                        handleBlur('isTfaPhoneEnabled', e.target.checked);
                                                    }}
                                                    name="isTfaPhoneEnabled"
                                                    disabled={!displayPhoneNumber}
                                                />
                                            }
                                            label="Phone"
                                        />
                                        {state?.errors?.isTfaEmailEnabled && (
                                            <FormHelperText error style={{ marginLeft: '12px' }}>
                                                {state.errors.isTfaEmailEnabled}
                                            </FormHelperText>
                                        )}
                                        {state?.errors?.isTfaPhoneEnabled && (
                                            <FormHelperText error style={{ marginLeft: '12px' }}>
                                                {state.errors.isTfaPhoneEnabled}
                                            </FormHelperText>
                                        )}

                                    </FormGroup>
                                </Box>
                            </Box>




                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <FormControl component="fieldset" variant="standard" sx={{ p: 2, border: '1px solid lightgrey', position: 'relative', padding: '5px' }}>
                                    <StyledFormLabel component="legend" sx={{ position: 'absolute', top: '-10px', left: '5px', padding: '0 4px', width: 'fit-content', fontSize: '0.75rem' }}>User Roles</StyledFormLabel>
                                    <FormGroup row sx={{ justifyContent: 'space-between', alignItems: 'center',  }}>
                                        {userRolesArray?.map((role) => {
                                            const { code } = role;
                                            return (
                                                <FormControlLabel
                                                    key={code}
                                                    control={
                                                        <Checkbox
                                                            checked={state?.roles.includes(code)}
                                                            onChange={() => handleRoleChange(code)}
                                                            name="roles"
                                                            value={role}
                                                        />
                                                    }
                                                    label={code.replace('COLLECT_', '')}
                                                />
                                            )
                                        })}
                                    </FormGroup>
                                    {state?.errors?.roles && (
                                        <FormHelperText error style={{ marginLeft: '12px' }}>
                                            {state.errors.roles}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Box>

                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3} >
                                <Box
                                    component="fieldset"
                                    sx={{ p: 2, border: '1px solid lightgrey', position: 'relative', padding: '5px' }}
                                >
                                    <StyledFormLabel
                                        component="legend"
                                        sx={{ position: 'absolute', top: '-10px', left: '5px', padding: '0 4px', width: 'fit-content', fontSize: '0.75rem' }}
                                    >
                                    Account Locked
                                    </StyledFormLabel>
                                    <FormGroup row sx={{ justifyContent: 'space-between', alignItems: 'center', }}>
                                        <FormControlLabel
                                            key='isLocked'
                                            control={
                                                <Checkbox
                                                    checked={state?.isAccountLocked}
                                                    onChange={(e) => handleFieldChange('isAccountLocked', e.target.checked)}
                                                    name="isAccountLocked"
                                                />
                                            }
                                            label="Locked"
                                        />

                                    </FormGroup>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="right" gap={2} p={1}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Paper>
            </form>
            {
                loading && (
                    <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
                )
            }
        </>
    );
};

export default UserForm;


import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import UserContext from '../../../../../context/UserContext';

import { Box, Button, Checkbox, FormControlLabel, TextField, Paper, LinearProgress } from '@mui/material';

import { toast } from 'react-toastify';
import { apiRoute } from '../../../../../App';



const BankForm = (props) => {
    const { handleClose, data, loading, setLoading, modalType, ts, rows, setRows, getRows } = props;
    const { getBanks } = useContext(UserContext);

    const initialState = {
        name: modalType === 'add' ? null : data?.name,
        isActive: modalType === 'add' ? null : data?.isActive,
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;

        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value })
    };

    const handleBlur = (field, value) => {
        let error = null;

        if (field === 'name') {
            if (!value) {
                error = 'Name is required';
            }
        }
        dispatch({ type: 'UPDATE_FIELD', field, value, error })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        if (modalType === 'add') {
            const bank = {
                Name: state?.name,
                isActive: state?.isActive
            }
            axios.post(`${apiRoute}/api/GlBankTbls`, bank)
                .then((res) => {
                    setRows([...rows, { ...res.data, id: res.data.pkBankId }])
                    toast.success('Agency added successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                    getBanks();
                })
                .catch((err) => {
                    toast.error(`Error ${err?.response?.status} while creating GL Bank`, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });

                })
                .finally(() => { setLoading(false); getRows(); })
        } else {
            const bank = {
                ...data,
                name: state?.name,
                isActive: state?.isActive,
            }

            axios.put(`${apiRoute}/api/GlBankTbls/${bank.pkBankId}`, bank)
                .then((res) => {
                    const updatedRows = rows.map(row => {
                        if (row.id === data.id) {
                            return { ...row, ...res.data };
                        }
                        return row;
                    });
                    setRows(updatedRows);
                    toast.success('Agency updated successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error(`Error ${err?.response?.status} while updating GL Bank!`, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    })
                })
                .finally(() => { setLoading(false); getRows() })
        }
    }

    //The Form
    return (
        <>
            <Paper elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                    <h1>
                        <strong>{modalType === 'add' ? 'Add Bank' : 'Edit Bank'}</strong>
                    </h1>
                </Paper>
                <form onSubmit={handleSubmit}>
                    <Box p={1}>
                        <Box width={1} flexWrap="wrap" display="flex">
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="name"
                                    label="Name"
                                    name="name"
                                    value={state.name}
                                    onChange={(e) => {
                                        handleFieldChange('name', e.target.value);
                                    }}
                                    onBlur={(e) => handleBlur('name', e.target.value)}
                                    helperText={state?.errors?.name}
                                    error={!!state?.errors?.name}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <FormControlLabel
                                    label="Is Active"
                                    control={
                                <Checkbox
                                    fullWidth
                                    sx={ts}
                                    id="isActive"
                                    label="Is Active"
                                    name="isActive"
                                    checked={state.isActive === "Y"}
                                    onChange={(e) => {
                                        handleFieldChange('isActive', state.isActive === "N" ? "Y" : "N");
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                                    }
                                />
                            </Box> 
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="right" gap={2} p={1}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Paper>
        </>
    );
}

export default BankForm;
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';

import { Backdrop, Box, CircularProgress, Modal, Paper } from '@mui/material';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BatchTable from '../components/tables/views/BatchTable';
import BatchSearch from '../components/sections/batches/BatchSearch';
import BatchButtons from '../components/sections/batches/BatchButtons.js';
import BatchModals from '../components/modals/views/BatchModals';

import { BatchContext } from '../context/BatchMgmtContext';
import { ThemeModeContext, apiRoute } from '../App';
import UserContext from '../context/UserContext';

const BatchManagement = () => {
    const {
        setDate,
        setUsers,
        setBatchStatus,
        setBatchNumber,
        getBatches,
        setStatusCheck,
        setStartDate,
        setEndDate,
        onSubmitHandler,
        batchNumber,
        setOpenBatches,
        setSearchedBatches
    } = useContext(BatchContext);

    const { mode } = useContext(ThemeModeContext);
    const { banks, userName, userId, entityId } = useContext(UserContext);

    const [newBatch, setNewBatch] = useState({});
    const [selectedRow, setSelectedRow] = useState({});
    const [excludeDate, setExcludeDate] = useState(false);
    const [rowSelected, setRowSelected] = useState(false);
    const [batchList, setBatchList] = useState([]);
    const [reportLoading, setReportLoading] = useState(false);

    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [modalWidth, setModalWidth] = useState('');
    const [loading, setLoading] = useState(false);
    const [accountingPeriod, setAccountingPeriod] = useState(`${new Date().getMonth() + 1 > 12 ? 1 : new Date().getMonth() + 1}/${new Date().getFullYear()}`);
    const [selectedBank, setSelectedBank] = useState(banks[0]?.pkBankId);

    const [batchName, setBatchName] = useState(modalType === "openBatch" ? "Batch " + userName : selectedRow.batchName);
    const [batchDate, setBatchDate] = useState(dayjs(new Date()));
    const [batchType, setBatchType] = useState('SIMPLE');
    const [newBatchDate, setNewBatchDate] = useState(dayjs(!selectedRow ? '' : selectedRow.openDatetime));
    const [modalLoading, setModalLoading] = useState(false);
    const [batchIds, setBatchIds] = useState(new Set(batchList?.map(b => b?.batchNumber)));

    const [isPaymentExists, setIsPaymentExists] = useState(false);


    const handleOpen = async (type, width) => {
        setModalType(type);
        setModalWidth(width);
        setModal(true);
    };

    const handleClose = () => {
        setModalType('');
        setModalWidth('');
        setModal(false);
    };

    const resetSearch = () => {
        setDate('');
        setUsers('allUsers');
        setBatchStatus('open');
        setBatchNumber('');
        setExcludeDate(false);
        setRowSelected(false);
        setStatusCheck('Open');
        getBatches();
        setStartDate('');
        setEndDate('');
    };

    const setSearchClosedBatchParams = (status, batch) => {
        if (batch !== '')
            setBatchNumber(batch.batchNumber)
        else setBatchNumber('')
        setDate('');
        setUsers('allUsers');
        setBatchStatus(status);
        setExcludeDate(false);
        setRowSelected(false);
        setStatusCheck(status);
        setStartDate('');
        setEndDate('');
    }

    const setSearchParams = (param, param2) => {
        return new Promise((resolve) => {
            setSearchClosedBatchParams(param, param2);
            resolve();
        })
    }

    const batchReportSubmitHandler = (calledFor, value) => {
        const apiEndPoint =
            calledFor === 'Drawer Count'
                ? `${apiRoute}/api/BatchCloseoutTbls/DrawerCountReport?BatchId=${value}`// pkBatchId
                : `${apiRoute}/api/BatchCloseoutTbls/batchCloseoutReport?batchNumber=${value}`;// batchNumber
        if (selectedRow?.pkBatchId) {
            setReportLoading(true);
            axios.get(apiEndPoint, { responseType: 'arraybuffer' })
                .then((response) => {
                    const arrayBufferView = new Uint8Array(response.data);
                    const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                    const dataUrl = URL.createObjectURL(blob);
                    window.open(dataUrl, '_blank');
                })
                .catch((error) => {
                    console.error(`Error fetching ${calledFor} Report`, error);
                })
                .finally(() => setReportLoading(false));
        } else {
            console.log('No batches selected');
        }
    };

    const depositSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        const batchListJson = JSON.stringify(batchList);
        const batchNumList = JSON.parse(batchListJson);
        const pkBatchIds = [];

        // Iterate through the objects in the JSON and extract pkBatchId values
        for (const batch of batchNumList) {
            if (batch.hasOwnProperty("batchNumber")) {
                pkBatchIds.push(batch.pkBatchId.toString());
            }
        }
        // Combine the extracted values into a single string
        const pkBatchIdString = pkBatchIds.join(",");
        try {

            // Make the Axios GET request with the batchListQueryParam as a parameter
            //await axios.get(`${apiRoute}/api/BatchTbl/deposit/${pkBatchIdString}`, {

            //const deposit = await createGlDepositRecord(batchList, accountingPeriod);
            const period = accountingPeriod?.split('/');
            const month = period[0];
            const year = period[1];
            const batchNums = [];

            batchList.forEach((b) => {
                batchNums.push(parseInt(b.batchNumber));
            });

            const deposit = await axios.post(`${apiRoute}/api/GlTransactionTbls/deposit/${selectedBank}/${month}/${year}`, batchNums);
            console.log('deposit here', deposit);
            await axios.get(`${apiRoute}/api/BatchTbl/deposit/${pkBatchIdString}/${deposit.data[0].referenceNo}`, {
                responseType: 'arraybuffer',
            })
                .then((response) => {
                    const arrayBufferView = new Uint8Array(response.data);
                    const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    return url;
                })
                .then((url) => {
                    window.open(url);
                    handleClose();
                })
                .catch((error) => {
                    toast.error(`Error occurred generating deposit report: ${error?.response?.data}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                    console.error('API Error:', error);
                    console.log('HTTP Status:', error.response.status);
                    console.log('Response Data:', error.response.data);
                })
                .finally(() => { setLoading(false); setBatchList([]); onSubmitHandler(e); });
        }
        catch (err) {
            console.log('error', err);
            toast.error(`Error occurred while creating GL Deposit Record: ${err?.response?.data}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            console.error('Error occurred depositSubmitHandler', err);
            setLoading(false);
        }
    };

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black"
        },
        '& .MuiInputLabel-root': {
            color: mode === "dark" ? "white" : "black",
            textShadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    const handleBatchOpen = async () => {
        let dayjsBatchDate =
            modalType === 'openBatch'
                ?
                dayjs(batchDate)
                : isPaymentExists ?
                    dayjs(selectedRow?.openDatetime)
                    : dayjs(batchDate);


        const { $D } = dayjsBatchDate;
        if (isNaN($D)) {
            return toast.error(`Select a date to open a new batch.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        const { batchNumber, shiftDate, settleDate, settledBy, fkUserId, pkBatchId } = selectedRow;
        const batchData = modalType === 'openBatch'
            ? {
                fkUserId: userId,
                entityId: entityId,
                openDatetime: dayjsBatchDate.toISOString().split('T')[0],
                batchType: 'SIMPLE',
                batchName: batchName
            }
            : {
                pkBatchId: batchList[0].pkBatchId,
                fkUserId: batchList[0].fkUserId,
                entityId: entityId,
                openDatetime: dayjsBatchDate.toISOString().split('T')[0],
                closeDatetime: null,
                batchName: batchList[0].batchName,
                batchType: 'SIMPLE',
                batchNumber: batchList[0].batchNumber,
                shiftDate: batchList[0].shiftDate,
                settleDate: batchList[0].settleDate,
                settledBy: batchList[0].settledBy,
            }
        setModalLoading(true)
        try {
            const { pkBatchId } = selectedRow;
            const { data } = modalType === 'openBatch'
                ? await axios.post(`${apiRoute}/api/batchtbl`, batchData)
                : await axios.put(`${apiRoute}/api/batchtbl/${batchList[0].pkBatchId}`, batchData)
            setOpenBatches((prevBatches) => prevBatches && prevBatches.length > 0 ? [...prevBatches, data] : [data]);
            toast.success(`Batch ${modalType === 'openBatch' ? `${batchName}(${data?.batchNumber})` :
                batchData?.batchName
                    ?
                    `${batchData?.batchName} (${batchData?.batchNumber})`
                    :
                    `${batchData?.batchNumber}`} opened.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            resetSearch();
            handleClose();
            setBatchList([]);
        } catch (err) {
            console.error('error closing batch', err);
            if (err?.response?.status === 400) {
                toast.error(`Batch already exists with selected Payment Date`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {

                // const { batchName } = newBatch
                toast.error(`Error opening batch ${modalType === 'openBatch'
                    ?
                    `${batchName}`
                    :
                    batchData?.batchName
                        ?
                        `${batchData?.batchName}(${batchData?.batchNumber})`
                        :
                        `${batchData?.batchNumber}`}.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } finally { setModalLoading(false) };
    };

    const handleBatchClose = async (bool, e) => {
        setModalLoading(true);
        setBatchList((batchList) => batchList?.forEach(batch => batch.openDatetime = new Date(batch?.openDatetime)));
        await axios.put(`${apiRoute}/api/batchtbl/close`, batchList)
            .then(({ data }) => {
                setSearchedBatches((prevBatches) => {
                    for (const batch of data) {
                        let existingBatch = prevBatches.filter(p => p.pkBathckId = batch.pkBatchId);
                        existingBatch = batch;
                        prevBatches?.filter(({ batchNumber }) => batchNumber !== existingBatch.batchNumber);

                    }
                });
                setOpenBatches((prevBatches) => {
                    for (const batch of data) {
                        let existingBatch = prevBatches?.filter(p => p.pkBathckId = batch.pkBatchId);
                        existingBatch = batch;
                        prevBatches?.filter(({ batchNumber }) => batchNumber !== existingBatch.batchNumber);
                    }
                })
                toast.success(`Batches successfully closed.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                handleClose();
            })
            .catch((err) => {
                console.log('error getting batch', err);
                toast.error(`Error closing batches.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .finally(() => setModalLoading(false));
        if (batchList.length === 1) await onSubmitHandler(e);
    };

    const handleDateChange = async () => {
        const { $D } = newBatchDate;
        if (isNaN($D)) {
            return toast.error(`Select which date to update the batch date to.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        setModalLoading(true);
        axios.get(`${apiRoute}/api/batchtbl/${batchList[0]?.pkBatchId}`)
            .then((res) => {
                const batchData = {
                    ...res.data,
                    openDatetime: newBatchDate.toISOString().split('T')[0],
                };
                axios.put(`${apiRoute}/api/batchtbl/${batchList[0]?.pkBatchId}`, batchData)
                    .then(() => {
                        toast.success(`The date for batch ${batchList[0] && batchList[0].batchName ? `${batchList[0].batchName}(${batchList[0]?.batchNumber})` : batchList[0]?.batchNumber} has been updated.`, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        handleClose();
                        resetSearch();
                        setBatchList((prevBatches) => {
                            return prevBatches.filter(p => p.pkBatchId !== batchList[0].pkBatchId).map(p => p);
                        });
                    })
                    .catch((err) => {
                        console.error('error changing batch date', err);
                        toast.error(`Error ${err.reponse.status} while changing the date for batch ${batchList[0] && batchList[0].batchName ? `${batchList[0].batchName}(${batchList[0]?.batchNumber})` : batchList[0]?.batchNumber}.`, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    })
            })
            .catch((err) => {
                console.log('error getting selected batch', err);
                toast.error(`Error ${err.reponse.status} while changing the date for batch ${batchList[0] && batchList[0].batchName ? `${batchList[0].batchName}(${batchList[0]?.batchNumber})` : batchList[0]?.batchNumber}.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .finally(() => setModalLoading(false))
    };

    const handleBatchSettle = () => {
        setModalLoading(true);
        setBatchList((batchList) => batchList?.forEach(batch => batch.openDatetime = new Date(batch?.openDatetime)));
        axios.put(`${apiRoute}/api/batchtbl/settle`, batchList)
            .then(({ data }) => {
                setSearchedBatches((prevBatches) => {
                    for (const batch of data) {
                        let existingBatch = prevBatches.filter(p => p.pkBathckId = batch.pkBatchId);
                        existingBatch = batch;
                        prevBatches.filter(({ batchNumber }) => batchNumber !== existingBatch.batchNumber);

                    }
                });
                toast.success(`Batches settled successfully.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                handleClose();
            })
            .catch((err) => {
                console.log('error settling batch', err)
                toast.error(`Failed to settle batches.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .finally(() => { setModalLoading(false); setRowSelected(false); })
    };
    const { $D } = newBatchDate;

    useEffect(() => {
        setIsPaymentExists(selectedRow && selectedRow.Total > 0)
    }, [selectedRow]);

    useEffect(() => {
        if (modalType === 'closeBatch' && (batchList && batchList?.length > 1)) {
            setSearchParams('closed', '')
        }
        setBatchIds(new Set(batchList?.map(b => b?.batchNumber)))
    }, [batchList, modalType]);

    return (
        <>
            <Paper sx={{ height: '100%', pb: '1vh' }} elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '3vh', textAlign: 'center' }} elevation={10}>
                    <h1 style={{ fontWeight: 'bold' }}>Batch Management</h1>
                </Paper>
                <Box sx={{ width: '98%', m: '0 auto' }}>
                    <BatchSearch
                        excludeDate={excludeDate}
                        resetSearch={resetSearch}
                        ts={textFieldStyle}
                        setRowSelected={setRowSelected}
                        setBatchList={setBatchList}
                    />
                    <BatchButtons
                        isPaymentExists={selectedRow.total > 0 ? true : false}
                        rowSelected={rowSelected}
                        batchList={batchList}
                        selectedRow={selectedRow}
                        handleOpen={handleOpen}
                        setReportLoading={setReportLoading}
                    />
                    <Paper elevation={10} sx={{ mb: '1vh', maxHeight: '55vh' }}>
                        <BatchTable
                            setSelectedRow={setSelectedRow}
                            setRowSelected={setRowSelected}
                            setBatchList={setBatchList}
                            handleOpen={handleOpen}
                            setReportLoading={setReportLoading}
                            batchReportSubmitHandler={batchReportSubmitHandler}
                            setBatchIds={setBatchIds}
                        />
                    </Paper>
                </Box>
            </Paper>
            <Modal
                open={modal}
                sx={{
                    width: modalWidth,
                    margin: "5vh auto",
                    "& > .MuiBackdrop-root": {
                        backdropFilter: 'blur(5px)'
                    }
                }}
            >
                <BatchModals
                    isPaymentExists={selectedRow.total > 0 ? true : false}
                    ts={textFieldStyle}
                    modalType={modalType}
                    handleClose={handleClose}
                    newBatch={newBatch}
                    handleOpen={handleOpen}
                    selectedRow={selectedRow}
                    setRowSelected={setRowSelected}
                    resetSearch={resetSearch}
                    depositSubmitHandler={depositSubmitHandler}
                    depositLoad={loading}
                    accountingPeriod={accountingPeriod}
                    setAccountingPeriod={setAccountingPeriod}
                    banks={banks}
                    selectedBank={selectedBank}
                    setSelectedBank={setSelectedBank}
                    userName={userName}
                    setSearchParams={setSearchParams}
                    batchList={batchList}
                    setBatchList={setBatchList}
                    handleDateChange={handleDateChange}
                    handleBatchOpen={handleBatchOpen}
                    handleBatchClose={handleBatchClose}
                    handleBatchSettle={handleBatchSettle}
                    loading={modalLoading}
                    setLoading={setModalLoading}
                    batchDate={batchDate}
                    setBatchDate={setBatchDate}
                    batchType={batchType}
                    setBatchType={setBatchType}
                    batchName={batchName}
                    setBatchName={setBatchName}
                    batchIds={batchIds}
                    onSubmitHandler={onSubmitHandler}
                    newBatchDate={newBatchDate}
                    setNewBatchDate={setNewBatchDate}
                    $D={$D}
                    setBatchIds={setBatchIds}
                />
            </Modal>
            {reportLoading && (
                <Backdrop open sx={{ zIndex: 1 }}>
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <CircularProgress />
                    </div>
                </Backdrop>
            )}
        </>
    )
}

export default BatchManagement;
import React, { useEffect, useState } from 'react';

import { Box, Button, LinearProgress, MenuItem, Paper, TextField } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import CloseOutCountModal from '../components/batches/CloseOutCountModal';
import OpenBatchModal from '../components/batches/OpenBatchModal';

const BatchModals = (props) => {
    const {
        isPaymentExists,
        modalType,
        ts,
        handleClose,
        newBatch,
        selectedRow,
        handleOpen,
        resetSearch,
        depositSubmitHandler,
        depositLoad,
        accountingPeriod,
        setAccountingPeriod,
        selectedBank,
        setSelectedBank,
        batchList,
        setSearchParams,
        handleDateChange,
        handleBatchOpen,
        handleBatchClose,
        handleBatchSettle,
        loading,
        batchDate,
        setBatchDate,
        batchType,
        setBatchType,
        batchName,
        setBatchName,
        batchIds,
        onSubmitHandler,
        setLoading,
        newBatchDate,
        setNewBatchDate,
        $D,
        banks,
        setBatchIds
    } = props;

    const [ids, setIds] = useState(batchIds);

    return (
        <>
            {
                (modalType === 'openBatch' || modalType === 'reopenBatch') && (
                    <OpenBatchModal
                        newBatch={newBatch}
                        ts={ts}
                        isPaymentExists={isPaymentExists}
                        batchDate={batchDate}
                        setBatchDate={setBatchDate}
                        handleBatchOpen={handleBatchOpen}
                        batchType={batchType}
                        setBatchType={setBatchType}
                        handleClose={handleClose}
                        modalType={modalType}
                        selectedRow={selectedRow}
                        loading={loading}
                        batchName={batchName}
                        setBatchName={setBatchName}
                        batchList={batchList}
                    />
                )
            }
            {
                modalType === 'closeBatch' && (
                    <Paper elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                            <h1>
                                <strong>Close Batch</strong>
                            </h1>
                        </Paper>
                        <Box sx={{ p: '1vh' }}>
                            <Box sx={{ textAlign: 'center', mb: '2vh' }}>
                                <h2>Would you like to close batch(s): <br />{Array.from(ids).join(', ')}?</h2>
                            </Box>
                            <hr />
                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'right' }}>
                                <Button
                                    onClick={async (e) => {
                                        if (batchList?.length === 1) {
                                            handleOpen('moneyCountCheck', '35%');
                                            await setSearchParams('closed', batchList[0]);
                                        } else {
                                            await handleBatchClose(true, e);
                                            await onSubmitHandler(e);
                                        }
                                    }}
                                    variant="contained"
                                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    onClick={() => { handleClose(); resetSearch() }}
                                    variant="contained"
                                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                >
                                    No
                                </Button>
                            </Box>
                        </Box>
                        {
                            loading && <LinearProgress />
                        }
                    </Paper>
                )
            }
            {
                batchList?.length === 1 && modalType === 'moneyCountCheck' && (
                    <Paper elevation={10} sx={{ width: '80%', ml: '3vw' }}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '1vh', textAlign: 'center' }} elevation={10}>
                            <h3 style={{ fontWeight: 'bold' }}>Close Out Drawer Count?</h3>
                        </Paper>
                        <Box display="flex" gap={5} m={5} pb={1} justifyContent="center">
                            <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white', mb: '3vh' }} onClick={() => handleOpen('moneyCount', '75%')}>Count the Drawer</Button>
                            <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white', mb: '3vh' }} onClick={(e) => { handleBatchClose(true, e) }}>Skip Drawer Count</Button>
                            {/*<Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={() => handleClose}>Cancel</Button>*/}
                        </Box>
                        {
                            loading && <LinearProgress />
                        }
                    </Paper>
                )
            }
            {
                modalType === 'moneyCount' && (
                    <>
                        <CloseOutCountModal
                            selectedRow={selectedRow}
                            handleCloseOutCountModal={handleClose}
                            handleBatchClose={handleBatchClose}
                            ts={ts}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    </>
                )
            }
            {
                modalType === 'changeDate' && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Paper elevation={10}>
                            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '1vh', textAlign: 'center' }} elevation={10}>
                                <h1>
                                    <strong>Change Batch Date</strong>
                                </h1>
                            </Paper>
                            <Box sx={{ p: '1vh' }}>
                                <Box sx={{ textAlign: 'center', mb: '1vh' }}>
                                    <h4>Do you want to change the date for: <br/>{batchList[0] && batchList[0].batchName ? `${batchList[0].batchName}(${batchList[0]?.batchNumber})` : batchList[0]?.batchNumber}?</h4>
                                </Box>
                                <hr />
                                <Box>
                                    <h5>
                                        Select the new date for this batch:
                                    </h5>
                                    <StaticDatePicker
                                        value={newBatchDate}
                                        onChange={(newDate) => setNewBatchDate(newDate)}
                                        renderInput={(params) => <TextField {...params} label="Pick a Date" />}
                                        componentsProps={{
                                            actionBar: {
                                                actions: ['today'],
                                            },
                                        }}
                                        disabled={isPaymentExists}
                                    />
                                </Box>
                                <hr />
                                <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'right' }}>
                                    <Button onClick={handleDateChange} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }} disabled={isNaN($D)}>Save Changes</Button>
                                    <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }}>Cancel</Button>
                                </Box>
                            </Box>
                            {
                                loading && <LinearProgress />
                            }
                        </Paper>
                    </LocalizationProvider>
                )
            }
            {
                modalType === 'settleBatch' && (
                    <Paper elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                            <h1>
                                <strong>Settle Batch</strong>
                            </h1>
                        </Paper>
                        <Box sx={{ p: '1vh' }}>
                            <Box sx={{ textAlign: 'center', mb: '2vh' }}>
                                <h2>Would you like to settle batch(s): <br />{Array.from(batchIds).join(', ')/*selectedRow?.batchName ? `${selectedRow?.batchName}(${selectedRow?.batchNumber})` : selectedRow?.batchNumber*/}?</h2>
                            </Box>
                            <hr />
                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'right' }}>
                                <Button onClick={handleBatchSettle} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }}>Yes</Button>
                                <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }}>No</Button>
                            </Box>
                        </Box>
                        {
                            loading && <LinearProgress />
                        }
                    </Paper>
                )
            }
            {
                modalType === 'depositBatch' && (
                    <Paper
                        elevation={10}
                    >
                        <Paper
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                p: '.5vh',
                                mb: '2vh',
                                textAlign: 'center'
                            }}
                            elevation={10}
                        >
                            <h1>
                                <strong>Deposit Batches</strong>
                            </h1>
                        </Paper>
                        <Box
                            sx={{
                                p: '1vh'
                            }}
                        >
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    mb: '2vh'
                                }}
                            >
                                <h4>Do you want to deposit the selected batches?</h4>
                                <TextField
                                    sx={{
                                        width: "16vw",
                                        mt: '2vh'
                                    }}
                                    select
                                    label="Select Bank Account"
                                    value={selectedBank}
                                    onChange={
                                        (e) => {
                                            setSelectedBank(e.target.value)
                                        }
                                    }
                                >
                                    <MenuItem value={'N/A'}>N/A</MenuItem>
                                    {
                                        banks.map((bank) => {
                                            return (
                                                <MenuItem
                                                    key={bank.pkBankId}
                                                    value={bank.pkBankId}
                                                >
                                                    {bank.name}
                                                </MenuItem>
                                            )
                                        })

                                    }
                                </TextField>
                                <TextField
                                    sx={{
                                        width: "16vw", mt: '2vh'
                                    }}
                                    label="Accounting Period"
                                    value={accountingPeriod}
                                    onChange={(e) => { setAccountingPeriod(e.target.value) }}
                                >
                                </TextField>
                            </Box>
                            <hr />
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '1rem',
                                    justifyContent: 'right'
                                }}
                            >
                                <Button
                                    onClick={depositSubmitHandler}
                                    variant="contained"
                                    sx={{
                                        backgroundColor: 'steelblue',
                                        color: 'white'
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    onClick={handleClose}
                                    variant="contained"
                                    sx={{
                                        backgroundColor: 'steelblue',
                                        color: 'white'
                                    }}
                                >
                                    No
                                </Button>
                            </Box>
                        </Box>
                        {
                            depositLoad && <LinearProgress />
                        }
                    </Paper>
                )
            }
        </>
    )
}

export default BatchModals;
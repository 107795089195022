import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, TextField, Paper, Autocomplete, FormGroup, FormControlLabel, Checkbox, FormLabel, Divider } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { RiDeleteBinLine } from "react-icons/ri";
import { ToastContainer } from 'react-toastify';
import { SystemContext } from '../../../../context/SystemContext';
import UserContext from '../../../../context/UserContext';
import { apiRoute } from '../../../../App';


const InterestAndAllocationsModal = (props) => {
    const {
        ts,
        handleClose,
        payees,
        setPayees,
        disbursees,
        setDisbursees,
        saveBankInterest,
        applyingInterest,
        which,
        allocations,
        setAllocations,
        from,
        setShowPrevious,
        showPrevious
    } = props;

    const { allocationTypes } = useContext(SystemContext);
    const { entityId, allPayees } = useContext(UserContext)

    // Autocomplete Interest state values
    const [paytoNames, setPaytoNames] = useState(payees?.map(p => p.paytoName));
    const [interestValue, setInterestValue] = useState('');
    const [interestInput, setInterestInput] = useState('');

    // Autocomplete Allocation state values
    // Will start with different values
    const [allocateFromValue, setAllocateFromValue] = useState('');
    const [allocateToValue, setAllocateToValue] = useState('');

    // Autocomplete Allocation Input values
    const [allocateFromInput, setAllocateFromInput] = useState('');
    const [allocateToInput, setAllocateToInput] = useState('');

    const [allocationType, setAllocationType] = useState('');
    const [allocationAmount, setAllocationAmount] = useState('0');

    // used to dictate whether or not it is a percentage or flat amount for allocations
    const [delimeter, setDelimiter] = useState('%')
    const [allocation, setAllocation] = useState([{
        id: crypto.randomUUID(),
        deductFrom: allocateFromValue,
        allocateTo: allocateToValue,
        allocationType: allocationType,
        denomination: delimeter,
        allocationAmount: allocationAmount,
        allocationTotal: '0.00'
    }])

    const allocationObj = {
        id: crypto.randomUUID(),
        deductFrom: '',
        allocateTo: '',
        allocationType: '',
        denomination: '%',
        allocationAmount: '0',
        allocationTotal: '0.00'
    }

    const resetAllocationInputs = () => {
        setAllocateFromValue('');
        setAllocateToValue('');
        setAllocateFromInput('');
        setAllocateToInput('');
        setAllocationType('');
        setAllocationAmount('0');
        setDelimiter('%');
    }
    const [allocatees, setAllocatees] = useState(payees?.map(p => p.paytoName))

    const columns = [
        {
            field: 'paytoName',
            headerName: 'Receiving Entities',
            width: 500
        },
        {
            field: 'vendorType',
            headerName: 'Type',
            width: 200,
            alignHeader: 'center'
        },
        {
            field: 'totalDisburseAmount',
            headerName: 'Disburse Amount',
            type: 'number',
            width: 300,
        },
        {
            field: 'delete',
            headerName: 'Remove From List',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {

                return <Button variant="contained" sx={{ backgroundColor: 'steelblue', ml: '.5rem', color: 'white', fontSize: '20px', padding: '.7vh', width: '20%' }} onClick={() => handleDelete(params.row.paytoName)}><RiDeleteBinLine style={{ fontSize: '25px' }} /></Button>
            }
        },
    ];
    const cols = [
        {
            field: 'deductFrom',
            headerName: 'Deduct From',
            width: 450
        },
        {
            field: 'allocateTo',
            headerName: 'Allocate to',
            width: 450,
        },
        {
            field: 'allocationType',
            headerName: 'Allocation Type',
            width: 200,
            alignHeader: 'center'
        },
        {
            field: 'denomination',
            headerName: 'Denomination',
            width: 100,
            alignHeader: 'right',
            align: 'right'
        },
        {
            field: 'allocationAmount',
            headerName: 'Deduction Amount',
            type: 'number',
            width: 200,
            renderCell: (params) => {
                return (
                    <span style={{ color: 'red', opacity: '200%' }}>
                        <strong><em>{(allocation[allocation.length - 1].delimeter === '$' || showPrevious) ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format((params.value * -1) || 0) : params.value}</em></strong>
                    </span>
                )
            }
        },
        {
            field: 'allocationTotal',
            headerName: 'Allocated Total',
            type: 'number',
            width: 200,
            renderCell: (params) => {
                return (
                    <span style={{ color: 'limegreen' }}>
                        <strong>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value || 0)}</strong>
                    </span>
                )
            }
        },
    ];

    const allocationColumns = showPrevious ? cols.filter(col => col.field !== 'denomination' & col.field !== 'delete') : cols;

    const handlePaytoNames = () => {
        handleClose();
        setDisbursees([]);
    };

    const handleAllocations = () => {
        handleClose();
        setAllocations([]);
    };

    const handleDelete = (paytoName) => {
        const updatedDisbursees = disbursees.filter(payee => payee.paytoName !== paytoName);
        setDisbursees(updatedDisbursees);
        setPaytoNames(prevPaytoNames => [...prevPaytoNames, paytoName].sort());
    };

    const handleAllocationTypeChange = (description) => {
        setAllocationType(description === allocationType ? null : description);
        setAllocation(prevAllocation => {
            const currentIndex = allocation.length - 1;
            if (currentIndex !== -1) {
                const updatedAllocations = [...prevAllocation];
                updatedAllocations[currentIndex] = {
                    ...updatedAllocations[currentIndex],
                    allocationType: description,
                };
                return updatedAllocations;
            }
            return prevAllocation;
        });
    };

    const saveAllocations = async () => {
        try {
            const allocateTo = payees.find(p => p.paytoName === allocation[allocation.length - 1].allocateTo);
            const deductFrom = payees.find(p => p.paytoName === allocation[allocation.length - 1].deductFrom);
            const allocationTbl = {
                source: deductFrom.fkPayeeId,
                allocationType: allocationType,
                target: allocateTo.fkPayeeId,
                amount: allocation[allocation.length - 1].allocationTotal,
                disburseDate: deductFrom.disburseDate,
            };

            const { data } = await axios.post(`${apiRoute}/api/allocation`, allocationTbl);

            resetAllocationInputs();
            setAllocation([...allocation, allocationObj]);

            data.disbursementChecksView.forEach(payee => {
                const disburseDate = new Date(payee.disburseDate);
                const formattedDate = (disburseDate.getMonth() + 1).toString().padStart(2, '0') + '/' + disburseDate.getDate().toString().padStart(2, '0') + '/' + disburseDate.getFullYear();

                payee.id = crypto.randomUUID();
                payee.totalDisburseAmount = parseFloat(payee.totalDisburseAmount).toFixed(2);
                payee.grandTotal = (parseFloat(payee.totalDisburseAmount) + payee.bankInterest + payee.allocations + payee.deductions).toFixed(2);
                payee.entityId = entityId;
                payee.bankInterest = parseFloat(payee.bankInterest).toFixed(2);
                payee.disbursedDate = formattedDate;
                payee.allocatedAmount = (payee.allocations + payee.deductions).toFixed(2);
            });

            setPayees(data.disbursementChecksView);

        } catch (e) {
            console.warn('Error from saveAllocations(): ', e);
            // Handle error
        }
    };

    const retrieveExistingAllocations = async () => {
        try {
            const { data } = await axios.get(`${apiRoute}/api/allocation/retrieveAllocations/${payees[0].disburseDate}`)
            data.forEach(d => {
                const source = allPayees?.find(p => p.pkPayeeId === d.source);
                const target = allPayees.find(p => p.pkPayeeId === d.target);
                const bankInterestPayee = 'BANK INTEREST'
                d.deductFrom = d.allocationType === 'BANK INTEREST' ? bankInterestPayee : source?.paytoName ?? "";
                d.allocateTo = target?.paytoName ?? "";
                d.allocationAmount = d.amount;
                d.allocationTotal = d.amount;
            });
            setAllocations(data);


            const newPayees = payees.map((payee) => {
                const matchingAllocation = data.find(d => d.allocateTo === payee.paytoName)
                if (matchingAllocation) {
                    const newTotal = parseFloat(payee.grandTotal) + matchingAllocation.amount;
                    return {
                        ...payee,
                        allocatedAmount: matchingAllocation.amount.toString(),
                        grandTotal: newTotal
                    };
                }
                return payee;
            });
            setPayees(newPayees);
        } catch (e) {
            console.log('error: ', e)
        }
    };


    const handlePrevious = async () => {
        setShowPrevious(!showPrevious)
        await retrieveExistingAllocations();
    };

    const handleRetrieved = async () => {
        setShowPrevious(true);
        await retrieveExistingAllocations();
    };

    useEffect(() => {
        if (delimeter === '$') {
            setAllocation(prevAllocation => {
                const currentIndex = allocation.length - 1;
                if (currentIndex !== -1) {
                    const updatedAllocations = [...prevAllocation];
                    updatedAllocations[currentIndex] = {
                        ...updatedAllocations[currentIndex],
                        allocationTotal: updatedAllocations[currentIndex].allocationAmount,
                    };
                    return updatedAllocations;
                }
                return prevAllocation;
            });
        } else {
            if (delimeter === '%') {
                setAllocation(prevAllocation => {
                    const currentIndex = allocation.length - 1;
                    if (currentIndex !== -1) {
                        const updatedAllocations = [...prevAllocation];
                        const deductFrom = payees.find(p => p.paytoName === updatedAllocations[currentIndex].deductFrom)
                        updatedAllocations[currentIndex] = {
                            ...updatedAllocations[currentIndex],
                            allocationTotal: (updatedAllocations[currentIndex].allocationAmount * deductFrom?.totalDisburseAmount) / 100,
                        };
                        return updatedAllocations;
                    }
                    return prevAllocation;
                })
            }
        }
    }, [delimeter, allocationAmount]);

    useEffect(() => {
        if (from === 'retrieved') {
            handleRetrieved();
        }
    }, [from]);

    return (
        <>
            {which === 'interest' &&
                <Paper elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', textAlign: 'center' }}>
                        <h3>
                            <strong>Bank Interest</strong>
                        </h3>
                    </Paper>
                    <Box sx={{ width: "70%", margin: "1vh auto", gap: "2rem", display: "flex", flexDirection: "column" }}>
                        <Box mt={1.5}>
                            <h5>Select Payee{'(s)'} To Allocate Bank Interest</h5>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Autocomplete
                                    sx={{ ...ts, width: '20vw' }}
                                    id="payees-autocomplete"
                                    label="Payees"
                                    options={paytoNames}
                                    value={interestValue}
                                    onChange={(e, newValue) => setInterestValue(newValue)}
                                    inputValue={interestInput}
                                    onInputChange={(e, newValue) => setInterestInput(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Payees" />}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                                <div style={{ marginLeft: '1rem', display: 'flex', gap: '1rem', alignItems: 'stretch' }}>
                                    <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white', flexGrow: 1, p: '1.5vh' }} onClick={() => {
                                        const selectedPayeeObject = payees.find(payee => payee.paytoName === interestValue);
                                        if (selectedPayeeObject) {

                                            const updatedDisbursees = [...disbursees, selectedPayeeObject];
                                            setDisbursees(updatedDisbursees);
                                            setPaytoNames(prevPaytoNames => prevPaytoNames.filter(payee => payee !== interestValue));

                                            const currentIndex = paytoNames.indexOf(interestValue);
                                            const nextIndex = (currentIndex + 1) % paytoNames.length;
                                            setInterestInput(paytoNames[nextIndex]);
                                            setInterestValue(paytoNames[nextIndex])
                                        }
                                    }}>
                                        Add Payee
                                    </Button>
                                </div>
                            </div>
                        </Box>
                        <Box sx={{ height: '55vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '2rem auto', marginBottom: '0' }}>
                            <DataGrid
                                columns={columns.map((column, index) => ({
                                    ...column,
                                    headerClassName: index === 0 ? 'first-column-header' : '',
                                    cellClassName: index === 0 ? 'first-column-cell' : '',
                                }))}
                                rows={disbursees}
                                loading={!disbursees | applyingInterest}
                                rowHeight={60}
                                localeText={{ noRowsLabel: "No Payees currently selected" }}
                                initialState={{
                                    pagination: { paginationModel: { pageSize: 25 } }
                                }}
                                sx={{
                                    "& .muiDataGrid-root": {
                                        height: "100%",
                                    },
                                    ".muiTablePagination-displayedRows, .muiTablePagination-selectLabel": {
                                        marginTop: "1em",
                                        marginBottom: "1em",
                                    },
                                    '.first-column-header': {
                                        paddingLeft: '5em',
                                    },
                                    '.first-column-cell': {
                                        paddingLeft: '5em',
                                    },
                                }}
                            />
                        </Box >
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: '2vh', mt: '-.5rem' }}>
                            <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white', mr: '1rem' }} onClick={saveBankInterest}>
                                Apply Interest
                            </Button>
                            <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handlePaytoNames}>Close</Button>
                        </Box>
                    </Box>

                </Paper>
            }
            {
                which === 'allocations' &&
                <Paper elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', textAlign: 'center' }}>
                        <h3>
                            <strong>Cost Allocations</strong>
                        </h3>
                    </Paper>
                    {!showPrevious &&
                        <>
                            <Box sx={{ width: "70%", margin: "1vh auto", gap: "2rem", display: "flex", flexDirection: "row", justifyContent: 'space-evenly' }}>
                                <Box mt={1.5} style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                                    <div style={{ flex: 1 }}>
                                        <h5>Select Payee To Deduct From</h5>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Autocomplete
                                                sx={{ ...ts, width: '20vw' }}
                                                id="allocate-from-autocomplete"
                                                label="Allocate From"
                                                options={allocatees}
                                                value={allocateFromValue}
                                                onChange={(e, newValue) => setAllocateFromValue(newValue)}
                                                inputValue={allocateFromInput}
                                                onInputChange={(e, newValue) => setAllocateFromInput(newValue)}
                                                renderInput={(params) => <TextField {...params} label="Payees" />}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }} />
                                            <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white', p: '1.5vh', marginLeft: '1rem' }} onClick={() => {
                                                const selectedPayeeObject = payees.find(payee => payee.paytoName === allocateFromValue);
                                                if (selectedPayeeObject) {
                                                    setAllocation(prevAllocation => {
                                                        const currentIndex = allocation.length - 1;
                                                        if (currentIndex !== -1) {
                                                            const updatedAllocations = [...prevAllocation];
                                                            updatedAllocations[currentIndex] = {
                                                                ...updatedAllocations[currentIndex],
                                                                deductFrom: selectedPayeeObject.paytoName
                                                            };
                                                            return updatedAllocations;
                                                        }
                                                        return prevAllocation;
                                                    });
                                                }
                                            }}
                                                disabled={!allocateFromValue | allocation[allocation.length - 1].deductFrom === allocateFromValue}
                                            >
                                                Select Payee
                                            </Button>
                                        </div>
                                    </div>
                                </Box>
                                <Box mt={1.5} style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end', alignSelf: 'right', flexDirection: 'row', float: "right" }}>
                                    <div style={{ flex: 1 }}>
                                        <h5>Select Payee To Allocate To</h5>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Autocomplete
                                                sx={{ ...ts, width: '20vw' }}
                                                id="allocate-to-autocomplete"
                                                label="Allocate To"
                                                options={allocatees}
                                                value={allocateToValue}
                                                onChange={(e, newValue) => setAllocateToValue(newValue)}
                                                inputValue={allocateToInput}
                                                onInputChange={(e, newValue) => setAllocateToInput(newValue)}
                                                renderInput={(params) => <TextField {...params} label="Payees" />}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }} />
                                            <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white', p: '1.5vh', marginLeft: '1rem' }} onClick={() => {
                                                const selectedPayeeObject = payees.find(payee => payee.paytoName === allocateToValue);
                                                if (selectedPayeeObject) {
                                                    setAllocation(prevAllocation => {
                                                        const currentIndex = allocation.length - 1;
                                                        if (currentIndex !== -1) {
                                                            const updatedAllocations = [...prevAllocation];
                                                            updatedAllocations[currentIndex] = {
                                                                ...updatedAllocations[currentIndex],
                                                                allocateTo: selectedPayeeObject.paytoName
                                                            };
                                                            return updatedAllocations;
                                                        }
                                                        return prevAllocation;
                                                    });
                                                }
                                            }}
                                                disabled={!allocateToValue | allocation[allocation.length - 1].allocateTo === allocateToValue}
                                            >
                                                Select Payee
                                            </Button>
                                        </div>
                                    </div>
                                </Box>
                            </Box>
                            <Divider sx={{ borderColor: 'lightgray', borderWidth: '1px', margin: '2vh 2vw', width: '95vw' }} component="hr" /><Box sx={{ display: 'flex', margin: '2vh auto', justifyContent: 'center', flexDirection: "column" }}>
                                <Box sx={{ display: 'flex', alignSelf: 'center', gap: '3rem' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <FormLabel>Percentage Or Flat Amount</FormLabel>
                                        <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', m: '0 3vw' }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={delimeter === '%'} />} label={'%'}
                                                onClick={() => {
                                                    setAllocationAmount('');
                                                    setAllocation(prevAllocation => {
                                                        const currentIndex = allocation.length - 1;
                                                        if (currentIndex !== -1) {
                                                            const updatedAllocations = [...prevAllocation];
                                                            updatedAllocations[currentIndex] = {
                                                                ...updatedAllocations[currentIndex],
                                                                allocationTotal: '0.00',
                                                                allocationAmount: '0',
                                                            };
                                                            return updatedAllocations;
                                                        }
                                                        return prevAllocation;
                                                    });
                                                }}
                                                onChange={(e) => {
                                                    setDelimiter(e.target.checked ? '%' : '$');
                                                    setAllocation(prevAllocation => {
                                                        const currentIndex = allocation.length - 1;
                                                        if (currentIndex !== -1) {
                                                            const updatedAllocations = [...prevAllocation];
                                                            updatedAllocations[currentIndex] = {
                                                                ...updatedAllocations[currentIndex],
                                                                denomination: e.target.checked ? '%' : '$',
                                                            };
                                                            return updatedAllocations;
                                                        }
                                                        return prevAllocation;
                                                    });
                                                }} />
                                            <FormControlLabel
                                                control={<Checkbox checked={delimeter === '$'} />} label={'$'}
                                                onClick={() => {
                                                    setAllocationAmount('');
                                                    setAllocation(prevAllocation => {
                                                        const currentIndex = allocation.length - 1;
                                                        if (currentIndex !== -1) {
                                                            const updatedAllocations = [...prevAllocation];
                                                            updatedAllocations[currentIndex] = {
                                                                ...updatedAllocations[currentIndex],
                                                                allocationTotal: '0.00',
                                                                allocationAmount: '0.00',
                                                            };
                                                            return updatedAllocations;
                                                        }
                                                        return prevAllocation;
                                                    });
                                                }}
                                                onChange={(e) => {
                                                    setDelimiter(e.target.checked ? '$' : '%');
                                                    setAllocation(prevAllocation => {
                                                        const currentIndex = allocation.length - 1;
                                                        if (currentIndex !== -1) {
                                                            const updatedAllocations = [...prevAllocation];
                                                            updatedAllocations[currentIndex] = {
                                                                ...updatedAllocations[currentIndex],
                                                                denomination: e.target.checked ? '$' : '%',
                                                            };
                                                            return updatedAllocations;
                                                        }
                                                        return prevAllocation;
                                                    });
                                                }} />
                                        </FormGroup>
                                    </Box>
                                    <TextField
                                        variant="outlined"
                                        label="Allocation Amount"
                                        type="text"
                                        sx={{ ...ts, width: '10vw' }}
                                        inputProps={{
                                            style: { textAlign: 'right' },
                                            inputMode: 'decimal',
                                        }}
                                        value={`${allocationAmount}`}
                                        onClick={() => { setAllocationAmount(''); }}
                                        onChange={(e) => {
                                            setAllocationAmount(e.target.value);
                                            setAllocation(prevAllocation => {
                                                const currentIndex = allocation.length - 1;
                                                if (currentIndex !== -1) {
                                                    const updatedAllocations = [...prevAllocation];
                                                    updatedAllocations[currentIndex] = {
                                                        ...updatedAllocations[currentIndex],
                                                        allocationAmount: e.target.value,
                                                    };
                                                    return updatedAllocations;
                                                }
                                                return prevAllocation;
                                            });
                                        }}
                                        onBlur={(e) => {
                                            if (delimeter === '%') {
                                                if (allocationAmount === '') {
                                                    setAllocationAmount('0');
                                                    setAllocation(prevAllocation => {
                                                        const currentIndex = allocation.length - 1;
                                                        if (currentIndex !== -1) {
                                                            const updatedAllocations = [...prevAllocation];
                                                            updatedAllocations[currentIndex] = {
                                                                ...updatedAllocations[currentIndex],
                                                                allocationAmount: '0',
                                                            };
                                                            return updatedAllocations;
                                                        }
                                                        return prevAllocation;
                                                    });
                                                } else {
                                                    setAllocation(prevAllocation => {
                                                        const currentIndex = allocation.length - 1;
                                                        if (currentIndex !== -1) {
                                                            const updatedAllocations = [...prevAllocation];
                                                            updatedAllocations[currentIndex] = {
                                                                ...updatedAllocations[currentIndex],
                                                                allocationAmount: e.target.value,
                                                            };
                                                            return updatedAllocations;
                                                        }
                                                        return prevAllocation;
                                                    });
                                                }
                                            }
                                            if (delimeter === '$') {
                                                if (allocationAmount === '') {
                                                    setAllocationAmount('0.00');
                                                    setAllocation(prevAllocation => {
                                                        const currentIndex = allocation.length - 1;
                                                        if (currentIndex !== -1) {
                                                            const updatedAllocations = [...prevAllocation];
                                                            updatedAllocations[currentIndex] = {
                                                                ...updatedAllocations[currentIndex],
                                                                allocationAmount: '0.00',
                                                            };
                                                            return updatedAllocations;
                                                        }
                                                        return prevAllocation;
                                                    });
                                                }
                                                else {
                                                    setAllocationAmount(parseFloat(allocationAmount).toFixed(2));
                                                    setAllocation(prevAllocation => {
                                                        const currentIndex = allocation.length - 1;
                                                        if (currentIndex !== -1) {
                                                            const updatedAllocations = [...prevAllocation];
                                                            updatedAllocations[currentIndex] = {
                                                                ...updatedAllocations[currentIndex],
                                                                allocationAmount: parseFloat(allocationAmount).toFixed(2),
                                                            };
                                                            return updatedAllocations;
                                                        }
                                                        return prevAllocation;
                                                    });
                                                }
                                            }
                                        }} />
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', }}>
                                        <FormLabel>Allocation Type</FormLabel>
                                        <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                            {allocationTypes
                                                .sort((a, b) => a.description < b.description ? -1 : a.description > b.description ? 1 : 0)
                                                .filter(a => a.description !== 'BANK INTEREST')
                                                .map((a) => (
                                                    <FormControlLabel
                                                        key={a.description}
                                                        control={<Checkbox
                                                            checked={allocationType === a.description}
                                                            onChange={() => handleAllocationTypeChange(a.description)} />}
                                                        label={a.description} />
                                                ))}
                                        </FormGroup>
                                    </Box>
                                    <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white', m: '2vh 0' }} onClick={saveAllocations} disabled={allocationType.length === 0 | allocationAmount < '0.01' | !allocation[allocation.length - 1].allocateTo | !allocation[allocation.length - 1].deductFrom}>
                                        Save Allocation
                                    </Button>
                                </Box>
                                <Box sx={{ height: '45vh', width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '2rem auto' }}>
                                    <DataGrid
                                        columns={allocationColumns.map((column, index) => ({
                                            ...column,
                                            headerClassName: index === 0 ? 'first-column-header' : '',
                                            cellClassName: index === 0 ? 'first-column-cell' : '',
                                        }))}
                                        rows={allocation}
                                        rowHeight={60}
                                        localeText={{ noRowsLabel: "Create New Allocations" }}
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 25 } }
                                        }}
                                        sx={{
                                            "& .muiDataGrid-root": {
                                                height: "100%",
                                            },
                                            //".muiTablePagination-displayedRows, .muiTablePagination-selectLabel": {
                                            //    marginTop: "1em",
                                            //    marginBottom: "1em",
                                            //},
                                            '.first-column-header': {
                                                paddingLeft: '5em',
                                            },
                                            '.first-column-cell': {
                                                paddingLeft: '5em',
                                            },
                                        }} />
                                </Box>
                            </Box>
                        </>
                    }
                    {(showPrevious === true) && <Box sx={{ height: '55vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 3, backgroundColor: 'transparent' }}>
                        <h4 style={{ m: 'auto' }}>Existing Allocations</h4>
                        <DataGrid
                            columns={allocationColumns.map((column, index) => ({
                                ...column,
                                headerClassName: index === 0 ? 'first-column-header' : '',
                                cellClassName: index === 0 ? 'first-column-cell' : '',
                            }))}
                            loading={!allocations.length}
                            rows={allocations}
                            rowHeight={60}
                            localeText={{ noRowsLabel: from === 'retrieved' && allocations.length < 1 ? "No Allocations Created" : "Loading Previous Allocations" }}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 25 } }
                            }}
                            sx={{
                                "& .muiDataGrid-root": {
                                    height: "100%",
                                },
                                ".muiTablePagination-displayedRows, .muiTablePagination-selectLabel": {
                                    marginTop: "1em",
                                    marginBottom: "1em",
                                },
                                '.first-column-header': {
                                    paddingLeft: '5em',
                                },
                                '.first-column-cell': {
                                    paddingLeft: '5em',
                                },
                                width: '90%',
                            }}
                        />
                    </Box>}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: '5vw', mt: '2vh', gap: '1rem' }}>
                        <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white', mb: '2vh', float: 'right' }} onClick={handlePrevious} disabled={from === 'retrieved'}>{showPrevious ? 'Create Allocations' : 'Existing Allocations'}</Button>
                        <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white', mb: '2vh', float: 'right' }} onClick={handleAllocations}>Close</Button>
                    </Box>

                </Paper>

            }
        </>
    )
}

export default InterestAndAllocationsModal;
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { Box, Button, Modal, Paper, Tooltip } from '@mui/material';

import { toast } from 'react-toastify';

import BondsModals from '../../components/modals/views/BondsModals';
import BondsTable from '../../components/tables/views/bondsTab/BondsTable';

import { apiRoute } from '../../App';
import { BatchContext } from '../../context/BatchMgmtContext';
import { CaseContext } from '../../context/CaseContext';
import { SystemContext } from '../../context/SystemContext';
import { DistributionCheckContext } from '../../context/DistributionCheckContext';
import { formatDate } from '../../Utils';

const BondsView = (props) => {
    const { updateCaseBalance, ts, state } = props;

    const { openBatches, setPaymentBatchNumber } = useContext(BatchContext);

    const { bondRows, setBondRows, violationRows, balance, cashBondRows, setCashBondRows } = useContext(CaseContext);

    const { autoBondNum } = useContext(SystemContext);

    const [selectedBond, setSelectedBond] = useState({});
    const [newBatch, setNewBatch] = useState({});

    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [modalWidth, setModalWidth] = useState('');
    const [checkInfo, setCheckInfo] = useState([]);
    const [bondNumber, setBondNumber] = useState('');

    const [selectedCheck, setSelectedCheck] = useState({});
    const [loadingPrint, setLoadingPrint] = useState(false);
    const [loadingCheck, setLoadingCheck] = useState(false);
    const [checkNum, setCheckNum] = useState('');

    const { caseNumber } = useParams();

    const handleOpen = async (type, width) => {
        if (openBatches.length > 0) {
            setModalType(type);
            setModalWidth(width);
            setModal(true);
        } else {
            try {
                setModalType('openBatch');
                setModalWidth('25%');
                setModal(true);
            } catch (err) {
                console.log('error getting newBatch', err);
            }
        }
    };


    const handleClose = () => {
        setModal(false);
        setModalType('');
        setModalWidth('');
    };

    const getCheckInfo = (e, id) => {
        e.preventDefault();
        axios.get(`${apiRoute}/api/ffchecktbls/${id}`)
            .then((res) => {
                setCheckInfo(res.data);
                //handleOpen('checksView', '50%');

                handleOpen('RefundChecksView', '65%')
            })
            .catch((err) => {
                handleOpen('checks', '50%');
                toast.info(`No check was created for this bond yet. Please create a check for this bond using this form.`, {
                    position: "top-right",
                    autoClose: 7000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                console.log(err);
            });
    };

    const voidBondCheck = async (checkId, bonds, checkAmount, parentId) => {
        const bond = bonds.filter(b => b.transactionAmount === checkAmount)[0];
        setLoadingPrint(true);
        try {
            await axios.put(`${apiRoute}/api/ffchecktbls/void/${checkId}`);
            await axios.put(`${apiRoute}/api/bond/voidBondCheck/${bond.pkCashBondsTransactionId}`, { ParentId: bond.fkBondId });
            await retrieveBondRows();
            setLoadingPrint(false);
            handleClose();
            toast.success("Check Voided Successfully", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        } catch (e) {
            setLoadingPrint(false);
            toast.error("Error voiding check, please reach out to support", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        }
    };

    const retrieveBondRows = async () => {
        try {
            //* -------------------- FETCH ALL BONDS ATTACHED TO CASE/CITATION ---------------------
            const { data } = await axios.get(`${apiRoute}/api/bond/case/${caseNumber}`);
            const sourceIds = [];
            data.forEach(d => d.bond.sourceId == null ? sourceIds.push(d) : null);
            const rows = data.map((row) => {
                const newRow = {
                    ...row.bond,
                    incidentDate: row.bond.incidentDate ? new Date(row.bond.incidentDate).toISOString().substring(0, 10) : null,
                    bondDate: row.bond.bondDate ? new Date(row.bond.bondDate).toISOString().substring(0, 10) : null,
                    originalPostedAmount: data && data[0]?.bond?.bondAmount ? data[0].bond.bondAmount : null,
                    remainingBondAmount: row.bond.bondType === 'CASH BOND' ? row.bond.cashBondTransactions.reduce((remaining, transaction) => {
                        if (transaction.isActive && !transaction.isVoid) {
                            return remaining - transaction.transactionAmount;
                        }
                        return remaining
                    }, row.bond.bondAmount) : sourceIds.filter(r => r.bond.id === row.bond?.sourceId)[0]?.bond.cashBondTransactions?.reduce((remaining, transaction) => {
                        if (transaction.isActive && !transaction.isVoid) {
                            return remaining - transaction.transactionAmount;
                        }
                        return remaining
                    }, sourceIds.filter(r => r.bond.id === row.bond.sourceId)[0]?.bond.bondAmount),
                    voidDate: sourceIds?.filter(r => r.bond.id === row.bond.sourceId)[0]?.bond.cashBondTransactions?.filter(c => c.isVoid === true && c.transactionType !== 'APPLY' && -c.transactionAmount === row.bond.bondAmount)[0]?.voidDate ?? null
                }
                return newRow;
            })
            setBondRows(rows)
        } catch (err) {
        }
    };

    const toolTipText = violationRows.length < 1 ? "Can't add a bond with no violations on the case" : balance === 0.00 ? 'No violations with outstanding balances' : '';
    const disableToolTip = balance === 0.00 ? false : violationRows.length > 0

    return (
        <Box sx={{ height: '100%', width: '99%', m: '1vh auto' }}>
            <Paper elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display: "flex" }}>
                    <h6 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Bonds</h6>
                </Paper>
                <Box sx={{ mt: '1vh' }}>
                    <Paper
                        sx={{
                            width: '99%',
                            margin: '0 auto',
                            minHeight: '21.8vh',
                            maxHeight: "46.5vh",
                        }}
                        elevation={10}
                    >
                        <BondsTable
                            handleOpen={handleOpen}
                            setSelectedBond={setSelectedBond}
                            bondRows={bondRows}
                            getCheckInfo={getCheckInfo}
                            setNewBatch={setNewBatch}
                            state={state}
                        />
                    </Paper>
                    <Box sx={{ width: '99%', m: '1vh auto', pb: '1vh' }}>
                            <span style={{ width: '100%' }}>
                                <Button
                                    onClick={async () => {
                                        if (autoBondNum) {
                                            axios.get(`${apiRoute}/api/bond/newestbond`)
                                                .then(res => {
                                                    setBondNumber(res.data);
                                                    handleOpen('selectViolations', '45%')
                                                })
                                        } else {
                                            handleOpen('selectViolations', '45%')
                                        }
                                    }}
                                    variant='contained'
                                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                    disabled={ !state.isActive | state.isDeleted}
                                >
                                    + Add Bond
                                </Button>
                            </span>
                        <Modal open={modal} sx={{ width: modalWidth, margin: "2vh auto" }}>
                            <>
                                <BondsModals
                                    handleClose={handleClose}
                                    modalType={modalType}
                                    ts={ts}
                                    selectedBond={selectedBond}
                                    setSelectedBond={setSelectedBond}
                                    bondRows={bondRows}
                                    setBondRows={setBondRows}
                                    cashBondRows={cashBondRows}
                                    setCashBondRows={setCashBondRows}
                                    updateCaseBalance={updateCaseBalance}
                                    handleOpen={handleOpen}
                                    checkInfo={checkInfo}
                                    bondNumber={bondNumber}
                                    newBatch={newBatch}
                                    state={state}
                                    violationRows={violationRows}
                                    voidBondCheck={voidBondCheck}
                                    setCheckNum={setCheckNum}
                                    loadingPrint={loadingPrint}
                                />
                            </>
                        </Modal>
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}

export default BondsView;
import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, Routes, Route } from "react-router-dom";

import CreateCaseView from "./pages/CreateCaseView";
import BatchManagement from "./pages/BatchManagement";
import Layout from "./components/Layout";
import Login from "./components/users/Login";
import Search from "./components/search/Search";
import HomeViewDisplay from "./pages/index";
import ResetPassword from "./components/users/ResetPassword";
import ForgotPassword from "./components/users/ForgotPassword";
import UtilitiesView from "./components/utilities/views/UtilitiesView";

import UserContext from "./context/UserContext";
import { BatchContext } from "./context/BatchMgmtContext";
import DistributionCheck from "./pages/DistributionChecks";
import { GeneralLedgerContext } from "./context/GeneralLedgerContext";

import CloseOutMonth from './pages/CloseOut'
import TextOptIn from "./pages/TextOptIn";
import { SystemContext } from "./context/SystemContext";

const AppRoutes = () => {
    const navigate = useNavigate();

    const hasToken = localStorage.getItem('token') != null;

    const resetToken = localStorage.getItem('passwordToken') != null;

    const { userId, adminCheck, getBanks } = useContext(UserContext);

    const { getBatches, batchRows, setOpenBatches } = useContext(BatchContext);

    const { getCodes, getYears } = useContext(SystemContext)

    const { getGlAccounts } = useContext(GeneralLedgerContext);

    const [utilInfo, setUtilInfo] = useState([]);

    useEffect(() => {
        if (hasToken) { getYears(); getCodes(); getBatches(); getBanks(); getGlAccounts(); }
    }, [hasToken]);

    useEffect(() => {
        if (userId && batchRows?.length >= 1) {
            setOpenBatches(batchRows?.filter((batch) => batch?.fkUserId === parseInt(userId) && !['Settled', 'Closed', 'Deposited'].includes(batch?.batchStatus)))
        }
    }, [batchRows, userId]);

    const routes = [
        {
            path: '/smsoptin',
            element: <TextOptIn />,
            layout: false
        },
        {
            path: "/new",
            element: hasToken ? <CreateCaseView navigate={navigate} /> : <Navigate to="/login" />,
            layout: true,
        },
        {
            path: "/",
            element: hasToken ? <Search /> : <Navigate to="/login" />,
            layout: true,
        },
        {
            path: "/view/:caseNumber",
            element: hasToken ? <HomeViewDisplay /> : <Navigate to="/login" />,
            layout: true,
        },
        {
            path: "/batches",
            element: hasToken ? <BatchManagement /> : <Navigate to="/login" />,
            layout: true,
        },
        {
            path: "/distribution-checks",
            element: hasToken ? <DistributionCheck /> : <Navigate to="/login" />,
            layout: true,
        },
        {
            path: "/close-out",
            element: hasToken ? <CloseOutMonth /> : <Navigate to="/login" />,
            layout: true,
        },
        {
            path: "/login",
            element: !hasToken ? <Login /> : <Navigate to="/" />,
            layout: false,
        },
        {
            path: "/forgotpassword",
            element: !hasToken ? <ForgotPassword /> : <Navigate to="/" />,
            layout: false,
        },
        {
            path: "/resetpassword/:token",
            element: resetToken ? <ResetPassword /> : <Navigate to="/" />,
            layout: false,
        },
        {
            path: "*",
            element: !hasToken ? <Navigate to="/" /> : <Navigate to="/login" />,
        },
        {
            path: `/util/:utilRoute`,
            element: hasToken && adminCheck ? <UtilitiesView utilInfo={utilInfo} /> : <Navigate to="/login" />,
            layout: true,
        }
    ];

    return (
        <Routes>
            {routes.map((route, index) => {
                const { path, element, layout } = route;
                const RouteComponent = layout ? (
                    <Route key={index} path={path} element={<Layout setUtilInfo={setUtilInfo}>{element}</Layout>} />
                ) : (
                    <Route key={index} path={path} element={element} />
                );
                return RouteComponent;
            })}
        </Routes>
    );
};

export default AppRoutes;
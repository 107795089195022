import React, { useContext } from 'react';

import { Box, Button, LinearProgress, Paper } from '@mui/material';
import {
    DataGrid,
    GridToolbar,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid';

import UserContext from '../../../../context/UserContext';

const AddChargeViewTable = (props) => {
    const {
        statuteRows,
        getRow,
        loading,
        addViolation,
        modalType,
        amendViolation,
        caseLevelFees,
    } = props;

    const { adminCheck } = useContext(UserContext);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={
                        {
                            tooltip: {
                                title: 'Change density'
                            }
                        }
                    }
                />
                <GridToolbarExport
                    slotProps={
                        {
                            tooltip: {
                                title: 'Export data'
                            },
                            button: {
                                variant: 'outlined'
                            },
                        }
                    }
                />
                <Button
                    onClick={addViolation}> Add </Button>
                <Box
                    sx={
                        {
                            flexGrow: 1
                        }
                    }
                />
                <GridToolbarQuickFilter />
            </GridToolbarContainer >
        );
    }

    const columns = [
        {
            field: 'chargeType',
            headerName: 'Charge Type',
            width: 450
        },
        {
            field: 'rsNumber',
            headerName: 'Statute',
            width: 150
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 550
        },
        {
            field: 'defaultFine',
            headerName: 'Default Amount',
            width: 140,
            headerAlign: 'right',
            align: 'right',
            renderCell: (params) => {
                return (
                    <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</span>
                )
            }
        },
    ];

    const ouachitaCols = [
        {
            field: 'chargeType',
            headerName: 'Code',
            width: 150
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 750
        }
    ];

    const caseLevelColumns = [
        {
            field: 'chargeType',
            headerName: 'Fee Type',
            width: 400
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 400
        },
    ];

    const rows = caseLevelFees ? statuteRows.filter((sr) => sr.caseLevelFee === true).sort((a, b) => { return a.chargeType > b.chargeType ? 1 : a.chargeType < b.chargeType ? -1 : 0 }).map(sr => sr) : statuteRows.sort((a, b) => { return a.chargeType > b.chargeType ? 1 : a.chargeType < b.chargeType ? -1 : 0 }).map(sr => sr);

    return (
        <DataGrid
            rows={rows}
            columns={caseLevelFees ? caseLevelColumns :  columns}
            disableMultipleRowSelection
            onRowClick={(params) => {
                getRow(params)
            }
            }
            onCellDoubleClick={() => {
                if (modalType === 'amendViolation') {
                    amendViolation()
                } else {
                    addViolation()
                }
            }}
            loading={loading}
            localeText={{ noRowsLabel: 'No violations avaliable for this case' }}
            density="compact"
            pageSizeOptions={[25, 50, 100]}
            initialState={{
                pagination: { paginationModel: { pageSize: 100 } },
            }}
            slots={{
                toolbar: (modalType === 'selectViolations' || modalType === 'editViolations') ? CustomToolbar : GridToolbar,
                loadingOverlay: LinearProgress
            }}
            disableColumnFilter
            disableColumnSelector
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: !adminCheck }
                },
            }}
            sx={{
                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                    "marginTop": "2em",
                    "marginBottom": "2em"
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                    outline: "none !important",
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                    display: 'none',  // Hides the scrollbar in WebKit browsers (like Chrome, Safari)
                },
            }}
            />
    )
}

export default AddChargeViewTable;
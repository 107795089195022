import React, { useState } from "react";
import {
    Box,
    Button,
    Card,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';

const OtpVerificationForm = ({
    otp,
    setOtp,
    confirmOtp,
    tryAgain,
    disableTryAgainButton,
    handleCancel
}) => {
  const [error, setError] = useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (otp.length === 6) {
      confirmOtp();
    } else {
      setError("Please enter valid 6-digit code");
    }
    };

    const handleChange = (otp) => setOtp(otp);

    return (
        <Card sx={{ padding: 4, textAlign: "center" }}>
            <Typography variant="h5" mb={2}>
                Enter Verification Code
            </Typography>
            <Typography variant="body1" mb={4}>
                We have sent a verification code to your Email & Phone Number
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box className='input-otp-class' sx={{ display: 'flex', justifyContent: 'center' }} mb={4.5}>
                    <OtpInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={6}
                        isOnlyNumberAllowed={true}
                        isInputNum={true}
                        renderSeparator={<span style={{ marginLeft: '1vh', marginRight: '1vh' }}>-</span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={{
                            width: '2em'
                        }}
                        inputType={"tel"}
                        shouldAutoFocus={true}
                    />
                </Box>
                {error && (
                    <Typography color="error" mb={2}>
                        {error}
                    </Typography>
                )}
                <Button type="submit" variant="contained" color="primary" fullWidth>
                    Verify
                </Button>
            </form>

            <Box mt={2}>
                {disableTryAgainButton ? (
                    <Tooltip title={"Please wait for 1 minute before trying again"}>
                        <Link
                            disabled={true}
                            style={{ color: "inherit", textDecoration: "inherit" }}
                        >
                            Try again
                        </Link>
                    </Tooltip>
                ) : (
                    <Link
                        className="tryButtonHover"
                        onClick={tryAgain}
                        style={{ color: "inherit", textDecoration: "none" }}
                    >
                        Try again
                    </Link>
                )}{" "}
                or{" "}
                <Link
                    className="tryButtonHover"
                    onClick={handleCancel}
                    style={{ color: "inherit", textDecoration: "none" }}
                >
                    Cancel
                </Link>
            </Box>
        </Card>
    );
};

export default OtpVerificationForm;

import React, { useEffect, useReducer, useState } from 'react';
import axios from 'axios';

import { Box, Button, TextField, Paper, MenuItem, LinearProgress } from '@mui/material';

import { toast } from 'react-toastify';

import { apiRoute } from '../../../../../App';
import { AddValueAndLabel } from '../../../../../Utils';

const DisbursementRuleForm = (props) => {
    const { handleClose, data, modalType, loading, setLoading, ts, rows, setRows, getRows } = props;

    const [payees, setPayees] = useState([]);

    const getPayees = () => {
        axios
            .get(`${apiRoute}/api/PayeeTbls/`)
            .then((res) => {
                const payeeArr = res.data.map((payee) => ({
                        ...payee,
                        id: payee.pkPayeeId,
                    }))
                setPayees(AddValueAndLabel(payeeArr, 'id', 'paytoName'))
            })
            .catch((err) => {
                console.log(err, 'err getting Payees - getPayees');
            });
    };

    useEffect(() => {
        getPayees();
    }, [])

    const initialState = {
        description: modalType === 'add' ? null : data.description,
        chargeType: modalType === 'add' ? null : data.chargeType,
        class: modalType === 'add' ? null : data.class,
        courtAppearRqd: modalType === 'add' ? null : data.courtAppearRqd,
        defaultFine: modalType === 'add' ? null : data.defaultFine,
        allowPartialPayment: modalType === 'add' ? null : data.allowPartialPayment,
        courtNum: modalType === 'add' ? null : data.courtNum,
        calcOrder: modalType === 'add' ? null : data.calcOrder,
        feeGrouper: modalType === 'add' ? null : data.feeGrouper,
        disburseOrder: modalType === 'add' ? null : data.disburseOrder,
        allocation: modalType === 'add' ? null : data.allocation,
        allocateBy: modalType === 'add' ? null : data.allocateBy,
        fkPayeeId: modalType === 'add' ? null : data.fkPayeeId,
        begDate: modalType === 'add' ? new Date().toISOString().slice(0, 10) : new Date(data?.begDate).toISOString().slice(0, 10),
        endDate: modalType === 'add' ? new Date().toISOString().slice(0, 10) : new Date(data?.endDate).toISOString().slice(0, 10),
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const fieldValidations = {
        description: {
            errorRequired: 'Description is required',
            validation: (value) => !value
        },
        fkPayeeId: {
            errorRequired: 'Payee is required',
            validation: (value) => !value
        },
        courtNum: {
            errorRequired: 'Court number is required',
            validation: (value) => !value
        },
        calcOrder: {
            errorRequired: 'Calc Order is required is required',
            validation: (value) => !value
        },
        begDate: {
            errorRequired: 'Beginning date is required',
            validation: (value) => !value
        },
        endDate: {
            errorRequired: 'End date is required',
            validation: (value) => !value
        },
        feeGrouper: {
            errorRequired: 'Fee grouper is required',
            validation: (value) => !value
        },
        disburseOrder: {
            errorRequired: 'disburse Order is required',
            validation: (value) => !value
        },
        allocation: {
            errorRequired: 'Allocation is required',
            validation: (value) => !value
        },
        allocateBy: {
            errorRequired: 'Allocate by is required',
            validation: (value) => !value
        }
    }

    const handleBlur = (field, value) => {
        const fieldInfo = fieldValidations[field];
        let error = null;

        if (fieldInfo) {
            if (!value) {
                error = fieldInfo.errorRequired
            }
        }
        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (modalType === 'edit') {
            const disburseRule = {
                ...data,
                Description: state.description,
                ChargeType: state.chargeType,
                Class: state.class,
                CourtAppearRqd: state.courtAppearRqd,
                DefaultFine: state.defaultFine,
                AllowPartialPayment: state.allowPartialPayment,
                CourtNum: state.courtNum,
                CalcOrder: state.calcOrder,
                FeeGrouper: state.feeGrouper,
                DisburseOrder: state.disburseOrder,
                Allocation: state.allocation,
                AllocateBy: state.allocateBy,
                FkPayeeId: state.fkPayeeId,
                BegDate: state.begDate,
                EndDate: state.endDate,
            };

            axios.put(`${apiRoute}/api/DisburseRuleTbls/${data.id}`, disburseRule)
                .then((res) => {
                    const updatedRows = rows.map(row => {
                        if (row.id === data.id) {
                            return { ...row, ...res.data };
                        }
                        return row;
                    });
                    setRows(updatedRows);
                    toast.success('Disburse Rule updated successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error(`Error ${err?.response?.status} while updating disburse rule!`, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    console.log(err, 'Error updating disbRule');
                })
                .finally(() => { setLoading(false); getRows() })
        } else {
            const disburseRule = {
                Description: state.description,
                ChargeType: state.chargeType,
                Class: state.class,
                CourtAppearRqd: state.courtAppearRqd,
                DefaultFine: state.defaultFine,
                AllowPartialPayment: state.allowPartialPayment,
                CourtNum: state.courtNum,
                CalcOrder: state.calcOrder,
                FeeGrouper: state.feeGrouper,
                DisburseOrder: state.disburseOrder,
                Allocation: state.allocation,
                AllocateBy: state.allocateBy,
                FkPayeeId: state.fkPayeeId,
                BegDate: state.begDate,
                EndDate: state.endDate,
            };

            axios.post(`${apiRoute}/api/DisburseRuleTbls/`, disburseRule)
                .then((res) => {
                    setRows([...rows, {...res.data, id: res.data.pkDisburseRuleId}])
                    toast.success('Disburse Rule created successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error(`Error ${err?.response?.status} while creating disburse rule!`, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    console.log(err, 'Error creating disbRule');
                })
                .finally(() => { setLoading(false); getRows() })
        }
    };

    return (
        <>
            <Paper elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                    <h1>
                        <strong>Add Disbursement Rule</strong>
                    </h1>
                </Paper>
                <form onSubmit={handleSubmit}>
                    <Box p={1}>
                        <Box width={1} flexWrap="wrap" display="flex">
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="description"
                                    label="Description"
                                    name="description"
                                    value={state.description}
                                    onChange={(e) => {
                                        handleFieldChange('description', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('description', e.target.value) }}
                                    helperText={state?.errors?.description}
                                    error={!!state?.errors?.description}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    required
                                    sx={ts}
                                    id="fkPayeeId"
                                    label="Payee"
                                    name="fkPayeeId"
                                    value={state.fkPayeeId}
                                    onChange={(e) => handleFieldChange('fkPayeeId', e.target.value)}
                                    onBlur={(e) => { handleBlur('fkPayeeId', e.target.value) }}
                                    helperText={state?.errors?.fkPayeeId}
                                    error={!!state?.errors?.fkPayeeId}
                                    select
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    SelectProps={{
                                        MenuProps: {
                                            PaperProps: {
                                                style: {
                                                    maxHeight: '30em',
                                                },
                                            },
                                        },
                                    }}
                                >
                                    {payees?.map(({ value, label }) => (
                                        <MenuItem key={value} value={value} style={{ height: '40px' }}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="courtNum"
                                    label="Court Num"
                                    name="courtNum"
                                    value={state.courtNum}
                                    onChange={(e) => {
                                        handleFieldChange('courtNum', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('courtNum', e.target.value) }}
                                    helperText={state?.errors?.courtNum}
                                    error={!!state?.errors?.courtNum}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>

                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="calcOrder"
                                    label="Calc Order"
                                    name="calcOrder"
                                    value={state.calcOrder}
                                    onChange={(e) => {
                                        handleFieldChange('calcOrder', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('calcOrder', e.target.value) }}
                                    helperText={state?.errors?.calcOrder}
                                    error={!!state?.errors?.calcOrder}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>

                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    required
                                    fullWidth
                                    sx={ts}
                                    type="date"
                                    id="begDate"
                                    label="Beg Date"
                                    value={state.begDate}
                                    onChange={(e) => {
                                        handleFieldChange('begDate', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('begDate', e.target.value) }}
                                    helperText={state?.errors?.begDate}
                                    error={!!state?.errors?.begDate}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    required
                                    fullWidth
                                    sx={ts}
                                    type="date"
                                    id="endDate"
                                    label="End Date"
                                    value={state.endDate}
                                    onChange={(e) => {
                                        handleFieldChange('endDate', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('endDate', e.target.value) }}
                                    helperText={state?.errors?.endDate}
                                    error={!!state?.errors?.endDate}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>

                            <Box width={[1, 1, 1 / 4]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="feeGrouper"
                                    label="Fee Grouper"
                                    name="feeGrouper"
                                    value={state.feeGrouper}
                                    onChange={(e) => {
                                        handleFieldChange('feeGrouper', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('feeGrouper', e.target.value) }}
                                    helperText={state?.errors?.feeGrouper}
                                    error={!!state?.errors?.feeGrouper}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>

                            <Box width={[1, 1, 1 / 4]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="disburseOrder"
                                    label="Disburse Order"
                                    name="disburseOrder"
                                    value={state.disburseOrder}
                                    onChange={(e) => {
                                        handleFieldChange('disburseOrder', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('disburseOrder', e.target.value) }}
                                    helperText={state?.errors?.disburseOrder}
                                    error={!!state?.errors?.disburseOrder}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>

                            <Box width={[1, 1, 1 / 4]} mt={3} pr={3}>
                                <TextField
                                    sx={ts}
                                    id="allocateBy"
                                    label="Allocate By"
                                    name="allocateBy"
                                    value={state.allocateBy}
                                    onChange={(e) => handleFieldChange('allocateBy', e.target.value)}
                                    select
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                >
                                    <MenuItem value={null}>N/A</MenuItem>
                                    <MenuItem value={'%'}>Percentage</MenuItem>
                                </TextField>
                            </Box>

                            <Box width={[1, 1, 1 / 4]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="allocation"
                                    label="Allocation"
                                    name="allocation"
                                    value={state.allocation}
                                    onChange={(e) => {
                                        handleFieldChange('allocation', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('allocation', e.target.value) }}
                                    helperText={state?.errors?.allocation}
                                    error={!!state?.errors?.allocation}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="right" gap={2} p={1}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Paper>
            {
                loading && (
                    <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
                )
            }
        </>
    );
};

export default DisbursementRuleForm;

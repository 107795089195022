import React, { useContext, useState } from 'react';

import { Autocomplete, Box, Checkbox, FormControlLabel, Grid, MenuItem, Paper, TextField } from '@mui/material'

import { SystemContext } from '../../context/SystemContext';
import UserContext from '../../context/UserContext';

const CitationView = (props) => {
    const {
        handleFieldChange,
        handleBlur,
        state,
        ts
    } = props;

    const { streetTypes } = useContext(SystemContext);
    const { officers } = useContext(UserContext);

    const badgeNumbers = officers.map(o => o.badgeNumber);

    //will revisit later
    //const officerNames = officers.map(o => (o.officerRank !== '' || o.officerRank !== undefined) ? `${o.officerRank} ${o.officerName}` : o.officerName);

    // Badge Number Autocomplete state variables
    const [badgeNumberValue, setBadgeNumberValue] = useState(state.badgeNumber);
    const [badgeNumberInputValue, setBadgeNumberInputValue] = useState(badgeNumberValue)

    // Badge Name Autocomplete state variables
    const [badgeNameValue, setBadgeNameValue] = useState(state.badgeName);
    const [badgeNameInputValue, setBadgeNameInputValue] = useState(badgeNameValue);

    return (
        <Box sx={{ height: "100%" }}>
            <Box sx={{ width: "99%", margin: "1vh auto" }}>
                <Box sx={{ display: "flex", gap: "1rem" }}>
                    <Paper sx={{ width: "55%" }} elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display: "flex" }}>
                            <h6 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Officer</h6>
                        </Paper>
                        <Box sx={{ width: "95%", margin: "1vh auto" }}>
                            <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", gap: "1rem" }}>
                                <Grid item sm={3}>
                                    <Autocomplete
                                        fullWidth
                                        sx={ts}
                                        id="badgeNumber"
                                        label="Badge #"
                                        name="badgeNumber"
                                        inputProps={{ type: 'numeric', readOnly: !state.isActive | state.isDeleted }}
                                        InputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                        options={badgeNumbers}
                                        value={badgeNumberValue}
                                        disabled={!state.isActive | state.isDeleted}
                                        inputValue={badgeNumberInputValue || ""}
                                        onInputChange={(e, badgeNumber) => {
                                            setBadgeNumberInputValue(badgeNumber);
                                            // If the input is manual, update the value directly
                                            if (!badgeNumbers.includes(badgeNumber)) {
                                                setBadgeNumberValue(badgeNumber);
                                            }

                                        }}
                                        onChange={(e, newValue) => {
                                            if (newValue) {
                                                handleFieldChange('badgeNumber', newValue?.replace(/[^a-zA-Z0-9\s]/g, ''));
                                                setBadgeNumberValue(newValue?.replace(/[^a-zA-Z0-9\s]/g, ''));
                                            } else {
                                                handleFieldChange('badgeNumber', '');
                                                setBadgeNumberValue('');
                                            }

                                        }}
                                        onBlur={(e) => {
                                            handleFieldChange('badgeNumber', e?.target?.value?.replace(/[^a-zA-Z0-9\s]/g, ''));
                                            handleFieldChange('badgeName', officers.find(o => o.badgeNumber === e?.target?.value?.replace(/[^a-zA-Z0-9\s]/g, ''))?.officerName);
                                            setBadgeNameInputValue(officers.find(o => o.badgeNumber === e?.target?.value?.replace(/[^a-zA-Z0-9\s]/g, ''))?.officerName);
                                            setBadgeNameValue(officers.find(o => o.badgeNumber === e?.target?.value?.replace(/[^a-zA-Z0-9\s]/g, ''))?.officerName);


                                            // will come back to these after stabilizing basic functionality to get rank...may need to change how we save to the citation table
                                            //setBadgeNameInputValue(`${officers.find(o => o.badgeNumber === e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')).officerRank} ${officers.find(o => o.badgeNumber === e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')).officerName}`);
                                            //setBadgeNameValue(`${officers.find(o => o.badgeNumber === e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')).officerRank} ${officers.find(o => o.badgeNumber === e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')).officerName}`);
                                        }}
                                        helperText={state?.errors?.badgeNumber}
                                        error={!!state?.errors?.badgeNumber}
                                        variant='outlined'
                                        InputLabelProps={{ shrink: true }}
                                        disableClearable={!state.isActive | state.isDeleted}
                                        freeSolo={!state.isActive | state.isDeleted}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    readOnly: !state.isActive | state.isDeleted | state.isDeleted
                                                }}
                                                disabled={!state.isActive | state.isDeleted}
                                                label="Badge #" />)}
                                    />
                                </Grid>
                                <Grid item sm={5}>
                                    <Autocomplete
                                        fullWidth
                                        sx={ts}
                                        id="badgeName"
                                        label="Name"
                                        name="badgeName"
                                        options={[]}
                                        value={badgeNameValue}
                                        inputProps={{ type: 'numeric', readOnly: !state.isActive | state.isDeleted }}
                                        InputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                        inputValue={badgeNameInputValue || ""}
                                        disabled={!state.isActive | state.isDeleted}
                                        onInputChange={(e, badgeName) => {
                                            setBadgeNameInputValue(badgeName);
                                            // If the input is manual, update the value directly
                                            if (!officers.some(o => o.badgeNumber === badgeNumberInputValue && o.officerName === badgeName)) {
                                                setBadgeNameValue(badgeName);
                                            }
                                        }}
                                        onChange={(e, newValue) => {
                                            if (newValue) {
                                                handleFieldChange('badgeName', newValue);
                                                setBadgeNameValue(newValue?.replace(/[^a-zA-Z0-9\s]/g, ''));
                                            }
                                            // handle the case when newValue is null (clearing the value)
                                            else {
                                                handleFieldChange('badgeName', ''); // clear the badgeName
                                                setBadgeNameValue(''); // clear the badgeNameValue
                                            }
                                        }}
                                        onBlur={(e) => {
                                            handleFieldChange('badgeName', e?.target?.value?.replace(/[^a-zA-Z0-9\s]/g, ''))
                                            if (e.target.value === '') {
                                                handleFieldChange('badgeNumber', '');
                                                setBadgeNumberValue("");
                                                setBadgeNumberInputValue("");
                                            }
                                        }}
                                        helperText={state?.errors?.badgeName}
                                        error={!!state?.errors?.badgeName}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        disableClearable={!state.isActive | state.isDeleted}
                                        freeSolo={!state.isActive | state.isDeleted}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    readOnly: !state.isActive | state.isDeleted
                                                }}
                                                disabled={!state.isActive | state.isDeleted}
                                                label="Officer Name" />)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>

                    <Paper sx={{ width: "35%" }} elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display: "flex" }}>
                            <h6 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Arrest</h6>
                        </Paper>
                        <Box sx={{ width: "95%", margin: "1vh auto" }}>
                            <Box sx={{ width: "100%" }}>
                                <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", gap: "1rem" }}>
                                    <Grid item sm={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={state?.arrested === 'Y'}
                                                    onChange={(e) => {
                                                        handleFieldChange('arrested', e.target.checked ? 'Y' : null)
                                                    }}
                                                />
                                            }
                                            disabled={!state.isActive | state.isDeleted}
                                            label="Arrested"
                                        />
                                    </Grid>
                                    <Grid item sm={5}>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            type="date"
                                            id="arrestDatetime"
                                            label="Date/Time"
                                            name="arrestDatetime"
                                            value={state?.arrestDatetime}
                                            onChange={(e) => handleFieldChange('arrestDatetime', e.target.value)}
                                            onBlur={(e) => handleBlur('arrestDatetime', e.target.value)}
                                            helperText={state?.errors?.arrestDatetime}
                                            error={!!state?.errors?.arrestDatetime}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            disabled={!state.isActive | state.isDeleted}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
                <Paper sx={{ width: "100%" }} elevation={10}>
                    <Box sx={{ width: "100%", mt: "1vh", pb: '1vh' }}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display: "flex" }}>
                            <h6 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Location</h6>
                        </Paper>
                        <Box sx={{ width: "95%", margin: "1vh auto" }}>
                            <Box sx={{ width: "90%" }}>
                                <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Grid item sm={1}>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            id="areaBlockOf"
                                            label="Block"
                                            name="areaBlockOf"
                                            value={state?.areaBlockOf}
                                            onChange={(e) => handleFieldChange('areaBlockOf', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                            onBlur={(e) => handleBlur('areaBlockOf', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                            helperText={state?.errors?.areaBlockOf}
                                            error={!!state?.errors?.areaBlockOf}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            disabled={!state.isActive | state.isDeleted}
                                        />
                                    </Grid>
                                    <Grid item sm={1}>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            id="areaStreetNum"
                                            label="Street #"
                                            name="areaStreetNum"
                                            value={state?.areaStreetNum}
                                            onChange={(e) => handleFieldChange('areaStreetNum', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                            onBlur={(e) => handleBlur('areaStreetNum', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                            helperText={state?.errors?.areaStreetNum}
                                            error={!!state?.errors?.areaStreetNum}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            disabled={!state.isActive | state.isDeleted}
                                        />
                                    </Grid>
                                    <Grid item sm={1}>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            id="areaStreetDir"
                                            label="Dir"
                                            name="areaStreetDir"
                                            value={state?.areaStreetDir}
                                            onChange={(e) => handleFieldChange('areaStreetDir', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                            onBlur={(e) => handleBlur('areaStreetDir', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                            helperText={state?.errors?.areaStreetDir}
                                            error={!!state?.errors?.areaStreetDir}
                                            select
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            disabled={!state.isActive | state.isDeleted}
                                        >
                                            <MenuItem value=""> </MenuItem>
                                            <MenuItem value="N">N</MenuItem>
                                            <MenuItem value="S">S</MenuItem>
                                            <MenuItem value="E">E</MenuItem>
                                            <MenuItem value="W">W</MenuItem>
                                            <MenuItem value="NE">NE</MenuItem>
                                            <MenuItem value="NW">NW</MenuItem>
                                            <MenuItem value="SE">SE</MenuItem>
                                            <MenuItem value="SW">SW</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            id="areaStreetName"
                                            label="Street Name"
                                            name="areaStreetName"
                                            value={state?.areaStreetName}
                                            onChange={(e) => handleFieldChange('areaStreetName', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                            onBlur={(e) => handleBlur('areaStreetName', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                            helperText={state?.errors?.areaStreetName}
                                            error={!!state?.errors?.areaStreetName}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            disabled={!state.isActive | state.isDeleted}
                                        />
                                    </Grid>
                                    <Grid item sm={1}>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            select
                                            id="areaStreetType"
                                            label="Type"
                                            name="areaStreetType"
                                            value={state?.areaStreetType}
                                            onChange={(e) => handleFieldChange('areaStreetType', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                            onBlur={(e) => handleBlur('areaStreetType', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                            helperText={state?.errors?.areaStreetType}
                                            error={!!state?.errors?.areaStreetType}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: '20em',
                                                        },
                                                    },
                                                },
                                            }}
                                            disabled={!state.isActive | state.isDeleted}
                                        >
                                            <MenuItem value={''}>N/A</MenuItem>
                                            {
                                                streetTypes?.map(({ codeValue, codeTitle }) => {
                                                    return <MenuItem key={codeTitle} value={codeValue}>{codeTitle}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            id="areaCity"
                                            label="City"
                                            name="areaCity"
                                            value={state?.areaCity}
                                            onChange={(e) => handleFieldChange('areaCity', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                            onBlur={(e) => handleBlur('areaCity', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                            helperText={state?.errors?.areaCity}
                                            error={!!state?.errors?.areaCity}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            disabled={!state.isActive | state.isDeleted}
                                        />
                                    </Grid>
                                </Grid>
                                <Box sx={{ width: "100%", display: "flex", gap: "1rem" }}>
                                    <Paper sx={{ width: "50%", marginTop: "1vh" }} elevation={10}>
                                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display: "flex" }}>
                                            <h6 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Cross Street</h6>
                                        </Paper>
                                        <Box sx={{ width: "95%", margin: "1vh auto" }}>
                                            <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", gap: '1rem' }}>
                                                <Grid item sm={2}>
                                                    <TextField
                                                        fullWidth
                                                        sx={ts}
                                                        id="areaCrossDir"
                                                        label="Dir"
                                                        name="areaCrossDir"
                                                        value={state?.areaCrossDir}
                                                        onChange={(e) => handleFieldChange('areaCrossDir', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                                        onBlur={(e) => handleBlur('areaCrossDir', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                                        helperText={state?.errors?.areaCrossDir}
                                                        error={!!state?.errors?.areaCrossDir}
                                                        select
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        disabled={!state.isActive | state.isDeleted}
                                                    >
                                                        <MenuItem value=""> </MenuItem>
                                                        <MenuItem value="N">N</MenuItem>
                                                        <MenuItem value="S">S</MenuItem>
                                                        <MenuItem value="E">E</MenuItem>
                                                        <MenuItem value="W">W</MenuItem>
                                                        <MenuItem value="NE">NE</MenuItem>
                                                        <MenuItem value="NW">NW</MenuItem>
                                                        <MenuItem value="SE">SE</MenuItem>
                                                        <MenuItem value="SW">SW</MenuItem>
                                                    </TextField>
                                                </Grid>
                                                <Grid item sm={7}>
                                                    <TextField
                                                        fullWidth
                                                        sx={ts}
                                                        id="areaCrossName"
                                                        label="Street Name"
                                                        name="areaCrossName"
                                                        value={state?.areaCrossName}
                                                        onChange={(e) => handleFieldChange('areaCrossName', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                                        onBlur={(e) => handleBlur('areaCrossName', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                                        helperText={state?.errors?.areaCrossName}
                                                        error={!!state?.errors?.areaCrossName}
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        disabled={!state.isActive | state.isDeleted}
                                                    />
                                                </Grid>
                                                <Grid item sm={2}>
                                                    <TextField
                                                        fullWidth
                                                        sx={ts}
                                                        select
                                                        id="areaCrossType"
                                                        label="Type"
                                                        name="areaCrossType"
                                                        value={state?.areaCrossType}
                                                        onChange={(e) => handleFieldChange('areaCrossType', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                                        onBlur={(e) => handleBlur('areaCrossType', e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}
                                                        helperText={state?.errors?.areaCrossType}
                                                        error={!!state?.errors?.areaCrossType}
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        SelectProps={{
                                                            MenuProps: {
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: '20em', // Set the desired height here
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                        disabled={!state.isActive | state.isDeleted}
                                                    >
                                                        <MenuItem value={null || '' || undefined}> </MenuItem>
                                                        {
                                                            streetTypes?.map(({ codeValue, codeTitle }) => {
                                                                return <MenuItem key={codeTitle} value={codeValue}>{codeTitle}</MenuItem>
                                                            })
                                                        }
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default CitationView;
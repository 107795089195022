import React, { useContext, useState } from 'react';
import axios from 'axios';

import { Box, Button, Tooltip, Menu, MenuItem, Modal } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SettingsIcon from '@mui/icons-material/Settings';

import { toast } from 'react-toastify';

import { CaseContext } from '../../../../context/CaseContext';
import UserContext from '../../../../context/UserContext';
import { apiRoute } from '../../../../App.js';
import PaymentCommentsBox from '../../../../components/modals/views/PaymentCommentModal'


const CaseHistoryView = (props) => {
    const { handleOpen, setSelectedPayment, ts, selectedPayment, azureBlob, setAzureBlob, retrieveReceipt, retrieveReceiptPayment, setRetrieveReceiptPayment } = props;

    const { setReceiptNumber, caseHistoryRows } = useContext(CaseContext);

    const { supervisorCheck } = useContext(UserContext);

    const [anchorEl, setAnchorEl] = useState({});

    const [comments, setComments] = useState("");

    const [commentsErrors, setErrors] = useState({});

    // SORTING THE CASE HISTORY ROWS TO HAVE IT DESC BY THE PK
    const rows = [...caseHistoryRows].sort((a, b) => b.pkFfPaymentId - a.pkFfPaymentId);

    const handleClick = (e, rowId) => {
        setAnchorEl(prevState => ({
            ...prevState,
            [rowId]: e.currentTarget
        }));
    };
    const handleClose = (rowId) => {
        setAnchorEl(prevState => ({
            ...prevState,
            [rowId]: null
        }));
    };

    const getReceiptNum = (params) => {
        setReceiptNumber(params.row.receiptNumber);
        setSelectedPayment(params.row);
    };

    // Comments Modal Handling
    const [openCommentsModal, setOpenCommentsModal] = useState(false);
    const handleCommentsModal = () => {
        setOpenCommentsModal(!openCommentsModal);
    };

    const printGuiltyPlea = (id) => {
        axios.get(`${apiRoute}/api/reportguiltyplea/${id}`, { responseType: 'arraybuffer' })
            .then((response) => {
                const arrayBufferView = new Uint8Array(response.data);
                const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                const dataUrl = URL.createObjectURL(blob);
                window.open(dataUrl, '_blank');
            })
            .catch(error => {
                console.error('Error fetching guilty receipt from front end:', error);
                toast.error(`Court is required on the case to print a guilty plea report.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
    };

    const updatePaymentComments = (e) => {
        e.preventDefault();
        if (commentsErrors.comments) {
            return
            toast.error("Comment Required", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        const newNote = { ...selectedPayment, notes: comments, paymentDate: new Date(selectedPayment.paymentDate), dateEnter: new Date(selectedPayment.dateEnter) }

        axios.put(`${apiRoute}/api/ffpaymenttbls/updatepaymentnotes/${newNote.pkFfPaymentId}`, newNote)
            .then(({ data }) => {
                toast.success("Comments saved", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setOpenCommentsModal(false);
                printGuiltyPlea(data.pkFfPaymentId);
            })
            .catch((err) => {
                console.log("❌ ERR SAVING COMMENTS", err);
                toast.error("Error saving comments", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            });
    };


    const columns = [
        {
            field: 'paymentDate',
            headerName: 'Payment Date',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            width: 125
        },
        {
            field: 'paymentType',
            headerName: 'Payment Type',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            width: 150
        },
        {
            field: 'paymentRef',
            headerName: 'Reference',
            flex: 0,
            headerAlign: 'center',
            align: 'center',
            width: 200
        },
        {
            field: 'paymentAmount',
            headerName: 'Payment Amount',
            flex: 0,
            headerAlign: 'right',
            align: 'right',
            width: 200,

            renderCell: (params) => {
                return (
                    <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</>
                )
            },
        },
        {
            field: 'masterReceipt',
            headerName: 'Receipt #',
            flex: 0,
            headerAlign: 'center',
            align: 'center',
            width: 200
        },
        {
            field: 'enteredBy',
            headerName: 'Created By',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            width: 125
        },
        {
            field: 'dateEnter',
            headerName: 'Created Date',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            width: 125
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const refundCheck = params.row.refundReason ? true : false;
                const settleCheck = params.row.settleDate ? true : false;
                const reversalCheck = params.row.disburseDate == null;

                return (
                    <>
                        <Button
                            id="payment-actions"
                            aria-controls={anchorEl[params.row.id] ? 'paymentActions-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl[params.row.id] ? 'true' : undefined}
                            onClick={(e) => handleClick(e, params.row.id)}
                            variant="contained"
                            sx={{ backgroundColor: "steelblue", color: 'white', fontSize: "20px", p: "15px" }}
                        >
                            <SettingsIcon />
                        </Button>
                        <Menu
                            id='paymentActions-menu'
                            anchorEl={anchorEl[params.row.id]}
                            open={Boolean(anchorEl[params.row.id])}
                            onClose={() => handleClose(params.row.id)}
                            MenuListProps={{
                                'aria-labelledby': 'payment-actions',
                            }}
                        >
                            {supervisorCheck ? !settleCheck : (params.row.paymentType !== 'REFUND' || params.row.paymentType !== 'TIME SERVED' || params.row.paymentType !== 'CREDIT CARD') && (
                                <MenuItem>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                        disabled={supervisorCheck}
                                        onClick={() => {
                                            handleOpen('editType', '40%');
                                            handleClose(params.row.id);
                                        }}
                                    >
                                        Edit Type
                                    </Button>
                                </MenuItem>
                            )}
                            <MenuItem>
                                {supervisorCheck ? (
                                    <Tooltip title="Supervisor Only" placement="left" disableHoverListener={supervisorCheck}>
                                        <span style={{ width: '100%' }}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                                disabled={!supervisorCheck | params.row.refundReason === "VOID"}
                                                onClick={() => {
                                                    handleOpen('voidPayment', '35%');
                                                    handleClose(params.row.id);
                                                }}
                                            >
                                                {
                                                    params.row.refundReason === 'VOID' ? 'VOIDED' : 'VOID'
                                                }
                                            </Button>
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Payment has been settled" placement="left" disableHoverListener={!settleCheck}>
                                        <span style={{ width: '100%' }}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                                disabled={settleCheck | params.row.refundReason === "VOID"}
                                                onClick={() => {
                                                    handleOpen('voidPayment', '35%');
                                                    handleClose(params.row.id);
                                                }}
                                            >
                                                {
                                                    params.row.refundReason === 'VOID' ? 'VOIDED' : 'VOID'
                                                }
                                            </Button>
                                        </span>
                                    </Tooltip>
                                )}
                            </MenuItem>
                            <MenuItem>
                                {
                                    supervisorCheck ? (
                                        <Tooltip title="Supervisor Only" placement="left" disableHoverListener={supervisorCheck}>
                                            <span style={{ width: '100%' }}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                                    disabled={params.row.paymentType === 'OVERPAYMENT' || !supervisorCheck || settleCheck || refundCheck}
                                                    onClick={() => {
                                                        handleOpen('refundPayment', '35%');
                                                        handleClose(params.row.id);
                                                    }}
                                                >
                                                    {
                                                        params.row.refundReason === 'REFUND' ? 'Refunded' : 'Refund'
                                                    }
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Payment has been settled" placement="left" disableHoverListener={!settleCheck}>
                                            <span style={{ width: '100%' }}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                                    disabled={params.row.paymentType === 'OVERPAYMENT' || !supervisorCheck || settleCheck || refundCheck}
                                                    onClick={() => {
                                                        handleOpen('refundPayment', '35%');
                                                        handleClose(params.row.id);
                                                    }}
                                                >
                                                    {
                                                        params.row.refundReason === 'REFUND' ? 'Refunded' : 'Refund'
                                                    }
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    )
                                }
                            </MenuItem>
                            <MenuItem>
                                {
                                    supervisorCheck ? (
                                        <Tooltip title="Supervisor Only" placement="left" disableHoverListener={supervisorCheck}>
                                            <span style={{ width: '100%' }}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                                    disabled={params.row.paymentType === 'OVERPAYMENT' || !supervisorCheck || reversalCheck}
                                                    onClick={() => {
                                                        handleOpen('reversePayment', '35%');
                                                        handleClose(params.row.id);
                                                    }}
                                                >
                                                    {
                                                        params.row.refundReason === 'REVERSAL' ? 'Reversed' : 'Reverse'
                                                    }
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            title="Cannot reverse payments before distribution of payment"
                                            placement="left"
                                        //disableHoverListener={reversalCheck}
                                        >
                                            <span style={{ width: '100%' }}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                                    disabled={params.row.paymentType === 'OVERPAYMENT' || !supervisorCheck || reversalCheck}
                                                    onClick={() => {
                                                        handleOpen('reversePayment', '35%');
                                                        handleClose(params.row.id);
                                                    }}
                                                >
                                                    {
                                                        params.row.refundReason === 'REVERSAL' ? 'Reversed' : 'Reverse'
                                                    }
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    )
                                }
                            </MenuItem>
                            <MenuItem>
                                <Button
                                    fullWidth
                                    onClick={async () => {
                                        await retrieveReceipt(params)
                                        handleClose(params.row.id);
                                    }}
                                    variant="contained"
                                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                    disabled={params.row.paymentType === "REFUND"}
                                >
                                    Print Payment Receipt
                                </Button>
                            </MenuItem>
                            <MenuItem>
                                <Button
                                    fullWidth
                                    onClick={() => {
                                        setSelectedPayment(params.row)
                                        //handleCommentsModal()
                                        printGuiltyPlea(params.row.id);
                                        handleClose(params.row.id);
                                    }}
                                    variant="contained"
                                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                >
                                    Print Guilty Plea
                                </Button>
                            </MenuItem>
                        </Menu>
                    </>
                );
            }
        },
    ];

    return (
        <Box sx={{ height: "24vh" }}>
            <DataGrid
                rows={rows}
                columns={columns}
                disableMultipleRowSelection
                onRowClick={getReceiptNum}
                autoHeight={false}
                density="compact"
                initialState={{
                    pagination: { paginationModel: { pageSize: 25 } },
                }}
                localeText={{
                    noRowsLabel:
                        'No payments have been made on this case',
                }}
                pageSizeOptions={[25, 50, 100]}
                sx={{
                    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                        marginTop: "1em",
                        marginBottom: "1em"
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                        outline: "none !important",
                    },
                }}
            />
            <Modal
                open={openCommentsModal}
                onClose={handleCommentsModal}
                aria-labelledby="comments-modal"
                sx={{ width: "25%", ml: "35vw", mt: "25vh", py: "4px" }}
            >
                <>
                    <PaymentCommentsBox
                        handleClose={handleCommentsModal}
                        updateCaseComments={updatePaymentComments}
                        textFieldStyle={ts}
                        comments={comments}
                        setComments={setComments}
                        errors={commentsErrors}
                        setErrors={setErrors}
                    />
                </>
            </Modal>
        </Box>
    )
}

export default CaseHistoryView;
import React from 'react'

import CaseProvider from '../context/CaseContext';
import HomeView from './Home';

const HomeViewDisplay = () => {
    return (
        <>
            <HomeView />
        </>
    )
}

export default HomeViewDisplay;
import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import { Badge, Box, Button, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { MdSpeakerNotes } from "react-icons/md";
import SettingsIcon from '@mui/icons-material/Settings';

import ViolationActions from '../../utils/violationsTab/ViolationActions';

import { CaseContext } from '../../../../context/CaseContext';
import UserContext from '../../../../context/UserContext';
import { apiRoute } from '../../../../App';
import { formatDate } from '../../../../Utils';

const ViolationsViewTable = (props) => {
    const {
        getViolationFeeFines,
        setCaseType,
        violationId,
        handleOpen,
        setDescription,
        setViolationView,
        violationView,
        setViolationData,
        violationData,
        comments,
        setComments,
        vioTransactionTbl,
        state
    } = props;

    const {
        violationRows,
        setViolationId,
        feeFineRows,
        getVioTransactionTbl,
    } = useContext(CaseContext);

    const { useFeeSchedule, entityId } = useContext(UserContext);

    const [anchorEl, setAnchorEl] = useState({});
    const [disbursedFees, setDisbursedFees] = useState(false);

    useEffect(() => {
        if (feeFineRows.length > 0) {
            const ids = feeFineRows.map(({ pkFeeFineId }) => pkFeeFineId);
            const idsQueryParam = ids.join('&ids=');
            axios.get(`${apiRoute}/api/DisburseDtl?ids=${idsQueryParam}`)
                .then((res) => {
                    setDisbursedFees(res.data);
                })
                .catch((err) => console.error(err));
        }
    }, [feeFineRows]);

    const handleClick = (e, rowId) => {
        setAnchorEl(prevState => ({
            ...prevState,
            [rowId]: e.currentTarget
        }));
    };

    const handleCloseDropdown = (rowId) => {
        setAnchorEl(prevState => ({
            ...prevState,
            [rowId]: null
        }));
    };

    const addFeesCheck = () => {
        return feeFineRows.some(({ applyTo }) => applyTo === "FEE");
    };

    const addSuspendedCheck = () => {
        return feeFineRows.filter(ff => ff.applyTo === 'FEE').every(({ assessAmount, suspendAmount}) => assessAmount === suspendAmount);
    }

    const getViolationId = async (params) => {
        await getVioTransactionTbl(params.row.pkViolationId)
        if (violationView !== params.row || violationId !== params.row.pkViolationId) {
            const view = {
                ...params.row,
                amendmentStatus: '',
                amendmentRecordId: '',
            }
            setViolationView(view);
            setDescription(params.row.description);
            setViolationId(params.id);
            getViolationFeeFines(params.id);
            setCaseType(params.row.chargeType);
            axios.get(`${apiRoute}/api/ViolationTbls/${params.row.pkViolationId}`)
                .then(res => {
                    setViolationData(res.data); setComments(res.data.comments)
                })
                .catch(err => console.log(err))
        }
    };

    const cols = [
        {
            field: 'chargeType',
            headerName: 'Charge Type',
            headerAlign: 'left',
            align: 'left',
            width: 145,
        },
        {
            field: 'status',
            headerName: 'Status',
            headerAlign: 'left',
            align: 'left',
            width: 150,
            renderCell: (params) => {
                if (params.row.amendedFromViolation == null) {
                    return (
                        <Tooltip
                            title={params.value}
                            sx={{
                                border: '1px solid white'
                            }}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        border: '1px solid white',
                                        borderRadius: '0px',
                                        backgroundColor: '#4c4e52',
                                        fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : params.row.amendedDate != null ? 'bold' : 'inherit'
                                    },
                                },
                            }}
                            placement='right-end'
                        >
                            <span
                                style={{
                                    color: params.row.courtAppearRqd === 'Y' ? 'red' : params.row.amendedDate != null ? '#E3D026' : 'inherit',
                                    fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : 'inherit',
                                }}
                            >
                                ORIGINAL
                            </span>
                        </Tooltip>
                    )

                } else {
                    return (
                        <Tooltip
                            title={params.value}
                            sx={{
                                border: '1px solid white'
                            }}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        border: '1px solid white',
                                        borderRadius: '0px',
                                        backgroundColor: '#4c4e52',
                                        fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : params.row.amendedDate != null ? 'bold' : 'inherit'
                                    },
                                },
                            }}
                            placement='right-end'
                        >
                            <span
                                style={{
                                    color: params.row.courtAppearRqd === 'Y' ? 'red' : params.row.amendedDate != null ? '#E3D026' : 'inherit',
                                    fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : 'inherit',
                                }}
                            >
                                AMENDED
                            </span>
                        </Tooltip>
                    )
                }
            }
        },
        {
            field: 'description',
            headerName: 'Description',
            headerAlign: 'left',
            align: 'left',
            width: 400,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={params.value}
                        sx={{
                            border: '1px solid white'
                        }}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    border: '1px solid white',
                                    borderRadius: '0px',
                                    backgroundColor: '#4c4e52',
                                    fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : params.row.amendedDate != null ? 'bold' : 'inherit'
                                },
                            },
                        }}
                        placement='right-end'
                    >
                        <span
                            style={{
                                color: params.row.courtAppearRqd === 'Y' ? 'red' : params.row.amendedDate != null ? '#E3D026' : 'inherit',
                                fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : 'inherit',
                                textDecoration: params.row.amendedDate != null ? 'line-through' : 'inherit'
                            }}
                        >
                            {params.value}
                        </span>
                    </Tooltip>
                )
            }
        },
        {
            field: 'currentStatute',
            headerName: 'Statute',
            headerAlign: 'left',
            align: 'left',
            width: 120,
            hidden: true
        },
        {
            field: 'dueDate',
            headerName: 'Pay By Date',
            headerAlign: 'right',
            align: 'right',
            width: 140,
            valueFormatter: ({ value }) => {
                if (value === undefined || value === null || value === "") return "";
                return `${formatDate(value)}`;
            },
        },
        {
            field: 'totalFines',
            headerName: 'Fine Total',
            headerAlign: 'right',
            align: 'right',
            type: 'number',
            width: 140,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumFractionDigits: 2
                        })
                            .format(params.value)}
                        sx={{
                            border: '1px solid white',
                        }}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    border: '1px solid white',
                                    borderRadius: '0px',
                                    backgroundColor: '#4c4e52',
                                    fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : params.row.amendedDate != null ? 'bold' : 'inherit'
                                },
                            },
                        }}
                        placement='right-end'
                    >
                        <span
                            style={{
                                color: params.row.courtAppearRqd === 'Y' ? 'red' : params.row.amendedDate != null ? '#E3D026' : 'inherit',
                                fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : 'inherit',
                                textDecoration: params.row.amendedDate != null ? 'line-through' : 'inherit'
                            }}
                        >
                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}
                        </span>
                    </Tooltip>
                )
            }
        },
        {
            field: 'totalCosts',
            headerName: 'Cost Total',
            headerAlign: 'right',
            align: 'right',
            type: 'number',
            width: 140,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumFractionDigits: 2
                        })
                            .format(params.value)}
                        sx={{
                            border: '1px solid white'
                        }}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    border: '1px solid white',
                                    borderRadius: '0px',
                                    backgroundColor: '#4c4e52',
                                    fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : params.row.amendedDate != null ? 'bold' : 'inherit'
                                },
                            },
                        }}
                        placement='right-end'
                    >
                        <span
                            style={{
                                color: params.row.courtAppearRqd === 'Y' ? 'red' : params.row.amendedDate != null ? '#E3D026' : 'inherit',
                                fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : 'inherit',
                                textDecoration: params.row.amendedDate != null ? 'line-through' : 'inherit'
                            }}
                        >
                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}
                        </span>
                    </Tooltip>
                )
            }
        },
        {
            field: 'totalFees',
            headerName: 'Fee Total',
            headerAlign: 'right',
            align: 'right',
            type: 'number',
            width: 140,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumFractionDigits: 2
                        })
                            .format(params.value)}
                        sx={{
                            border: '1px solid white'
                        }}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    border: '1px solid white',
                                    borderRadius: '0px',
                                    backgroundColor: '#4c4e52',
                                    fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : params.row.amendedDate != null ? 'bold' : 'inherit'
                                },
                            },
                        }}
                        placement='right-end'
                    >
                        <span
                            style={{
                                color: params.row.courtAppearRqd === 'Y' ? 'red' : params.row.amendedDate != null ? '#E3D026' : 'inherit',
                                fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : 'inherit',
                                textDecoration: params.row.amendedDate != null ? 'line-through' : 'inherit'
                            }}
                        >
                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}
                        </span>
                    </Tooltip>
                )
            }
        },
        {
            field: 'remainingBalance',
            headerName: 'Balance',
            headerAlign: 'right',
            align: 'right',
            type: 'number',
            width: 140,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumFractionDigits: 2
                        })
                            .format(params.value)}
                        sx={{
                            border: '1px solid white'
                        }}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    border: '1px solid white',
                                    borderRadius: '0px',
                                    backgroundColor: '#4c4e52',
                                    fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : params.row.amendedDate != null ? 'bold' : 'inherit'
                                },
                            },
                        }}
                        placement='right-end'
                    >
                        <span
                            style={{
                                color: params.row.courtAppearRqd === 'Y' ? 'red' : params.row.amendedDate != null ? '#E3D026' : 'inherit',
                                fontWeight: params.row.courtAppearRqd === 'Y' ? 'bold' : 'inherit',
                                textDecoration: params.row.amendedDate != null ? 'line-through' : 'inherit'
                            }}
                        >
                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}
                        </span>
                    </Tooltip>
                )
            }
        },
        {
            field: 'comments',
            headerName: 'Violation Comments',
            headerAlign: 'center',
            align: 'center',
            width: 175,
            disableColumnFilter: 'true',
            disableColumnMenu: 'true',
            disableSort: 'true',
            sortComparator: () => {
                return 0;
            },
            renderCell: (params) => {
                return (
                    <Button
                        fullWidth
                        onClick={(e) => {
                            handleOpen('Comments', '50%');
                        }}
                        variant="contained"
                        sx={{
                            backgroundColor: "steelblue",
                            color: 'white',
                            fontSize: "22px",
                            p: "4px",
                            ml: '.5vw'
                        }}
                    >
                        <Badge
                            badgeContent={params.row.comments?.length > 0 ? "!!" : ""}
                            color={params.row.comments?.length > 0 ? 'warning' : 'default'}
                            overlap="circular" //variant="dot"
                            sx={{
                                '& .MuiBadge-badge': {
                                    color: 'white', // Change color here
                                    fontSize: 15
                                },
                            }}
                        >
                            <MdSpeakerNotes />
                        </Badge>
                    </Button >
                )
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            headerAlign: 'center',
            align: 'center',
            width: 165,
            disableColumnFilter: 'true',
            disableColumnMenu: 'true',
            disableSort: 'true',
            sortComparator: () => {
                return 0;
            },
            renderCell: (params) => {
                return (
                    <>
                        <Button
                            id="violation-actions"
                            aria-controls={anchorEl[params.row.id] ? 'violation-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl[params.row.id] ? 'true' : undefined}
                            onClick={(e) => handleClick(e, params.row.id)}
                            variant="contained"
                            sx={{
                                backgroundColor: "steelblue",
                                color: 'white',
                                fontSize: "20px",
                                p: "3px",
                            }}
                            fullWidth
                        >
                            <SettingsIcon />
                        </Button>
                        <ViolationActions
                            anchorEl={anchorEl}
                            params={params}
                            handleCloseDropdown={handleCloseDropdown}
                            handleOpen={handleOpen}
                            disbursedFees={disbursedFees}
                            addFeesCheck={addFeesCheck}
                            useFeeSchedule={useFeeSchedule}
                            setViolationData={setViolationData}
                            comments={comments}
                            setComments={setComments}
                            state={state}
                            feeFineRows={feeFineRows}
                            addSuspendedCheck={addSuspendedCheck}
                        />
                    </>
                )
            }
        },
    ];

    const minHeight = violationRows?.length < 1 ? '25vh' : 'inherit';

    const columns = cols;

    return (
        <Box sx={{ height: minHeight }}>
            <DataGrid
                rows={violationRows}
                columns={columns.map((column, index) => ({
                    ...column,
                    headerClassName: index === 0 ? 'first-column-header' : '',
                    cellClassName: index === 0 ? 'first-column-cell' : '',
                }))}
                disableMultipleRowSelection
                onRowClick={getViolationId}
                onCellDoubleClick={
                    () => {
                        handleOpen('violationInfo', '50%', true);
                    }
                }
                density="compact"
                initialState={{
                    pagination: { paginationModel: { pageSize: 25 } },
                }}
                localeText={{
                    noRowsLabel:
                        'No violations, click Add Violation to add a violation',
                }}
                sx={{
                    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                        marginTop: "1em",
                        marginBottom: "1em"
                    },
                    maxHeight: '30.5vh',
                    '.first-column-header': {
                        paddingLeft: '2em', // Adjust the padding value as needed
                    },
                    '.first-column-cell': {
                        paddingLeft: '2em', // Adjust the padding value as needed
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                        outline: "none !important",
                    },
                }}
            />
        </Box>
    )
}

export default ViolationsViewTable;
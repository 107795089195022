import React, { useReducer } from 'react';
import axios from 'axios';

import { Box, Button, TextField, Paper, LinearProgress } from '@mui/material';

import { toast } from 'react-toastify';
import { apiRoute } from '../../../../../App';



const AgencyForm = (props) => {
    const { handleClose, data, loading, setLoading, modalType, ts, rows, setRows, getRows } = props;

    const initialState = {
        code: modalType === 'add' ? null : data?.code,
        description: modalType === 'add' ? null : data?.description,
        usCode: modalType === 'add' ? null : data?.usCode,
        accountNumber: modalType === 'add' ? null : data?.accountNumber,
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const fieldValidations = {
        code: {
            errorRequired: 'Code is required',
            validation: (value) => !value
        },
        description: {
            errorRequired: 'Description is required.',
            validation: (value) => !value
        }
    }

    const handleBlur = (field, value) => {
        const fieldInfo = fieldValidations[field];
        let error = null;

        if (fieldInfo) {
            if (!value) {
                error = fieldInfo.errorRequired
            }
        }
        dispatch({ type: 'UPDATE_FIELD', field, value, error });

        //switch (field) {
        //    case 'description':
        //        if (!value) {
        //            error = 'Description is required';
        //        }
        //        break;
        //    default:
        //        break;
        //}
        //dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (modalType === 'add') {
            const agency = {
                Code: state?.code,
                Description: state?.description,
                UsCode: state?.usCode,
                AccountNumber: state?.accountNumber,
            }
            axios.post(`${apiRoute}/api/AgencyTbls`, agency)
                .then((res) => {
                    setRows([...rows, {...res.data, id: res.data.pkAgencyId}])
                    toast.success('Agency added successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error(`Error ${err?.response?.status} while creating agency!`, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    console.log(err, 'Error adding agency!');
                })
                .finally(() => { setLoading(false); getRows() })
        } else {
            const agency = {
                ...data,
                Code: state?.code,
                Description: state?.description,
                UsCode: state?.usCode,
                AccountNumber: state?.accountNumber,
            }

            axios.put(`${apiRoute}/api/AgencyTbls/${data.id}`, agency)
                .then((res) => {
                    const updatedRows = rows.map(row => {
                        if (row.id === data.id) {
                            return { ...row, ...res.data };
                        }
                        return row;
                    });
                    setRows(updatedRows);
                    toast.success('Agency updated successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error(`Error ${err?.response?.status} while updating agency!`, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    console.log(err, 'Error updating Agency');
                })
                .finally(() => { setLoading(false); getRows() })
        }
    }

    return (
        <>
            <Paper elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                    <h1>
                        <strong>{modalType === 'add' ? 'Add Agency' : 'Edit Agency'}</strong>
                    </h1>
                </Paper>
                <form onSubmit={handleSubmit}>
                    <Box p={1}>
                        <Box width={1} flexWrap="wrap" display="flex">
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="code"
                                    label="Code"
                                    name="code"
                                    value={state.code}
                                    onChange={(e) => {
                                        handleFieldChange('code', e.target.value);
                                    }}
                                    onBlur={(e) => handleBlur('code', e.target.value)}
                                    helperText={state?.errors?.code}
                                    error={!!state?.errors?.code}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="description"
                                    label="Description"
                                    name="description"
                                    value={state.description}
                                    onChange={(e) => {
                                        handleFieldChange('description', e.target.value);
                                    }}
                                    onBlur={(e) => handleBlur('description', e.target.value)}
                                    helperText={state?.errors?.description}
                                    error={!!state?.errors?.description}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="usCode"
                                    label="US Code"
                                    name="usCode"
                                    value={state.usCode}
                                    onChange={(e) => {
                                        handleFieldChange('usCode', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 9 }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="accountNumber"
                                    label="Account Number"
                                    name="accountNumber"
                                    value={state.accountNumber}
                                    onChange={(e) => {
                                        handleFieldChange('accountNumber', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="right" gap={2} p={1}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Paper>
            {
                loading && (
                    <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
                )
            }
        </>
    );
};

export default AgencyForm;

import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { apiRoute } from '../App.js';

const SystemContext = createContext({
    agencyCode: Array,
    setAgencyCode: () => { },
    allocationTypes: Array,
    setAllocationTypes: () => { },
    autoBondNum: Boolean,
    setAutoBondNum: () => { },
    caseTypes: Array,
    setCaseTypes: () => { },
    caseYear: Array,
    setcaseYear: () => { },
    chargeTypeFees: Array,
    setChargeTypeFees: () => { },
    courtNum: Array,
    setCourtNum: () => { },
    divisionCode: Array,
    setDivisionCode: () => { },
    loadingCaseTypeFees: Boolean,
    setLoadingCaseTypeFees: () => { },
    partialPaymentChecks: Array,
    setPartialPaymentChecks: () => { },
    paymentOptions: Array,
    setPaymentOptions: () => { },
    races: Array,
    setRaces: () => { },
    sexes: Array,
    setSexes: () => { },
    states: Array,
    setStates: () => { },
    statusCodes: Array,
    setStatusCodes: () => { },
    streetTypes: Array,
    setStreetTypes: () => { },
    suffixCodes: Array,
    setSuffixCodes: () => { },
    vehicleMakes: Array,
    setVehicleMakes: () => { },
    vehicleTypes: Array,
    setVehicleTypes: () => { },
    vehicleStyles: Array,
    setVehicleStyles: () => { },
    vehicleColors: Array,
    setVehicleColors: () => { },
    getCodes: () => { },
    getYears: () => { },
    resetSystemContextPromise: () => { }
});

const SystemProvider = ({ children }) => {
    const [agencyCode, setAgencyCode] = useState([]);
    const [allocationTypes, setAllocationTypes] = useState([]);
    const [autoBondNum, setAutoBondNum] = useState(false);
    const [caseTypes, setCaseTypes] = useState([]);
    const [caseYear, setcaseYear] = useState([]);
    const [chargeTypeFees, setChargeTypeFees] = useState([]);
    const [courtNum, setCourtNum] = useState([]);
    const [divisionCode, setDivisionCode] = useState([]);
    const [loadingCaseTypeFees, setLoadingCaseTypeFees] = useState(false);
    const [partialPaymentChecks, setPartialPaymentChecks] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [races, setRaces] = useState([]);
    const [sexes, setSexes] = useState([]);
    const [states, setStates] = useState([]);
    const [statusCodes, setStatusCodes] = useState([]);
    const [streetTypes, setStreetTypes] = useState([]);
    const [suffixCodes, setSuffixCodes] = useState([]);
    const [vehicleColors, setVehicleColors] = useState([]);
    const [vehicleMakes, setVehicleMakes] = useState([]);
    const [vehicleStyles, setVehicleStyles] = useState([]);
    const [vehicleTypes, setVehicleTypes] = useState([]);

    const hasToken = localStorage.getItem('token') != null || localStorage.getItem('token') !== undefined;

    const resetSystemContext = () => {
        setAgencyCode([]);
        setAllocationTypes([]);
        setAutoBondNum(false);
        setCaseTypes([]);
        setcaseYear([]);
        setChargeTypeFees([]);
        setCourtNum([]);
        setDivisionCode([]);
        setLoadingCaseTypeFees(false);
        setPartialPaymentChecks([]);
        setPaymentOptions([]);
        setRaces([]);
        setSexes([]);
        setStates([]);
        setStatusCodes([]);
        setStreetTypes([]);
        setSuffixCodes([]);
        setVehicleColors([]);
        setVehicleMakes([]);
        setVehicleStyles([]);
        setVehicleTypes([]);
    };

    const resetSystemContextPromise = async () => {
        return new Promise((resolve) =>{
            resetSystemContext();
            resolve();
        });
    };

    //* -------------------- GET SYSCODE/LUCODE FUNCTION ---------------------
    const getCodes = async () => {
        axios.get(`${apiRoute}/api/AgencyTbls`)
            .then(({ data }) => {
                setAgencyCode(data);
            })
            .catch(err => {
                console.log("❌ AGENCY CODE = ERR")
            });

        axios
            .get(`${apiRoute}/api/SyscodeTbls/ByType/32`)
            .then(({ data }) => {
                setAllocationTypes(data);
            })
            .catch((err) => {
                console.log('❌ SYSCODE TYPE = 32 ERR');
            });

        axios.get(`${apiRoute}/api/SyscodeTbls/ByType/11`)
            .then(({ data }) => {
                const filteredData = data?.filter(({ code }) => code !== "NCOURT" && code !== "LEGA" && code !== "CREDIT CARD" && code !== "CATTITLE" && code !== "CASH BOND");
                setPaymentOptions(filteredData);
            })
            .catch(err => {
                console.log("❌ SYSCODE TYPE = 11 ERR")
            })

        axios.get(`${apiRoute}/api/SyscodeTbls/bond`)
            .then(({ data }) => {
                setAutoBondNum(data);
            })
            .catch(err => {
                console.log("❌ SYSCODE TYPE = 28 ERR")
            })

        axios.get(`${apiRoute}/api/SyscodeTbls/ByType/995`)
            .then(({ data }) => {
                setCaseTypes(data);
            })
            .catch(err => {
                console.log("❌ SYSCODE TYPE = 995 ERR")
            })

        axios
            .get(`${apiRoute}/api/SyscodeTbls/ByType/25`)
            .then(({ data }) => {
                setPartialPaymentChecks(data);
            })
            .catch((err) => {
                console.log('❌ SYSCODE TYPE = 25 ERR');
            });

        axios.get(`${apiRoute}/api/LuCodes/default/STAT`)
            .then(({ data }) => {
                const sortedStates = data.sort((a, b) => a.codeTitle.localeCompare(b.codeTitle))
                setStates(sortedStates)
            })
            .catch(err => {
                console.log("❌ LU_CODES CODE_TYPE = STAT ERR")
            })

        axios.get(`${apiRoute}/api/LuCodes/default/RACE`)
            .then(({ data }) => {
                setRaces(data)
            })
            .catch(err => {
                console.log("❌ LU_CODES CODE_TYPE = RACE ERR")
            })

        axios.get(`${apiRoute}/api/LuCodes/default/SEX`)
            .then(({ data }) => {
                setSexes(data)
            })
            .catch(err => {
                console.log("❌ LU_CODES CODE_TYPE = SEX ERR")
            })

        axios.get(`${apiRoute}/api/LuCodes/default/VCOL`)
            .then(({ data }) => {
                setVehicleColors(data)
            })
            .catch(err => {
                console.log("❌ LU_CODES CODE_TYPE = VCOL ERR")
            })

        axios.get(`${apiRoute}/api/LuCodes/default/VMAK`)
            .then(({ data }) => {
                setVehicleMakes(data)
            })
            .catch(err => {
                console.log("❌ LU_CODES CODE_TYPE = VMAK ERR")
            })

        axios.get(`${apiRoute}/api/LuCodes/default/VSTY`)
            .then(({ data }) => {
                setVehicleStyles(data)
            })
            .catch(err => {
                console.log("❌ LU_CODES CODE_TYPE = VSTY ERR")
            })

        axios.get(`${apiRoute}/api/LuCodes/default/VTYP`)
            .then(({ data }) => {
                setVehicleTypes(data)
            })
            .catch(err => {
                console.log("❌ LU_CODES CODE_TYPE = VTYP ERR")
            })

        axios.get(`${apiRoute}/api/LuCodes/default/STRE`)
            .then(({ data }) => {
                setStreetTypes(data)
            })
            .catch(err => {
                console.log("❌ LU_CODES CODE_TYPE = STRE ERR")
            })

        axios.get(`${apiRoute}/api/CourtTbls`)
            .then(({ data }) => {
                setCourtNum(data);
            })
            .catch(err => {
                console.log("❌ COURT NUM = ERR")
            })

        axios.get(`${apiRoute}/api/DivisionTbls`)
            .then(({ data }) => {
                setDivisionCode(data);
            })
            .catch(err => {
                console.log("❌ DIVISION CODE = ERR")
            })

        axios
            .get(`${apiRoute}/api/SyscodeTbls/ByType/30`)
            .then(({ data }) => {
                setStatusCodes(data);
            })
            .catch((err) => {
                console.log('❌ SYSCODE TYPE = 30 ERR');
            });

        axios
            .get(`${apiRoute}/api/SyscodeTbls/ByType/31`)
            .then(({ data }) => {
                setSuffixCodes(data);
            })
            .catch((err) => {
                console.log('❌ SYSCODE TYPE = 31 ERR');
            });

        setLoadingCaseTypeFees(true);
        try {
            const { data } = await axios.get(`${apiRoute}/api/syscodetbls/ByType/33`);
            const newRows = data?.map((row) => ({
                ...row,
                id: row.pkSyscodeId
            }));
            const rows = newRows?.filter(({ code }) => code !== "CATTITLE");
            setChargeTypeFees(rows.sort((a, b) => a.code.localeCompare(b.code)));
        } catch (err) {
            console.log('error getting syscode 1', err);
        } finally {
            setLoadingCaseTypeFees(false);
        }
    };

    const getYears = async () => {
        try {
            //* -------------------- FETCH YEAR LIST ---------------------
            axios.get(`${apiRoute}/api/CaseTbls/getYears`)
                .then(({ data }) => {
                    setcaseYear(data);
                })
                .catch(err => {
                    console.log('err: ', err)
                    return toast.error("Error in Years", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                })
        } catch (err) {

        }
    }

    const contextValue = {
        agencyCode: agencyCode,
        setAgencyCode,
        allocationTypes: allocationTypes,
        setAllocationTypes,
        caseYear: caseYear,
        setcaseYear,
        paymentOptions: paymentOptions,
        setPaymentOptions,
        states: states,
        setStates,
        races: races,
        setRaces,
        sexes: sexes,
        setSexes,
        vehicleMakes: vehicleMakes,
        setVehicleMakes,
        vehicleTypes: vehicleTypes,
        setVehicleTypes,
        vehicleStyles: vehicleStyles,
        setVehicleStyles,
        vehicleColors: vehicleColors,
        setVehicleColors,
        streetTypes: streetTypes,
        setStreetTypes,
        courtNum: courtNum,
        setCourtNum,
        divisionCode: divisionCode,
        setDivisionCode,
        chargeTypeFees: chargeTypeFees,
        setChargeTypeFees,
        loadingCaseTypeFees: loadingCaseTypeFees,
        setLoadingCaseTypeFees,
        autoBondNum: autoBondNum,
        setAutoBondNum,
        caseTypes: caseTypes,
        setCaseTypes,
        partialPaymentChecks: partialPaymentChecks,
        setPartialPaymentChecks,
        statusCodes: statusCodes,
        setStatusCodes,
        suffixCodes: suffixCodes,
        setSuffixCodes,
        getCodes,
        getYears,
        resetSystemContextPromise,
    }

    return (
        <SystemContext.Provider value={contextValue}>
            {children}
        </SystemContext.Provider>
    );
}

export default SystemProvider;
export { SystemContext };


import React, { useContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';

import { Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, LinearProgress, MenuItem, TextField, Paper, Radio, RadioGroup } from '@mui/material';

import { toast } from 'react-toastify';

import { apiRoute } from '../../../../../App';

import UserContext from '../../../../../context/UserContext';
import { commaMoneyFormat } from '../../../../../Utils';

const StatuteFineForm = (props) => {
    const { handleClose, modalType, data, loading, setLoading, ts, rows, setRows, getRows } = props;

    const [chargeTypes, setChargeTypes] = useState([]);
    const [displayDefaultFine, setDisplayDefaultFine] = useState(
        modalType === 'edit'
            ? commaMoneyFormat(data?.defaultFine)
            : ''
    )

    const { useFeeSchedule, entityId } = useContext(UserContext);

    useEffect(() => {
        axios.get(`${apiRoute}/api/SyscodeTbls/ByType/1`)
            .then((res) => {
                setChargeTypes(res.data.filter((item) => item.code !== "CATTITLE"))
            })
            .catch((err) => console.log(err))
    }, [])

const initialState = {
    courtNum: modalType === 'add' ? '' : data?.courtNum,
    rsNumber: modalType === 'add' ? '' : data?.rsNumber,
    description: modalType === 'add' ? '' : data?.description,
    chargeType: modalType === 'add' ? 'NOFEES' : data?.chargeType,
    class: modalType === 'add' ? '' : data?.class,
    courtAppearRqd: modalType === 'add' ? 'N' : data?.courtAppearRqd,
    defaultFine: modalType === 'add' ? '' : parseFloat(data?.defaultFine | '0.00').toFixed(2),
    begDate: modalType === 'add' ? new Date().toISOString().slice(0, 10) : data?.begDate.split('T')[0],
    endDate: modalType === 'add' ? '9999-01-01' : data?.endDate.split('T')[0],
    cmisCategory: modalType === 'add' ? '' : data.cmisCategory,
    caseLevelFee: modalType === 'add' ? false : data.caseLevelFee
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_FIELD':
            return {
                ...state,
                [action.field]: action.value,
                errors: {
                    ...state.errors,
                    [action.field]: action.error,
                },
            };
        case 'UPDATE_FIELD_ERROR':
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.field]: action.error,
                }
            };
        case 'CLEAR_FIELD_ERROR':
            return {
                ...state,
                bond: (() => {
                    const updatedErrors = { ...state.errors };
                    delete updatedErrors[action.field];
                    return {
                        ...state,
                        errors: updatedErrors,
                    };
                }),
            };
        default:
            return state;
    }
};

const [state, dispatch] = useReducer(reducer, initialState);

const handleFieldChange = (field, value) => {
    dispatch({ type: 'UPDATE_FIELD', field, value });
};

const handleBlur = (field, value) => {
    let error = null;
    switch (field) {
        case 'description':
            if (!value) {
                error = 'Description is required';
            }
            break;
        case 'chargeType':
            if (!value) {
                error = 'Charge type is required'
            }
            break;
        case 'defaultFine':
            if (!value) {
                error = 'Default fine is required';
            }
            if (value.length > 8) {
                error = 'Default fine cannot exceed 8 digits'
            }
            break;
        case 'begDate':
            if (!value) {
                error = 'Begin date is required'
            }
            break;
        case 'endDate':
            if (!value) {
                error = 'End date is required'
            }
            break;
        default:
            break;
    }
    dispatch({ type: 'UPDATE_FIELD', field, value, error });
};

const [radioGroupValue, setRadioGroupValue] = useState(state.cmisCategory);

const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (modalType === 'add') {
        const charge = {
            courtNum: state?.courtNum,
            rsNumber: state?.rsNumber,
            description: state?.description,
            chargeType: state?.chargeType,
            class: state?.class,
            courtAppearRqd: state?.courtAppearRqd,
            defaultFine: parseFloat(state?.defaultFine).toFixed(2),
            begDate: state?.begDate,
            endDate: state?.endDate,
            cmisCategory: state?.cmisCategory,
            caseLevelFee: state.caseLevelFee
        }
        axios.post(`${apiRoute}/api/StatuteFineTbls`, charge)
            .then((res) => {
                setRows([...rows, { ...res.data, id: res.data.pkStatuteFineId }])
                toast.success('Charge added successfully!', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                handleClose();
            })
            .catch((err) => {
                toast.error(`Error ${err?.response?.status} while creating charge!`, {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                console.log(err, 'Error adding StatuteFine!');
            })
            .finally(() => { setLoading(false); getRows() })
    } else {
        const charge = {
            ...data,
            courtNum: state?.courtNum,
            rsNumber: state?.rsNumber,
            description: state?.description,
            chargeType: state?.chargeType,
            class: state?.class,
            courtAppearRqd: state?.courtAppearRqd,
            defaultFine: parseFloat(state?.defaultFine | '0.00').toFixed(2),
            begDate: state?.begDate,
            endDate: state?.endDate,
            cmisCategory: state?.cmisCategory,
            caseLevelFee: state.caseLevelFee
        }
        axios.put(`${apiRoute}/api/StatuteFineTbls/${data.id}`, charge)
            .then((res) => {
                const updatedRows = rows?.map(row => {
                    if (row.id === data.id) {
                        return { ...row, ...res.data };
                    }
                    return row;
                });
                setRows(updatedRows);
                toast.success('Charge updated successfully!', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                handleClose();
            })
            .catch((err) => {
                toast.error(`Error ${err?.response?.status} while updating charge!`, {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                console.log(err, 'Error updating StatuteFine');
            })
            .finally(() => { setLoading(false); getRows() })
    }
}

const cmisOptions =
    [
        { value: 'DWI', label: 'D.W.I' },
        { value: 'FELONY', label: 'Felonies' },
        { value: 'LOCAL MISD', label: 'Misdemeanors (Local Ordinance)' },
        { value: 'STATE MISD', label: 'Misdemeanors (State)' },
        { value: 'NO COURT', label: 'No Court' },
        { value: 'PARK', label: 'Parking (State and Local Ordinance)' },
        { value: 'LOCAL TRAF', label: 'Traffic (Local Ordinance)' },
        { value: 'STATE TRAF', label: 'Traffic (State)' },
        { value: 'WLF', label: 'Wildlife & Fisheries' }
    ];

return (
    <>
        <Paper elevation={10}>
            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                <h1>
                    <strong>
                        {
                            modalType === 'add' ? 'Add Charge To System' : 'Edit Charge In System'
                        }
                    </strong>
                </h1>
            </Paper>
            <form onSubmit={handleSubmit}>
                <Box p={1}>
                    <Box width={1} flexWrap="wrap" display="flex">
                        <Grid container spacing={2} mt={.75} width={1}>
                            <Grid item xs={10} md={4} mt={.75}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="rsNumber"
                                    label="RS Number"
                                    name="rsNumber"
                                    value={state.rsNumber}
                                    onChange={(e) => {
                                        handleFieldChange('rsNumber', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={8} mt={.75}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="description"
                                    label="Description (Required)"
                                    name="description"
                                    value={state.description}
                                    onChange={(e) => {
                                        handleFieldChange('description', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('description', e.target.value) }}
                                    helperText={state?.errors?.description}
                                    error={!!state?.errors?.description}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} mt={.75}>
                                <TextField
                                    fullWidth
                                    required
                                    select
                                    sx={ts}
                                    id="chargeType"
                                    label="Charge Type"
                                    name="chargeType"
                                    value={state.chargeType}
                                    onChange={(e) => {
                                        handleFieldChange('chargeType', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('chargeType', e.target.value) }}
                                    helperText={state?.errors?.chargeType}
                                    error={!!state?.errors?.chargeType}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        MenuProps: {
                                            PaperProps: {
                                                style: {
                                                    maxHeight: '20em',
                                                },
                                            },
                                        },
                                    }}
                                >
                                    {
                                        chargeTypes?.map(({ code, description }) => {
                                            return (
                                                <MenuItem key={code} value={code}>{code}</MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={3} mt={.75}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="defaultFine"
                                    label="Default Fine ($) (Required)"
                                    name="defaultFine"
                                    value={displayDefaultFine}
                                    onClick={() => setDisplayDefaultFine(state.defaultFine)}
                                    onChange={(e) => {
                                        handleFieldChange('defaultFine', e.target.value);
                                        setDisplayDefaultFine(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        handleBlur('defaultFine', e.target.value)
                                        if (e.target.value) {
                                            setDisplayDefaultFine(commaMoneyFormat(e.target.value))
                                        }
                                    }}
                                    helperText={state?.errors?.defaultFine}
                                    error={!!state?.errors?.defaultFine}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        style: { textAlign: 'right' },
                                        inputMode: 'numeric',
                                        min: 0,
                                        max: 99999999
                                    }}
                                />
                            </Grid>
                            <Grid item xs={10} md={2.5} mt={1}>
                                <FormControl>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        label="Case Level Fee:"
                                        value="start"
                                        control={
                                            <Checkbox
                                                checked={state.caseLevelFee === true}
                                                onChange={(e) => {
                                                    const val = e.target.checked ? true : false;
                                                    handleFieldChange('caseLevelFee', val);
                                                }}
                                            />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4} mt={.75}>
                                <TextField
                                    select
                                    fullWidth
                                    sx={ts}
                                    id="courtAppearRqd"
                                    label="Mandatory Court"
                                    name="courtAppearRqd"
                                    value={state.courtAppearRqd}
                                    onChange={(e) => {
                                        handleFieldChange('courtAppearRqd', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                >
                                    <MenuItem value="Y">Y</MenuItem>
                                    <MenuItem value="N">N</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={4} mt={.75}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="begDate"
                                    type="date"
                                    label="Start Date (Required)"
                                    name="begDate"
                                    value={state.begDate}
                                    onChange={(e) => {
                                        handleFieldChange('begDate', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('begDate', e.target.value) }}
                                    helperText={state?.errors?.begDate}
                                    error={!!state?.errors?.begDate}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} mt={.75}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="endDate"
                                    type="date"
                                    label="Stop Date (Required)"
                                    name="endDate"
                                    value={state.endDate}
                                    onChange={(e) => {
                                        handleFieldChange('endDate', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('endDate', e.target.value) }}
                                    helperText={state?.errors?.endDate}
                                    error={!!state?.errors?.endDate}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                        <Box width={4 / 4} mt={2} pr={3} >
                            <FormControl>
                                <FormLabel id="cmis-report-radio-button-group-label">Autofill with CMIS FORM A Category:</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="cmis-reports-radio-button-group-label"
                                    name="cmis-row-radio-buttons-group"
                                    value={radioGroupValue}
                                    onChange={(e) => {
                                        setRadioGroupValue(e.target.value);
                                        handleFieldChange('cmisCategory', e.target.value);
                                    }}
                                >
                                    {cmisOptions.map(c => {
                                        return (
                                            <FormControlLabel
                                                value={c.value}
                                                control={< Radio />}
                                                label={c.label}
                                            />
                                        )
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="right" gap={2} p={1}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: 'steelblue',
                            color: 'white',
                            mt: '2vh',
                        }}
                        type="submit"
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: 'steelblue',
                            color: 'white',
                            mt: '2vh',
                        }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Box>
            </form >
        </Paper >
        {
            loading && (
                <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
            )
        }
    </>
);
};

export default StatuteFineForm;


import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Paper, Card, Divider, LinearProgress, Modal, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import { toast, ToastContainer } from 'react-toastify';

import UserContext from '../../../context/UserContext';
import { ThemeModeContext, apiRoute } from '../../../App';
import { DistributionCheckContext } from '../../../context/DistributionCheckContext';
import InterestAndAllocationsModal from '../components/closeOut/InterestAndAllocationsModal';

const DisbursementDetailsModal = (props) => {
    const { ts, handleClose, from, setFrom, getDisburseReconDates, handlePrintDisbursementChecksModalOpen, retrievedData } = props;

    const [bankInterest, setBankInterest] = useState('$0.00')
    const [temp, setTemp] = useState('$0.00')
    const [errorModal, setErrorModal] = useState(false);
    const [errorText, setErrorText] = useState('');


    /* 
        temp state variable to allow for predisbursement calculations
        to be re-run if indeed there is a change to bankInterest
        after calculations have been run initially
    */

    const { mode } = useContext(ThemeModeContext);

    const navigate = useNavigate();

    const { entityId, userName } = useContext(UserContext);
    const { checksPrinted } = useContext(DistributionCheckContext)

    const [selectedDate, setSelectedDate] = useState('');
    const [disbursefetch, setDisburseFetch] = useState(false);
    const [disbursefetchLoading, setDisburseFetchLoading] = useState(false);
    const [disburseAmountFetch, setDisburseAmountFetch] = useState(false);
    const [disburseAmountFetchLoading, setDisburseAmountFetchLoading] = useState(false);
    const [disburseData, setDisburseData] = useState({});
    const [calcData, setCalcData] = useState({});
    const [interestApplied, setInterestApplied] = useState(false);
    const [applyingInterest, setApplyingInterest] = useState(false);
    const [applyInterestModal, setApplyInterestModal] = useState(false);
    const [interestDisbursees, setInterestDisbursees] = useState([]);
    const [payees, setPayees] = useState(disburseData?.disbursedPayees)
    const [printed, setPrinted] = useState(false);

    // will dictate which type of modal for interestAndAllocationsModal which="interest" for example
    const [which, setWhich] = useState('');
    const [allocations, setAllocations] = useState([]);
    const [showPrevious, setShowPrevious] = useState(false)
    const [accountingPeriod, setAccountingPeriod] = useState(`${new Date().getMonth() + 1}/${new Date().getFullYear()}`);
    const [accountingPeriodError, setAccountingPeriodError] = useState(false);

    const handleCalc = async () => {
        setDisburseFetchLoading(true);
        setTemp(bankInterest)
        const currentDate = new Date();
        if (new Date(selectedDate) > currentDate) {
            setErrorText('A future date cannot be selected');
            setErrorModal(true);
            setDisburseFetchLoading(false);
            return;
        } else if (!selectedDate) {
            setErrorText('A date must be selected');
            setErrorModal(true);
            setDisburseFetchLoading(false);
            return;
        }

        try {
            const calc = await axios.get(`${apiRoute}/api/Disbursements/${selectedDate}/${entityId}`);
            setDisburseFetch(true)
            setDisburseFetchLoading(false);
            setCalcData(calc.data)
        } catch (err) {
            console.log(err);
            setDisburseFetchLoading(false);
            if (err.response.data === 'All open batches in closing month must be settled before closing and disbursing funds.') {
                setErrorText('All open batches in closing month must be settled before closing and disbursing funds.')
                setErrorModal(true);
            } else {
                setErrorText(`Calc failed. ${err?.response?.data}`)
                setErrorModal(true);
            }
        }
    }

    const handleDisburseAmount = async () => {
        setDisburseAmountFetchLoading(true);
        try {

            const period = accountingPeriod.split('/');
            const month = period[0];
            const year = period[1];
            const disburse = await axios.post(`${apiRoute}/api/Disbursements/DisburseIt/${selectedDate}/${entityId}/${month}/${year}`);
            setDisburseAmountFetch(true)
            setDisburseAmountFetchLoading(false);
            disburse.data.disbursedPayees.forEach(payee => {
                const disburseDate = new Date(payee.disburseDate);
                const formattedDate = (disburseDate.getMonth() + 1).toString().padStart(2, '0') + '/' + disburseDate.getDate().toString().padStart(2, '0') + '/' + disburseDate.getFullYear();
                payee.id = crypto.randomUUID();
                payee.totalDisburseAmount = payee.totalDisburseAmount.toFixed(2)
                payee.bankInterest = '0.00';
                payee.grandTotal = payee.totalDisburseAmount;
                payee.entityId = entityId;
                payee.disbursedDate = formattedDate
            });
            setDisburseData(disburse.data)
            setPayees(disburse.data.disbursedPayees);
            getDisburseReconDates()
            setFrom('postDisburse')
        } catch (err) {
            console.error("error disbursing", err);
            setDisburseAmountFetchLoading(false);
        }
    };

    const calculateGrandTotal = () => {
        const payments = calcData?.paymentsTotal?.toFixed(2);
        const interest = bankInterest.slice(1,);
        const refunds = calcData?.refundsTotal?.toFixed(2);
        const grand = +payments + +interest + +refunds;
        const total = grand.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });
        return total;
    };

    const saveBankInterest = async () => {
        setApplyingInterest(true)
        try {
            const disburse = await axios.put(`${apiRoute}/api/DisburseDtl/applyInterest/${parseFloat(bankInterest.slice(1,))}`, interestDisbursees)
            toast.success('Interest Applied Successfully', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            disburse.data.updatedViewModel.forEach(payee => {
                const disburseDate = new Date(payee.disburseDate);
                const formattedDate = (disburseDate.getMonth() + 1).toString().padStart(2, '0') + '/' + disburseDate.getDate().toString().padStart(2, '0') + '/' + disburseDate.getFullYear();
                payee.id = crypto.randomUUID();
                payee.totalDisburseAmount = (payee.totalDisburseAmount).toFixed(2);
                payee.grandTotal = (parseFloat(payee.totalDisburseAmount) + payee.bankInterest).toFixed(2);
                payee.entityId = entityId;
                payee.bankInterest = payee.bankInterest.toFixed(2)
                payee.disbursedDate = formattedDate;
            });
            disburseData.disbursedPayees = disburse.data.updatedViewModel;
            setPayees(disburse.data.updatedViewModel);
            setInterestApplied(true);
            setWhich('allocations')
        }
        catch (e) {
            setInterestApplied(false)
            setApplyingInterest(false)
            console.log('error from saveBankInterest(): ', e)
        }
        finally {
            setApplyingInterest(false)
        }
    }

    const handlePrintCheck = () => {
        handlePrintDisbursementChecksModalOpen();
    };

    const columns = [
        {
            field: 'disbursedDate',
            headerName: 'Date',
            width: 120
        },
        {
            field: 'paytoName',
            headerName: 'Receiving Entities',
            width: 400
        },
        {
            field: 'vendorType',
            headerName: 'Type',
            width: 120
        },
        {
            field: 'totalDisburseAmount',
            headerName: 'Disburse Amount',
            width: 150,
            type: 'number',
            editable: true,
            headerAlign: 'right',
            renderCell: (params) => {
                return (
                    <strong>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</strong>
                )
            }
        },

        // Artifacts for allocations, if we decide to follow this route
        {
            field: 'allocatedAmount',
            headerName: "Allocation Amount",
            width: 145,
            type: 'number',
            editable: true,
            renderCell: (params) => {
                return (
                    <span style={{ color: params.value !== undefined ? params.value > '0.00' ? 'mediumseagreen' : params.value === '0.00' ? 'inherit' : 'red' : 'inherit' }}>
                        <strong>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value || 0)}</strong>
                    </span>
                )
            }
        },
        {
            field: 'bankInterest',
            headerName: "Bank Interest",
            width: 145,
            type: 'number',
            editable: true,
            renderCell: (params) => {
                return (
                    <span style={{ color: 'cornflowerblue', opacity: '200%' }}>
                        <strong>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</strong>
                    </span>
                )
            }
        },
        {
            field: 'grandTotal',
            headerName: 'Grand Total',
            width: 145,
            type: 'number',
            align: 'right',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <span style={{ color: params.value !== undefined & params.value > '0.00' ? 'limegreen' : 'indianred', marginRight: '1vw' }}>
                        <strong>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</strong>
                    </span>
                );
            }
        }
    ]

    const handleIACModal = () => {
        setApplyInterestModal(!applyInterestModal)
    }

    const handleInterestClick = () => {
        setWhich('interest');
        handleIACModal();
    }

    const handleAllocationClick = () => {
        setWhich('allocations');
        handleIACModal();
    }

    return (
        <>
            {
                from === 'preDisburse' &&
                <>
                    <Paper elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', textAlign: 'center' }}>
                            <h3>
                                <strong>Disbursement</strong>
                            </h3>
                        </Paper>
                        <Box sx={{ width: "85%", margin: "1vh auto", display: "flex", gap: "2rem", justifyContent: 'space-between' }}>
                            <Box mt={1.5} mb={1.5}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="bank-interest"
                                    label="Bank Interest"
                                    type="text"
                                    inputProps={{
                                        style: { textAlign: 'right' },
                                        inputMode: 'decimal',
                                    }}
                                    value={`${bankInterest}`}
                                    onClick={() => { setBankInterest('$') }}
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/[^0-9.]/g, '');
                                        if (!isNaN(value)) {
                                            setBankInterest('$' + value);
                                        }
                                    }}
                                    onBlur={(e) => {
                                        const value = e.target.value.replace(/[^0-9.]/g, '');
                                        if (isNaN(value) || value === '') {
                                            setBankInterest('$0.00')
                                            setTemp('$0.00')
                                        } else {
                                            setBankInterest('$' + parseFloat(value).toFixed(2))
                                        }
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box mt={1.5} mb={1.5}>
                                <TextField
                                    type="text"
                                    required
                                    sx={{ ...ts, width: '7.5vw' }}
                                    id="accountingPeriod"
                                    name="accountingPeriod"
                                    label="Accounting Period"
                                    value={accountingPeriod}
                                    onChange={(e) => setAccountingPeriod(e.target.value.replace(/[^0-9/]/g, ""))}
                                    error={accountingPeriodError}
                                    helperText={accountingPeriodError && "Accounting period must include a valid month and year separated by a '/'"}
                                    onBlur={() => {
                                        if (accountingPeriod.length < 6) {
                                            setAccountingPeriodError(true);
                                            return;
                                        }

                                        if (accountingPeriod.includes("/")) {
                                            const period = accountingPeriod.split('/');
                                            const month = period[0];
                                            if (month.length < 1 || (month > 12 || month <= 0)) {
                                                setAccountingPeriodError(true);
                                                return;
                                            }
                                            const year = period[1];
                                            if (year.length !== 4 || new Date().getFullYear().toString() !== year) {
                                                setAccountingPeriodError(true);
                                                return;
                                            }
                                            setAccountingPeriodError(false);
                                        } else {
                                            setAccountingPeriodError(true);
                                        }
                                        setAccountingPeriodError(false);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box mt={1.5} mb={1.5}>
                                <TextField
                                    type="date"
                                    required
                                    sx={{ ...ts, width: '7.5vw' }}
                                    id="disburseDate"
                                    name="disburseDate"
                                    label="Disbursal Date"
                                    value={selectedDate}
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', pb: '1vh', mr: '1rem' }}>
                            <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleCalc} disabled={(disbursefetch && (temp === bankInterest)) || accountingPeriodError}>Calculate</Button>
                            <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Cancel</Button>
                        </Box>
                        {disbursefetch && !disbursefetchLoading && (
                            <>
                                <hr />
                                <Box>
                                    <Paper className="border-radius-bottom" sx={{ color: 'frostwhite', backgroundColor: 'transparent', pl: '1vw' }} elevation={0}>
                                        <h4>Disbursement Overview</h4>
                                    </Paper>
                                    <Box sx={{ borderRadius: '10px', p: '1vh .5vw' }}>
                                        <Card style={{ border: '.5px solid grey', p: 'auto', backgroundColor: 'lightgray' }}>
                                            <Box sx={{ borderRadius: '5px', p: '.5vh .5vw', border: '1px solid white', m: '1vh .5vw', backgroundColor: 'white', pb: 0 }}>
                                                <em><p style={{ color: 'black' }}>Accounting Period: <p style={{ color: 'InfoText', float: 'right', fontWeight: 'bold' }}>{accountingPeriod}</p></p></em>
                                                <em><p style={{ color: 'black' }}>Refunds Total: <strong style={{ color: 'indianred', float: 'right' }}>{calcData?.refundsTotal?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }) || 0.00}</strong></p></em>
                                                <em><p style={{ color: 'black' }}>Interest Total: <strong style={{ color: 'steelblue', float: 'right' }}>{parseFloat(bankInterest.slice(1,)).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }) || 0.00}</strong></p></em>
                                                <em><p style={{ color: 'black' }}>Disbursement Total: <strong><p style={{ color: calcData.totalDeposited > 0.00 ? 'mediumseagreen' : 'darkred', float: 'right' }}>{calcData?.totalDeposited?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }) || 0.00}</p></strong></p></em>
                                                <Divider sx={{ borderColor: 'lightgray', borderWidth: '1px', margin: '2vh 0' }} component="div" />
                                                <strong><p style={{ color: 'black' }}> Grand Total: <p style={{ color: 'black', float: 'right' }}>{calculateGrandTotal()}</p></p></strong>
                                                <em><p style={{ color: 'black', marginBottom: '.5vh' }}>Disbursed By: <p style={{ color: 'black', float: 'right' }}>{userName}</p></p></em>
                                            </Box>
                                        </Card>
                                    </Box>
                                </Box>
                                <hr />
                                <div style={{ textAlign: 'center', marginBottom: '1vh' }}>
                                    <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white', marginBottom: '2vh' }} onClick={handleDisburseAmount} disabled={disburseAmountFetch || accountingPeriodError}>Disburse Funds</Button>
                                </div>
                            </>
                        )}
                        {(disbursefetchLoading || disburseAmountFetchLoading) && (
                            <div style={{ marginTop: '2vh' }}>
                                <LinearProgress />
                            </div>
                        )}
                    </Paper>
                    <Modal open={errorModal} sx={{ width: '25%', m: '10vh auto' }}>
                        <Paper elevation={10}>
                            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', textAlign: 'center' }}>
                                <h3>
                                    <strong>Error!</strong>
                                </h3>
                            </Paper>
                            <Box sx={{ textAlign: 'center', mt: '2vh' }}>
                                <h4>{errorText}</h4>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: '1vh' }}>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={() => setErrorModal(false)}>Close</Button>
                            </Box>
                        </Paper>
                    </Modal>

                </>
            }

            {
                from === 'postDisburse' && (
                    <>
                        <Paper elevation={3}>
                            <Paper className="border-radius-bottom" sx={{ color: 'white', height: 'auto', p: '.5vh .5vw .2vh', textAlign: 'center', backgroundColor: 'steelblue' }}>
                                <h3>
                                    <strong>Disbursement Details</strong>
                                </h3>
                            </Paper>
                            <Box sx={{ width: "100%", margin: "2vh auto", display: 'flex', justifyContent: 'center', overflowY: '' }}>
                                <Box sx={{ height: '70vh', display: 'flex', width: '95%' }}>
                                    <DataGrid
                                        columns={columns}
                                        rows={payees}
                                        loading={!disburseData}
                                        rowHeight={60}
                                        onRowClick={(p) => setSelectedDate(p.row.Value)}
                                        localeText={{ noRowsLabel: "Loading Disbursement..." }}
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 25 } }
                                        }}
                                        sx={{
                                            "& .MuiDataGrid-root": {
                                                height: "100%",
                                            },
                                            ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                                                marginTop: "1em",
                                                marginBottom: "1em",
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'start', float: 'left', ml: '.7vw' }}>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white', mr: '1rem' }} onClick={handlePrintCheck}>Print Checks</Button>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleAllocationClick}>Allocations</Button>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', pb: '1vh', mr: '1rem' }}>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleInterestClick} disabled={interestApplied}>{!interestApplied ? 'Bank Interest' : 'Interest Applied'}</Button>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Close</Button>
                            </Box>
                        </Paper>
                        {applyInterestModal === true && (
                            <Box
                                sx={{
                                    width: '35%',
                                    m: '5vh auto'
                                }}
                            >
                                <Modal
                                    open={applyInterestModal}
                                    onClose={handleIACModal}
                                    aria-labelledby="interest-and-allocations-modal"
                                >
                                    <InterestAndAllocationsModal
                                        handleClose={handleIACModal}
                                        payees={payees}
                                        setPayees={setPayees}
                                        bankInterest={bankInterest.slice(1,)}
                                        ts={ts}
                                        disbursees={interestDisbursees}
                                        setDisbursees={setInterestDisbursees}
                                        saveBankInterest={saveBankInterest}
                                        applyingInterest={applyingInterest}
                                        which={which}
                                        setWhich={setWhich}
                                        allocations={allocations}
                                        setAllocations={setAllocations}
                                        showPrevious={showPrevious}
                                        setShowPrevious={setShowPrevious}
                                    />
                                </Modal>
                            </Box>
                        )}

                    </>
                )}
            {
                from === 'retrieved' && (
                    <>
                        <Paper elevation={3}>
                            <Paper className="border-radius-bottom" sx={{ color: 'white', height: 'auto', p: '.5vh .5vw .2vh', textAlign: 'center', backgroundColor: 'steelblue' }}>
                                <h3>
                                    <strong>Disbursement Details</strong>
                                </h3>
                            </Paper>
                            <Box sx={{ width: "100%", margin: "2vh auto", display: 'flex', justifyContent: 'center', overflowY: '' }}>
                                <Box sx={{ height: '70vh', display: 'flex', width: '95%' }}>
                                    <DataGrid
                                        columns={columns}
                                        rows={retrievedData}
                                        loading={!retrievedData}
                                        rowHeight={60}
                                        localeText={{ noRowsLabel: "Loading Disbursement..." }}
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 25 } }
                                        }}
                                        sx={{
                                            "& .MuiDataGrid-root": {
                                                height: "100%",
                                            },
                                            ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                                                marginTop: "1em",
                                                marginBottom: "1em",
                                            },
                                            '&::-webkit-scrollbar': {
                                                display: 'none',
                                            },
                                            '-ms-overflow-style': 'none',
                                            scrollbarWidth: 'none',
                                            overflow: '-moz-scrollbars-none',
                                            scrollbars: 'hidden'
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'start', float: 'left', ml: '.7vw' }}>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white', mr: '1rem' }} onClick={handlePrintCheck}>Print Checks</Button>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleAllocationClick}>Allocations</Button>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', pb: '1vh', mr: '1rem' }}>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} disabled={from === 'retrieved'}>Interest Applied</Button>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Close</Button>
                            </Box>
                        </Paper>
                        {
                            applyInterestModal === true && (
                                <Box
                                    sx={{
                                        width: '35%',
                                        m: '5vh auto'
                                    }}
                                >
                                    <Modal
                                        open={applyInterestModal}
                                        onClose={handleIACModal}
                                        aria-labelledby="interest-and-allocations-modal"
                                    >
                                        <InterestAndAllocationsModal
                                            handleClose={handleIACModal}
                                            payees={retrievedData}
                                            bankInterest={bankInterest.slice(1,)}
                                            ts={ts}
                                            disbursees={interestDisbursees}
                                            setDisbursees={setInterestDisbursees}
                                            saveBankInterest={saveBankInterest}
                                            applyingInterest={applyingInterest}
                                            which={which}
                                            setWhich={setWhich}
                                            allocations={allocations}
                                            setAllocations={setAllocations}
                                            from={from}
                                            retrievedData={retrievedData}
                                            showPrevious={showPrevious}
                                            setShowPrevious={setShowPrevious}
                                        />
                                    </Modal>
                                </Box>
                            )}

                    </>
                )}
        </>
    )
}

export default DisbursementDetailsModal;
import React, { useContext, useEffect, useState, useRef } from 'react';

import { Box, Grid, Checkbox, MenuItem, TextField, IconButton, InputAdornment, FormControlLabel, Paper } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { SystemContext } from '../../context/SystemContext';

const PersonInfoView = (props) => {
    const { state, handleFieldChange, handleBlur, ts, resetState } = props;

    const { states, races, sexes } = useContext(SystemContext);

    const [ssnInputType, setSsnInputType] = useState('password');

    //* --------------- CODE TO HANDLE SHOW/HIDE SOCIAL SECURITY NUM INPUT VALUE ---------------
    // state that allows user to show their password while entering
    const [showSocSecNum, setShowSocSecNum] = useState(false);

    // function that is called when user clicks on showSocSecNum icon to either show or hide password
    const handleClickShowSocSecNum = (e) => {
        setShowSocSecNum((show) => !show);
    };

    // function
    const handleMouseDownSocSecNum = (e) => {
        e.preventDefault();
    };

    //* --------------- CODE TO FORMAT SOCIAL SECURITY NUM EX: 999-99-9999 ---------------
    // Create a function that formats the input
    const formatSocSecNum = (socSecNum) => {
        if (state.socSecNum == null || state.socSecNum === "Value could not ") {
            return "";
        } else {
            const cleaned = ('' + socSecNum).replace(/\D/g, '');
            const match = cleaned.match(/^(\d{0,3})(\d{0,2})(\d{0,})$/);
            if (match) {
                const part1 = match[1];
                const part2 = match[2];
                const part3 = match[3];
                return part1 + (part2 ? "-" + part2 : '') + (part3 ? "-" + part3 : '');
            }
            return '';
        }
    };
    const [displaySocSecNum, setDisplaySocSecNum] = useState(formatSocSecNum(state?.socSecNum || ""));

    // Create a function that removes non-digits
    const cleanSocSecNum = (socSecNum) => {
        return socSecNum.replace(/\D/g, '');
    };

    //* --------------- CODE TO FORMAT PHONE NUMBERS EX: (999) 99-9999 ---------------
    // Create a function that formats the input
    const formatPhoneNumber = (phoneNumber, field) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

        if (match) {
            const part1 = match[1];
            const part2 = match[2];
            const part3 = match[3];
            let formattedPhoneNumber = '';

            if (part1) {
                formattedPhoneNumber += '(' + part1;
            }
            if (part2) {
                formattedPhoneNumber += ") " + part2;
            }
            if (part3) {
                formattedPhoneNumber += "-" + part3;
            }

            return formattedPhoneNumber;
        }
    };

    const [displayMobilePhone, setDisplayMobilePhone] = useState(formatPhoneNumber(state?.mobilePhone));
    const [displayHomePhone, setDisplayHomePhone] = useState(formatPhoneNumber(state?.homePhone));
    const [displayWorkPhone, setDisplayWorkPhone] = useState(formatPhoneNumber(state?.workPhone));

    // Create a function that removes non-digits
    const cleanPhoneNumber = (phoneNumber) => {
        return phoneNumber.replace(/\D/g, '');
    };

    //* --------------- CODE TO FORMAT HEIGHT EX: 5'10" ---------------
    // Create a function that formats the input
    const heightRef = useRef(null);

    const formatHeight = (height) => {
        const cleaned = ('' + height).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{0,1})(\d{0,2}|1[0-1])$/);
        if (match) {
            const part1 = match[1];
            const part2 = match[2];
            // Check if inches part exists and add quotation mark if it does
            const inches = part2 ? "'" + part2 + '"' : '';
            // Return formatted height with or without inches and quotation mark
            return part1 + inches;
        }
    };

    const [displayHeight, setDisplayHeight] = useState(formatHeight(state?.height));

    // Create a function that removes non-digits
    const cleanHeight = (height) => {
        // Remove non-digits and non-punctuation characters except apostrophes and quotes
        return height.replace(/[^\d'"]/g, '');
    };

    const handleHeightChange = (e, field, displaySetter, ref) => {
        const { value, selectionStart } = e.target;
        const cleanedHeight = cleanHeight(value);

        displaySetter(formatHeight(cleanedHeight));
        handleFieldChange(field, cleanedHeight);

        // Update the selection range after the state is updated
        setTimeout(() => {
            const diff = cleanedHeight.length - value.length;
            const newPosition = selectionStart + diff;

            if (ref && ref.current) {
                ref.current.setSelectionRange(newPosition, newPosition);
            }
        }, 0);
    };

    useEffect(() => {
        setDisplayMobilePhone(formatPhoneNumber(state.mobilePhone));
        setDisplayHomePhone(formatPhoneNumber(state.homePhone));
        setDisplayWorkPhone(formatPhoneNumber(state.workPhone));
        setDisplaySocSecNum(formatSocSecNum(state?.socSecNum || ""))
    }, [resetState])

    const homePhoneInputRef = useRef(null);
    const workPhoneInputRef = useRef(null);
    const mobilePhoneInputRef = useRef(null);

    const handleChange = (e, field, displaySetter, ref) => {
        const { value, selectionStart } = e.target;
        const formattedPhoneNumber = formatPhoneNumber(value, field);

        displaySetter(formattedPhoneNumber);
        handleFieldChange(field, value);

        // Update the selection range after the state is updated
        setTimeout(() => {
            const diff = formattedPhoneNumber.length - value.length;
            const newPosition = selectionStart + diff;

            if (ref && ref.current) {
                ref.current.setSelectionRange(newPosition, newPosition);
            }
        }, 0);
    };

    return (
        <Box sx={{ height: "100%" }}>
            <Box sx={{ width: "99%", margin: "1vh auto", display: 'flex', flexDirection: "column", alignItems: "stretch" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                    <Paper sx={{ width: "75%" }} elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display: "flex" }}>
                            <h6 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Address</h6>
                        </Paper>
                        <Box sx={{ width: "95%", margin: "1vh auto" }}>
                            <Box sx={{ width: "100%" }}>
                                <Box mt={1.5} mb={1.5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="address1"
                                        label="Address 1"
                                        value={state?.address1}
                                        onChange={(e) => handleFieldChange('address1', e.target.value)}
                                        onBlur={(e) => handleBlur('address1', e.target.value)}
                                        helperText={state?.errors?.address1}
                                        error={!!state?.errors?.address1}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                    />
                                </Box>
                                <Box mt={1.5} mb={1.5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="address2"
                                        label="Address 2"
                                        name="address2"
                                        value={state?.address2}
                                        onChange={(e) => handleFieldChange('address2', e.target.value)}
                                        onBlur={(e) => handleBlur('address2', e.target.value)}
                                        helperText={state?.errors?.address2}
                                        error={!!state?.errors?.address2}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                    />
                                </Box>
                                <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Grid item sm={5}>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            id="zipCode"
                                            label="Zip Code"
                                            name="zipCode"
                                            value={state?.zipCode}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                if (/^\d*$/.test(inputValue)) {
                                                    handleFieldChange('zipCode', inputValue);
                                                }
                                            }}
                                            onBlur={(e) => handleBlur('zipCode', e.target.value)}
                                            helperText={state?.errors?.zipCode}
                                            error={!!state?.errors?.zipCode}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 5, readOnly: !state.isActive | state.isDeleted }}
                                        />
                                    </Grid>
                                    <Grid item sm={5}>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            select
                                            displayEmpty
                                            id="state"
                                            label="State"
                                            name="state"
                                            value={state?.state}
                                            onChange={(e) => handleFieldChange('state', e.target.value)}
                                            onBlur={(e) => handleBlur('state', e.target.value)}
                                            helperText={state?.errors?.state}
                                            error={!!state?.errors?.state}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: '20em',
                                                        },
                                                    },
                                                },
                                            }}
                                            inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                        >
                                            <MenuItem value={''}>N/A</MenuItem>
                                            {
                                                states?.map(({ codeValue, codeTitle },) => {
                                                    return <MenuItem key={codeTitle} value={codeValue}>{codeTitle}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Box mt={1.5} mb={1.5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="city"
                                        label="City"
                                        name="city"
                                        value={state?.city}
                                        onChange={(e) => {
                                            const sanitizedValue = e.target.value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                                            handleFieldChange('city', sanitizedValue);
                                        }}
                                        onBlur={(e) => handleBlur('city', e.target.value)}
                                        helperText={state?.errors?.city}
                                        error={!!state?.errors?.city}
                                        variant="outlined"
                                        inputProps={{ readOnly: !state.isActive | state.isDeleted}}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                    <Paper sx={{ width: "75%", gap: "1rem" }} elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display: "flex" }}>
                            <h6 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Description</h6>
                        </Paper>
                        <Box sx={{ width: "90%", margin: "0 auto" }}>
                            <Box sx={{ width: "100%" }}>
                                <Box mt={1.5} mb={1.5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        type="date"
                                        id="dateOfBirth"
                                        label="Date of Birth"
                                        name="dateOfBirth"
                                        value={state?.dateOfBirth}
                                        onChange={(e) => handleFieldChange('dateOfBirth', e.target.value)}
                                        onBlur={(e) => state?.dateOfBirth?.length > 0 && handleBlur('dateOfBirth', e.target.value)}
                                        helperText={state?.errors?.dateOfBirth}
                                        error={!!state?.errors?.dateOfBirth}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                    />
                                </Box>
                                <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Grid item sm={5}>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            select
                                            id="race"
                                            label="Race"
                                            name="race"
                                            value={state?.race}
                                            onChange={(e) => handleFieldChange('race', e.target.value)}
                                            onBlur={(e) => handleBlur('race', e.target.value)}
                                            helperText={state?.errors?.race}
                                            error={!!state?.errors?.race}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                        >
                                            <MenuItem value={''}>N/A</MenuItem>
                                            {
                                                races?.map(({ codeValue, codeTitle },) => {
                                                    return <MenuItem key={codeTitle} value={codeValue}>{codeTitle}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item sm={5}>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            select
                                            id="sex"
                                            label="Sex"
                                            name="sex"
                                            value={state?.sex}
                                            onChange={(e) => handleFieldChange('sex', e.target.value)}
                                            onBlur={(e) => handleBlur('sex', e.target.value)}
                                            helperText={state?.errors?.sex}
                                            error={!!state?.errors?.sex}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                        >
                                            <MenuItem value={''}>N/A</MenuItem>
                                            {
                                                sexes?.map(({ codeValue, codeTitle },) => {
                                                    return <MenuItem key={codeTitle} value={codeValue}>{codeTitle}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Box mt={1.5} mb={1.5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        type={ssnInputType}
                                        id="socSecNum"
                                        label="S.S.N"
                                        name="socSecNum"
                                        autoComplete={!!state.socSecNum ? "off" : 'on'}
                                        value={displaySocSecNum}
                                        onClick={() => setSsnInputType('text')}
                                        onChange={(e) => {
                                            const newSocSecNum = formatSocSecNum(e.target.value);
                                            setDisplaySocSecNum(newSocSecNum);
                                            handleFieldChange('socSecNum', cleanSocSecNum(e.target.value));
                                        }}
                                        onBlur={(e) => {
                                            displaySocSecNum.length > 0 && handleBlur('socSecNum', cleanSocSecNum(e.target.value))
                                            setSsnInputType('password');
                                        }}
                                        helperText={state?.errors?.socSecNum}
                                        error={!!state?.errors?.socSecNum}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 11, readOnly: !state.isActive | state.isDeleted }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle social security number visibility'
                                                        onClick={handleClickShowSocSecNum}
                                                        onMouseDown={handleMouseDownSocSecNum}
                                                        edge='end'
                                                    >
                                                        {showSocSecNum ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>
                                <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Grid item sm={5}>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            id="height"
                                            label="Height"
                                            value={displayHeight}
                                            onChange={(e) => {
                                                handleHeightChange(e, 'height', setDisplayHeight, heightRef)
                                            }}
                                            onBlur={(e) => {
                                                if (displayHeight.length > 0) {
                                                    handleBlur('height', cleanHeight(e.target.value))
                                                }
                                            }
                                            }
                                            helperText={state?.errors?.height}
                                            error={!!state?.errors?.height}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                                ref: heightRef,
                                                maxLength: 6,
                                                readOnly: !state.isActive | state.isDeleted
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={5}>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            id="weight"
                                            label="Weight"
                                            value={state?.weight}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                if (/^\d*$/.test(inputValue)) {
                                                    handleFieldChange('weight', inputValue);
                                                }
                                            }}
                                            onBlur={(e) => state?.weight?.length > 0 && handleBlur('weight', e.target.value)}
                                            helperText={state?.errors?.weight}
                                            error={!!state?.errors?.weight}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                                maxLength: 3,
                                                readOnly: !state.isActive | state.isDeleted
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                    <Paper sx={{ width: "75%", marginTop: "1vh", marginBottom: "1vh" }} elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display: "flex" }}>
                            <h6 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Contact Information</h6>
                        </Paper>
                        <Box sx={{ width: "95%", margin: "1.5vh auto" }}>
                            <Box sx={{ width: "100%" }}>
                                <Box mt={1.5} mb={1.5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="homePhone"
                                        label="Home Phone"
                                        name="homePhone"
                                        value={displayHomePhone}
                                        onChange={(e) => handleChange(e, 'homePhone', setDisplayHomePhone, homePhoneInputRef)}
                                        onBlur={(e) => {
                                            if (displayHomePhone.length > 0) {
                                                const newPhoneNumber = formatPhoneNumber(e.target.value, 'homePhone');
                                                setDisplayHomePhone(newPhoneNumber);
                                                handleFieldChange('homePhone', cleanPhoneNumber(e.target.value));
                                                handleBlur('homePhone', cleanPhoneNumber(e.target.value))
                                            }
                                        }}
                                        helperText={state?.errors?.homePhone}
                                        error={!!state?.errors?.homePhone}
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 14,
                                            ref: homePhoneInputRef,
                                            readOnly: !state.isActive | state.isDeleted
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                                <Box mt={1.5} mb={1.5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="workPhone"
                                        label="Work Phone"
                                        name="workPhone"
                                        value={displayWorkPhone}
                                        onChange={(e) => handleChange(e, 'workPhone', setDisplayWorkPhone, workPhoneInputRef)}
                                        onBlur={(e) => {
                                            if (displayWorkPhone.length > 0) {
                                                const newPhoneNumber = formatPhoneNumber(e.target.value, 'workPhone');
                                                setDisplayWorkPhone(newPhoneNumber);
                                                handleFieldChange('workPhone', cleanPhoneNumber(e.target.value));
                                                handleBlur('workPhone', cleanPhoneNumber(e.target.value))
                                            }
                                        }}
                                        helperText={state?.errors?.workPhone}
                                        error={!!state?.errors?.workPhone}
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 14,
                                            ref: workPhoneInputRef,
                                            readOnly: !state.isActive | state.isDeleted
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                                <Box mt={1.5} mb={1.5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="mobilePhone"
                                        label="Mobile Phone"
                                        name="mobilePhone"
                                        value={displayMobilePhone}
                                        onChange={(e) => handleChange(e, 'mobilePhone', setDisplayMobilePhone, mobilePhoneInputRef)}
                                        onBlur={(e) => {
                                            if (displayMobilePhone.length > 0) {
                                                const newPhoneNumber = formatPhoneNumber(e.target.value, 'mobilePhone');
                                                setDisplayMobilePhone(newPhoneNumber);
                                                handleFieldChange('mobilePhone', cleanPhoneNumber(e.target.value));
                                                handleBlur('mobilePhone', cleanPhoneNumber(e.target.value))
                                            }
                                        }}
                                        helperText={state?.errors?.mobilePhone}
                                        error={!!state?.errors?.mobilePhone}
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 14,
                                            ref: mobilePhoneInputRef,
                                            readOnly: !state.isActive | state.isDeleted
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                    <Paper sx={{ width: "75%", marginTop: "1vh", marginBottom: "1vh" }} elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display: "flex" }}>
                            <h6 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Driver's License</h6>
                        </Paper>
                        <Box sx={{ width: "90%", margin: "0 auto" }}>
                            <Box sx={{ width: "100%" }}>
                                <Box mt={1.5} mb={1.5}>
                                    <TextField
                                        fullWidth
                                        sx={ts}
                                        id="driverLicNumber"
                                        label="Driver License #"
                                        name="driverLicNumber"
                                        value={state?.driverLicNumber}
                                        onChange={(e) => handleFieldChange('driverLicNumber', e.target.value.replace(/[^a-zA-Z0-9]/g, ''))}
                                        onBlur={(e) => handleBlur('driverLicNumber', e.target.value.replace(/[^a-zA-Z0-9]/g, ''))}
                                        helperText={state?.errors?.driverLicNumber}
                                        error={!!state?.errors?.driverLicNumber}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 19, readOnly: !state.isActive | state.isDeleted }}
                                    />
                                </Box>
                                <Grid container mt={1.5} mb={1.5} sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Grid item sm={5}>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            select
                                            id="driverLicState"
                                            label="Driver License State"
                                            name="driverLicState"
                                            value={state?.driverLicState}
                                            onChange={(e) => handleFieldChange('driverLicState', e.target.value)}
                                            onBlur={(e) => handleBlur('driverLicState', e.target.value)}
                                            helperText={state?.errors?.driverLicState}
                                            error={!!state?.errors?.driverLicState}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: '20em',
                                                        },
                                                    },
                                                },
                                            }}
                                            inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                        >
                                            <MenuItem value={''}>N/A</MenuItem>
                                            {
                                                states.map(({ codeValue, codeTitle },) => {
                                                    return <MenuItem key={codeTitle} value={codeValue}>{codeTitle}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item sm={5}>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            id="driverLicClass"
                                            label="Driver License Class"
                                            name="driverLicClass"
                                            value={state?.driverLicClass}
                                            onChange={(e) => handleFieldChange('driverLicClass', e.target.value.replace(/[^a-zA-Z.]/g, '').toUpperCase())}
                                            onBlur={(e) => handleBlur('driverLicClass', e.target.value)}
                                            helperText={state?.errors?.driverLicClass}
                                            error={!!state?.errors?.driverLicClass}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 1, readOnly: !state.isActive | state.isDeleted }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container mt={1.5} mb={1.5} sx={{ display: "flex" }}>
                                    <Grid item sm={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={state?.commercialDl === 'Y'}
                                                    onChange={(e) => {
                                                        handleFieldChange('commercialDl', e.target.checked ? 'Y' : null)
                                                    }}
                                                />}
                                            disabled={!state.isActive | state.isDeleted}
                                            label="Commercial"
                                        />
                                    </Grid>
                                    <Grid item sm={5}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={state?.pickedUpDl === 'Y'}
                                                    onChange={(e) => {
                                                        handleFieldChange('pickedUpDl', e.target.checked ? 'Y' : null)
                                                    }}
                                                />}
                                            disabled={!state.isActive | state.isDeleted}
                                            label="Picked Up DL"
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Box>
    )
}

export default PersonInfoView;
import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import { Box, Button, Modal, Paper, LinearProgress } from '@mui/material';
import { toast } from 'react-toastify';

import UtilitiesTable from '../components/tables/views/UtilitiesTable';
import UtilitiesModals from '../components/modals/views/UtilitiesModal';
import DeleteModal from '../components/modals/views/DeleteModal';

import UserContext from '../../../context/UserContext';
import { apiRoute } from '../../../App';

const UtilitiesView = (props) => {
    const { utilInfo } = props;

    const { useFeeSchedule, superAdminCheck, entityId } = useContext(UserContext);

    const [addCheck, setAddCheck] = useState(true);
    const [rowData, setRowData] = useState({});
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);

    const [modal, setModal] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalType, setModalType] = useState('');
    const [loadingRows, setLoadingRows] = useState(false)

    const handleOpen = (name, type) => {
        setModalName(name);
        setModalType(type);
        setModal(true);
    };

    const handleClose = () => setModal(false);

    const utilName = utilInfo.name;
    useEffect(() => {
        if (utilName === "Fee Rules") {
            setAddCheck(superAdminCheck);
        }
    }, [utilName, superAdminCheck]);

    const printAgencyList = () => {
        setLoading(true);
        axios.get(`${apiRoute}/api/CollectionReportViews/rptAgencyList`, { responseType: 'arraybuffer' })
            .then((response) => {
                const arrayBufferView = new Uint8Array(response.data);
                const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                const dataUrl = URL.createObjectURL(blob);
                window.open(dataUrl, '_blank');
            })
            .catch(error => {
                toast.error(`Error ${error.response.status} trying to print the agency list`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .finally(() => setLoading(false))
    };

    const getRows = () => {
        setLoadingRows(true);
        axios.get(`${apiRoute}/api/${utilInfo.controller}`)
            .then((res) => {
                setRows(res.data.map((row) => ({
                    ...row,
                    id: row[utilInfo.id]
                })));
            })
            .catch((err) => {
                console.log(`error getting ${utilInfo.controller} data`, err);
            })
            .finally(() => setLoadingRows(false))
    };

    return (
        <>
            <Paper sx={{ width: '99.9%', m: '0 auto', mb: '3vh' }} elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '0.1vh', mb: '1vh', textAlign: 'center', }} elevation={10}>
                    <h1>
                        <strong>{utilName}</strong>
                    </h1>
                </Paper>
                <Box sx={{ width: '98%', m: '0 auto' }}>
                    <Paper elevation={10} sx={{ mb: '1vh', maxHeight: utilInfo.tableHeight }}>
                        <UtilitiesTable
                            controller={utilInfo.controller}
                            tableHeight={utilInfo.tableHeight}
                            name={utilInfo.name}
                            setRowData={setRowData}
                            handleOpen={handleOpen}
                            rows={rows}
                            setRows={setRows}
                            getRows={getRows}
                            loading={loadingRows}
                            setLoading={setLoadingRows}
                        />
                    </Paper>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: '1vh' }}>
                    {
                        addCheck && utilName !== 'Organization Info' &&
                        <Box ml={3} mt={1}>
                            <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={() => handleOpen(utilInfo.name, 'add')}>
                                + Add {utilName}
                            </Button>
                        </Box>
                    }
                    {
                        utilName === "Agencies" &&
                        <Box mr={3} mt={1}>
                            <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={printAgencyList}>
                                Print Agency List
                            </Button>
                        </Box>
                    }
                </Box>
                {
                    loading && <LinearProgress />
                }
            </Paper>
            <Modal open={modal} sx={{ width: modalType === 'delete' ? '30%' : '50%', m: '5vh auto' }}>
                <>
                    {
                        modalType === 'delete' && (
                            <DeleteModal
                                name={modalName}
                                controller={utilInfo.controller}
                                handleClose={handleClose}
                                data={rowData}
                                loading={loading}
                                setLoading={setLoading}
                                setRows={setRows}
                                getRows={getRows}
                            />
                        )
                    }
                    {
                        (modalType === 'add' || modalType === 'edit') && (
                            <UtilitiesModals
                                handleClose={handleClose}
                                modalType={modalType}
                                modalName={modalName}
                                data={rowData}
                                loading={loading}
                                setLoading={setLoading}
                                rows={rows}
                                setRows={setRows}
                                getRows={getRows}
                            />
                        )
                    }
                </>
            </Modal>
        </>
    )
}

export default UtilitiesView;
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import { Box, Checkbox, Paper, TextField, Button, LinearProgress, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { toast } from 'react-toastify';

import BondsForm from '../components/bondsTab/BondsForm';
import BondCheckForm from '../components/bondsTab/BondCheckForm';
import BondTransferModal from '../components/bondsTab/BondTransferModal';
import BondRefundChecksModal from '../components/bondsTab/BondRefundChecksModal';
import AddChargeViewTable from '../../tables/views/violationsTab/AddChargeViewTable';
import BondViolationsTable from './BondViolationsTable';

import UserContext from '../../../context/UserContext';
import { BatchContext } from '../../../context/BatchMgmtContext';
import { apiRoute, ThemeModeContext } from '../../../App';
import { CaseContext } from '../../../context/CaseContext';
import OpenBatchModal from '../components/batches/OpenBatchModal';

const BondsModals = (props) => {
    const {
        modalType,
        handleClose,
        ts,
        selectedBond,
        setSelectedBond,
        bondRows,
        setBondRows,
        updateCaseBalance,
        handleOpen,
        checkInfo,
        bondNumber,
        newBatch,
        state,
        violationRows,
        voidBondCheck,
        setCheckNum,
        loadingPrint
    } = props;

    // null variable so the open batch modal can be called
    const selectedRow = {};

    const { setCaseHistoryRows, citationView } = useContext(CaseContext);
    const { shiftDate, userName, userId, entityId } = useContext(UserContext);
    const { paymentBatchNumber, setPaymentBatchNumber, openBatches, setOpenBatches } = useContext(BatchContext);
    const { mode } = useContext(ThemeModeContext);

    const { caseNumber } = useParams();
    const [batchName, setBatchName] = useState(`Batch ${userName}`);

    const [loading, setLoading] = useState(false);

    const [batchDate, setBatchDate] = useState(dayjs(''));
    const [batchType, setBatchType] = useState('SIMPLE');

    const [applyAmount, setApplyAmount] = useState("$0.00");
    const [applyViolations, setApplyViolations] = useState([]);
    const [refundAmount, setRefundAmount] = useState("$0.00");

    const isApplyDisabled = applyAmount.replace(/[^0-9.]/g, '') < "0.01" || applyAmount.replace(/[^0-9.]/g, '') > selectedBond.remainingBondAmount || loading || violationRows.length < 1;
    const isRefundDisabled = refundAmount.replace(/[^0-9.]/g, '') < "0.01" || refundAmount.replace(/[^0-9.]/g, '') > selectedBond.remainingBondAmount || loading;


    const [reason, setReason] = useState('');
    const [errors, setErrors] = useState({});

    const [statuteRows, setStatuteRows] = useState([]);
    const [statute, setStatute] = useState([]);
    const [attachedStatuteId, setAttachedStatuteId] = useState({});
    const [selectedStatutes, setSelectedStatutes] = useState([]);

    const getStatutes = () => {
        setLoading(true);
        axios.get(`${apiRoute}/api/statutefinetbls/date/${state?.violationDate}`)
            .then((res) => {
                const concatenatedData = res.data.map(subArray => subArray).flat();
                const statuteRows = concatenatedData.map(item => ({
                    ...item,
                    id: item.pkStatuteFineId,
                    chargeType: item.chargeType,
                    rsNumber: item.rsNumber,
                }));
                const statuteRowsWithChargeType = statuteRows.filter(item => item.chargeType !== null);
                setStatuteRows(statuteRowsWithChargeType);
            })
            .catch((err) => {
                console.error("GET STATUTES ERR");
            })
            .finally(() => { setLoading(false) })
    };

    const handleFocus = (field) => {
        const stateFields = {
            "applyAmount": setApplyAmount,
            "refundAmount": setRefundAmount
        }

        stateFields[field]('');
    };

    const handleBatchOpen = async () => {
        let dayjsBatchDate = dayjs(batchDate);
        const { $D } = dayjsBatchDate;
        if (isNaN($D)) {
            toast.error(`Select a date to open a new batch.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else {

            const batchData = {
                fkUserId: userId,
                entityId: entityId,
                openDatetime: dayjsBatchDate.toISOString().split('T')[0],
                BatchType: batchType,
                batchName: batchName,
            }

            try {
                const res = await axios.post(`${apiRoute}/api/batchtbl`, batchData);
                setOpenBatches([...openBatches, res.data]);
                toast.success(`Batch ${batchName} (${res?.data?.batchNumber}) opened.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                handleClose();
            } catch (err) {
                console.error('error closing batch', err);
                if (err?.response.status === 400) {
                    toast.error(`Batch already exists with selected Payment Date`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    toast.error(`Error opening batch ${batchName}.`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }
        }
    };

    const deleteBond = async () => {
        if (!reason) {
            setErrors({
                error: 'Reason Required'
            })
            return;
        }
        const bondData = {
            ...selectedBond,
            incidentDate: new Date(selectedBond.incidentDate) || '',
            bondDate: new Date(selectedBond.bondDate) || '',
            deletedDate: new Date(),
            reimbursementDate: new Date(selectedBond.reimbursementDate) || '',
            deletedBy: userName,
            deletedReason: reason,
            appliedToCaseDate: null
        }
        setLoading(true);
        try {
            await axios.put(`${apiRoute}/api/bond/delete/${selectedBond.id}`, bondData);
            setBondRows(bondRows.filter(r => r.id !== selectedBond.id));
            handleClose();
            toast.success(`Bond ${selectedBond.bondNumber} successfully delete.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setReason('');
        } catch (err) {
            console.log(err);
            toast.error(`Error deleting bond ${selectedBond.bondNumber}.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
            setLoading(false);
        }
    };

    const applyBond = async (e) => {
        e.preventDefault();
        if (!paymentBatchNumber) {
            toast.error('Please select the batch this applied bond will be in.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        setLoading(true);
        try {
            const applyData = {
                BondId: selectedBond.id,
                AppliedAmount: +(applyAmount.slice(1)),
                ShiftDate: shiftDate,
                PaymentType: "BOND",
                BatchNumber: paymentBatchNumber,
                Violations: applyViolations
            }

            const res = await axios.post(`${apiRoute}/api/bond/apply`, applyData);

            const newBondRows = res.data.map((row) => ({
                ...row.bond,
                incidentDate: row.bond.incidentDate ? new Date(row.bond.incidentDate).toISOString().substring(0, 10) : null,
                reimbursementDate: row.bond.reimbursementDate ? new Date(row.bond.reimbursementDate).toISOString().substring(0, 10) : null,
                bondDate: row.bond.bondDate ? new Date(row.bond.bondDate).toISOString().substring(0, 10) : null,
                appliedToCaseDate: row.bond.appliedToCaseDate ? new Date(row.bond.appliedToCaseDate).toISOString().substring(0, 10) : null,
                remainingBondAmount: modalType === 'form' ? row.bond.bondAmount : row.bond.cashBondTransactions.reduce((remaining, transaction) => {
                    if (transaction.isActive) {
                        return remaining - transaction.transactionAmount;
                    }
                    return remaining
                }, row.bond.bondAmount),
                statuteIds: row.statuteIds
            }));

            const paymentRes = await axios.get(`${apiRoute}/api/FfPaymentTbls/CaseId/${caseNumber}`);
            const paymentDataWithId = paymentRes.data.map((row) => ({
                ...row,
                id: row.pkFfPaymentId,
                paymentDate: row.paymentDate ? new Date(row.paymentDate).toISOString().split('T')[0] : '',
                dateEnter: row.dateEnter ? new Date(row.dateEnter).toISOString().split('T')[0] : '',
            }));

            setBondRows(newBondRows);
            setCaseHistoryRows(paymentDataWithId);
            await updateCaseBalance();
            handleClose();
            toast.success(`Bond #${selectedBond.bondNumber} applied`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } catch (err) {
            toast.error(`Failed to apply bond #${selectedBond.bondNumber}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            console.log(err)
        } finally {
            setLoading(false);
        }
    };

    const refundBond = async (e) => {
        setLoading(true);
        const newBond = {
            BondId: selectedBond.id,
            CaseId: caseNumber,
            IncidentLocation: selectedBond.incidentLocation,
            IncidentDate: selectedBond.incidentDate,
            PostedLast: selectedBond.postedLast,
            PostedFirst: selectedBond.postedFirst,
            PostedPhone1: selectedBond.postedPhone1,
            PostedAddress1: selectedBond.postedAddress1,
            PostedAddress2: selectedBond.postedAddress2,
            PostedCity: selectedBond.postedCity,
            PostedState: selectedBond.postedState,
            PostedZip: selectedBond.postedZip,
            RefundAmount: parseFloat(refundAmount.slice(1)),
            BondNumber: selectedBond.bondNumber,
            ReimbursementCheckNumber: selectedBond.reimbursementCheckNumber,
            ReceiptNum: selectedBond.receiptNum,
            RefundReason: reason,
            BatchNumber: paymentBatchNumber
        }
        try {
            const res = await axios.post(`${apiRoute}/api/bond/refund`, newBond);

            const newBondRows = res.data.map((row) => ({
                ...row.bond,
                incidentDate: row.bond.incidentDate ? new Date(row.bond.incidentDate).toISOString().substring(0, 10) : null,
                reimbursementDate: row.bond.reimbursementDate ? new Date(row.bond.reimbursementDate).toISOString().substring(0, 10) : null,
                bondDate: row.bond.bondDate ? new Date(row.bond.bondDate).toISOString().substring(0, 10) : null,
                appliedToCaseDate: row.bond.appliedToCaseDate ? new Date(row.bond.appliedToCaseDate).toISOString().substring(0, 10) : null,
                remainingBondAmount: modalType === 'form' ? row.bond.bondAmount : row.bond.cashBondTransactions.reduce((remaining, transaction) => {
                    if (transaction.isActive) {
                        return remaining - transaction.transactionAmount;
                    }
                    return remaining
                }, row.bond.bondAmount),
                statuteIds: row.statuteIds
            }));

            const paymentRes = await axios.get(`${apiRoute}/api/FfPaymentTbls/CaseId/${caseNumber}`);
            const paymentDataWithId = paymentRes.data.map((row) => ({
                ...row,
                id: row.pkFfPaymentId,
                paymentDate: row.paymentDate ? new Date(row.paymentDate).toISOString().split('T')[0] : '',
                dateEnter: row.dateEnter ? new Date(row.dateEnter).toISOString().split('T')[0] : '',
            }));


            setBondRows(newBondRows);
            setCaseHistoryRows(paymentDataWithId);
            await updateCaseBalance();
            handleOpen('checks', '50%');
            toast.success(`Bond #${selectedBond.bondNumber} refunded`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setReason('');
        } catch (err) {
            toast.error(`Failed to refund bond #${selectedBond.bondNumber}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const getRow = (params) => {
        setStatute({
            id: params?.row?.id,
            chargeType: params?.row?.chargeType,
            rsNumber: params?.row?.rsNumber,
            description: params?.row?.description,
            defaultFine: params?.row?.defaultFine
        });
    };

    const addStatute = () => {
        const currentStatutes = selectedStatutes.map(({ id, chargeType, rsNumber, description, defaultFine }) => ({
            id, chargeType, rsNumber, description, defaultFine
        }));
        if (!currentStatutes.some(item => statute.id === item.id)) {
            currentStatutes.push(statute);
        }
        console.log('currentStatutes', currentStatutes)
        setSelectedStatutes(currentStatutes);
    }

    const getAttachedStatuteRow = (params) => {
        setAttachedStatuteId(params?.row?.id);
    }

    const removeStatute = () => {
        const currentStatutes = selectedStatutes.filter(statute => attachedStatuteId !== statute.id).map(statute => statute);
        setSelectedStatutes(currentStatutes);
    }

    const handleEditViolations = () => {
        const statutesFromIds = [];
        selectedBond.statuteIds.forEach(statuteId => {
            statutesFromIds.push(statuteRows.find(statute => statute.id === statuteId))
        })
        console.log('previously attached statutes', statutesFromIds);

        setSelectedStatutes(statutesFromIds.map(({ id, chargeType, rsNumber, description, defaultFine }) => ({
            id, chargeType, rsNumber, description, defaultFine
        })))
    }

    const tBackground = mode === 'dark' ? '#313131' : 'white';

    const minHeight = (selectedStatutes === undefined || selectedStatutes.length === 0) ? '25vh' : 'auto';

    useEffect(() => {
        if (modalType === 'selectViolations' || modalType === 'editViolations') {
            getStatutes()
        }
    }, [modalType]);

    useEffect(() => {
        if (modalType === 'editViolations' && statuteRows.length > 0) {
            handleEditViolations();
        }
    }, [modalType, statuteRows])


    return (
        <>
            {
                (modalType === 'selectViolations' || modalType === 'editViolations') && (
                    <>
                        <Paper elevation={10}>
                            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                                <h3 style={{ fontWeight: 'bold' }}>
                                    Violations Attached
                                </h3>
                            </Paper>
                            <Box sx={{ width: "100%", m: "auto", pb: "1vh", pt: "2vh", px: ".6vw" }}>
                                <Paper sx={{ height: "35vh", overflow: 'hidden' }} elevation={10}>
                                    <AddChargeViewTable
                                        statuteRows={statuteRows}
                                        getRow={getRow}
                                        loading={loading}
                                        addViolation={addStatute}
                                        caseLevelFees={false}
                                        modalType={modalType}
                                    />
                                </Paper>
                            </Box>
                            <hr />
                            <Box sx={{
                                width: "100%", m: "auto", pb: "1vh", pt: "1vh", px: ".6vw"
                            }}>
                                <Paper sx={{ height: minHeight, maxHeight: '35vh', overflow: 'auto' }} elevation={10}>
                                    <BondViolationsTable
                                        rows={selectedStatutes}
                                        getRow={getAttachedStatuteRow}
                                        removeStatute={removeStatute}
                                    />
                                </Paper>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', pb: '1vh', pr: '.6vw' }} >
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={() => { modalType === 'editViolations' ? handleOpen('edit', '45%') : handleOpen('form', '45%') }} disabled={selectedStatutes.length < 1}>
                                    Bond Details
                                </Button>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Close</Button>
                            </Box>
                            {
                                loading && (
                                    <>
                                        <LinearProgress />
                                    </>
                                )
                            }
                        </Paper>
                    </>
                )
            }
            {
                (modalType === 'form' || modalType === 'edit') && (
                    <>
                        <Paper elevation={10}>
                            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                                <h1 style={{ fontWeight: 'bold' }}>
                                    {
                                        modalType === 'form' ? 'Add Bond' : `Edit Bond #${selectedBond.bondNumber}`
                                    }
                                </h1>
                            </Paper>
                            <Box sx={{ width: '99%', m: '1vh auto' }}>
                                <BondsForm
                                    ts={ts}
                                    handleClose={handleClose}
                                    bondRows={bondRows}
                                    setBondRows={setBondRows}
                                    modalType={modalType}
                                    selectedBond={selectedBond}
                                    setLoading={setLoading}
                                    loading={loading}
                                    bondNumber={bondNumber}
                                    selectedStatutes={selectedStatutes}
                                />
                            </Box>
                            {
                                loading && (
                                    <>
                                        <LinearProgress />
                                    </>
                                )
                            }
                        </Paper>
                    </>
                )
            }
            {
                modalType === 'delete' && (
                    <>
                        <Paper elevation={10}>
                            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                                <h1>
                                    <strong>Delete Bond #{selectedBond.bondNumber}</strong>
                                </h1>
                            </Paper>
                            <Box sx={{ width: '99%', m: '1vh auto' }}>
                                <h3>
                                    Please enter the reason you are deleting bond #{selectedBond.bondNumber} below:
                                </h3>
                                <form>
                                    <TextField
                                        sx={{ ...ts, mt: '1vh', pb: '1vh' }}
                                        multiline
                                        rows={7}
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                        onBlur={(e) => {
                                            if (!e.target.value) {
                                                setErrors({
                                                    error: 'Reason Required'
                                                })
                                            }
                                        }}
                                        fullWidth
                                        label="Delete Reason"
                                        variant="outlined"
                                        helperText={errors?.error}
                                        error={!!errors?.error}
                                        inputProps={{ maxLength: 255 }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', pb: '1vh' }}>
                                        <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={deleteBond} disabled={loading | state.isDeleted | !state.isActive}>Delete Bond</Button>
                                        <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Cancel</Button>
                                    </Box>
                                </form>
                            </Box>
                            {
                                loading && (
                                    <>
                                        <LinearProgress />
                                    </>
                                )
                            }
                        </Paper>
                    </>
                )
            }
            {
                modalType === 'apply' && (
                    <Paper elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                            <h1>
                                <strong>Apply Bond</strong>
                            </h1>
                        </Paper>
                        <Box sx={{ width: '99%', m: '1vh auto' }}>
                            <h3 style={{ textAlign: 'center' }}>Apply {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(selectedBond.bondAmount)} from bond #{selectedBond.bondNumber}.</h3>
                            <hr />
                            <Box sx={{ maxHeight: '20vh', overflowY: 'auto' }}>
                                <table style={{ borderCollapse: 'separate', marginLeft: '1rem', textAlign: 'center' }}>
                                    <thead>
                                        <tr style={{ position: 'sticky', top: 0, backgroundColor: tBackground, zIndex: 1 }}>
                                            <th></th>
                                            <th style={{ paddingLeft: '1rem' }}>Charge Type</th>
                                            <th style={{ paddingLeft: '2.5rem' }}>Statute</th>
                                            <th style={{ paddingLeft: '4rem' }}>Description</th>
                                            <th style={{ paddingLeft: '1rem' }}>Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {violationRows?.filter(violation => violation.amendedDate === null).map(({ pkViolationId, chargeType, currentStatute, description, remainingBalance }) => (
                                            <tr key={pkViolationId}>
                                                <td>
                                                    <FormControlLabel
                                                        value={pkViolationId}
                                                        control={<Checkbox />}
                                                        checked={applyViolations.some(vio => vio.pkViolationId === pkViolationId)}
                                                        onChange={(e) => {
                                                            setApplyViolations((prevViolations) => {
                                                                const isSelected = prevViolations.some(vio => vio.pkViolationId === pkViolationId)
                                                                if (isSelected) {
                                                                    return prevViolations.filter(vio => vio.pkViolationId !== pkViolationId)
                                                                } else {
                                                                    return [...prevViolations, { pkViolationId, currentStatute, remainingBalance }];
                                                                }
                                                            })
                                                        }}
                                                        disabled={remainingBalance < 0.01 }
                                                    />
                                                </td>
                                                <td style={{ paddingLeft: '1rem' }}>{chargeType}</td>
                                                <td style={{ paddingLeft: '2.5rem' }}>{currentStatute}</td>
                                                <td style={{ paddingLeft: '4rem' }}>{description}</td>
                                                <td style={{ paddingLeft: '1rem' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(remainingBalance)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Box>
                            <hr />
                            <Box sx={{ display: "flex", gap: '1rem', alignItems: 'center' }}>
                                <h5 style={{ marginTop: '.5vh' }}>*Select batch to apply bond: </h5>
                                <>
                                    <RadioGroup
                                        row
                                        name='batchSelection'
                                        value={paymentBatchNumber}
                                        onChange={(e) => setPaymentBatchNumber(e.target.value)}
                                    >
                                        {
                                            openBatches?.map(({ batchNumber }) => (
                                                <FormControlLabel
                                                    key={batchNumber}
                                                    value={batchNumber}
                                                    control={<Radio />}
                                                    label={batchNumber}
                                                />
                                            ))
                                        }
                                    </RadioGroup>
                                </>
                            </Box>
                            <Box sx={{ display: "flex", marginTop: '1rem', justifyContent: "space-evenly" }}>
                                <TextField
                                    label={"Remaining Bond Amount"}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                                    sx={{ minWidth: '15vw', justifyContent: 'end' }}
                                    value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(selectedBond.remainingBondAmount)}
                                />
                                <TextField
                                    label={"Apply Amount"}
                                    value={applyAmount}
                                    sx={{ minWidth: '10vw' }}
                                    inputProps={{ style: { textAlign: 'right' } }}
                                    onFocus={() => handleFocus("applyAmount")}
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/[^0-9.]/g, '');
                                        if (!isNaN(value)) {
                                            setApplyAmount(value);
                                        }
                                    }}
                                    onBlur={(e) => {
                                        const value = e.target.value.replace(/[^0-9.]/g, '');
                                        if (!isNaN(value)) {
                                            setApplyAmount(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(value));
                                        }
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', py: '1vh', marginTop: "1rem" }}>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={applyBond} disabled={isApplyDisabled | state.isDeleted | !state.isActive || !paymentBatchNumber || applyViolations.length === 0}>Apply Bond</Button>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Close</Button>
                            </Box>
                        </Box>
                        {
                            loading && (
                                <>
                                    <LinearProgress />
                                </>
                            )
                        }
                    </Paper>
                )
            }
            {
                modalType === 'refund' && (
                    <Paper elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                            <h1>
                                <strong>Refund Bond</strong>
                            </h1>
                        </Paper>
                        <Box sx={{ width: '99%', m: '1vh auto' }}>
                            <h3 style={{ textAlign: 'center' }}>Refund {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(selectedBond.bondAmount)} from bond #{selectedBond.bondNumber} from this case.</h3>
                            <hr />
                            <Box sx={{ display: "flex", gap: '1rem', alignItems: 'center' }}>
                                <h5 style={{ marginTop: '.5vh' }}>*Select batch: </h5>
                                <>
                                    <RadioGroup
                                        row
                                        name='batchSelection'
                                        value={paymentBatchNumber}
                                        onChange={(e) => setPaymentBatchNumber(e.target.value)}
                                    >
                                        {
                                            openBatches?.map(({ batchNumber }) => (
                                                <FormControlLabel
                                                    key={batchNumber}
                                                    value={batchNumber}
                                                    control={<Radio />}
                                                    label={batchNumber}
                                                />
                                            ))
                                        }
                                    </RadioGroup>
                                </>
                            </Box>
                            <form>
                                <Box sx={{ display: "flex", justifyContent: "space-evenly", width: '99%', m: '1.5vh auto' }}>
                                    <TextField
                                        label="Remaining Bond Amount"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                                        sx={{ minWidth: '15vw', justifyContent: 'end' }}
                                        value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(selectedBond.remainingBondAmount)}
                                    />
                                    <TextField
                                        label="Refund amount"
                                        InputLabelProps={{ shrink: true }}
                                        value={refundAmount}
                                        sx={{ minWidth: '10vw' }}
                                        inputProps={{ style: { textAlign: 'right' } }}
                                        onFocus={() => handleFocus("refundAmount")}
                                        onChange={(e) => {
                                            const value = e.target.value.replace(/[^0-9.]/g, '');
                                            if (!isNaN(value)) {
                                                setRefundAmount(value);
                                            }
                                        }}
                                        onBlur={(e) => {
                                            const value = e.target.value.replace(/[^0-9.]/g, '');
                                            if (!isNaN(value)) {
                                                setRefundAmount(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(value));
                                            }
                                        }}
                                    />
                                </Box>
                                <TextField
                                    sx={{ ...ts, mt: '1vh', pb: '1vh' }}
                                    multiline
                                    rows={7}
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    fullWidth
                                    label="Refund Reason"
                                    variant="outlined"
                                    helperText={errors?.error}
                                    error={!!errors?.error}
                                    inputProps={{ maxLength: 255 }}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', py: '1vh' }}>
                                    <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={refundBond} disabled={isRefundDisabled | state.isDeleted | !state.isActive || !paymentBatchNumber}>Refund Bond</Button>
                                    <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Close</Button>
                                </Box>
                            </form>
                        </Box>
                        {
                            loading && (
                                <>
                                    <LinearProgress />
                                </>
                            )
                        }
                    </Paper>
                )
            }
            {
                (modalType === 'checks' || modalType === 'checksView') && (
                    <>
                        <Paper elevation={10}>
                            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                                <h1>
                                    <strong>
                                        {
                                            modalType === 'checks' ? (
                                                'Add Bond Check'
                                            ) : (
                                                `View Check #${checkInfo.checkNumber} For Bond #${selectedBond.bondNumber}`
                                            )
                                        }
                                    </strong>
                                </h1>
                            </Paper>
                            <Box sx={{ width: '99%', m: '1vh auto' }}>
                                <BondCheckForm
                                    ts={ts}
                                    handleClose={handleClose}
                                    bondRows={bondRows}
                                    setBondRows={setBondRows}
                                    modalType={modalType}
                                    selectedBond={selectedBond}
                                    setLoading={setLoading}
                                    checkInfo={checkInfo}
                                    refundAmount={refundAmount}
                                />
                            </Box>
                            {
                                loading && (
                                    <>
                                        <LinearProgress />
                                    </>
                                )
                            }
                        </Paper>
                    </>
                )
            }
            {
                modalType === 'transfer' && (
                    <Paper elevation={10}>
                        <Paper sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', textAlign: 'center' }}>
                            <h3>
                                <strong>Bond Transfers</strong>
                            </h3>
                        </Paper>
                        <Box sx={{ width: '99%', m: '1vh auto' }}>
                            <BondTransferModal
                                handleClose={handleClose}
                                selectedBond={selectedBond}
                                citationView={citationView}
                                setBondRows={setBondRows}
                                setSelectedBond={setSelectedBond}
                            />
                        </Box>
                    </Paper>
                )
            }
            {
                modalType === 'RefundChecksView' && (
                    <Paper elevation={10}>
                        <Paper sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', textAlign: 'center' }}>
                            <h3>
                                <strong>Refund Bond Checks</strong>
                            </h3>
                        </Paper>
                        <Box sx={{ width: '99%', m: '1vh auto' }}>
                            <BondRefundChecksModal
                                handleClose={handleClose}
                                selectedBond={selectedBond}
                                checkInfo={checkInfo}
                                setCheckNum={setCheckNum}
                                voidBondCheck={voidBondCheck}
                                loadingPrint={loadingPrint}
                            />
                        </Box>
                    </Paper>
                )
            }
            {
                modalType === 'openBatch' && (
                    <>
                        <OpenBatchModal
                            newBatch={newBatch}
                            ts={ts}
                            batchDate={batchDate}
                            setBatchDate={setBatchDate}
                            handleBatchOpen={handleBatchOpen}
                            batchType={batchType}
                            setBatchType={setBatchType}
                            handleClose={handleClose}
                            modalType={modalType}
                            selectedRow={selectedRow}
                            batchName={batchName}
                            setBatchName={setBatchName}
                        />
                    </>
                )
            }
        </>
    )
}

export default BondsModals;